import * as ApiHelpers from './api_helper';

const apiRoute = 'themes';

export const ThemeRepository = {
  GetTheme: id => ApiHelpers.api_call_get(`${apiRoute}/${id}`),
  GetListTheme: (page = 1, pageSize = 20, search = '') => {
    let path = ApiHelpers.GetApiUrl(apiRoute, {
      page,
      pageSize,
      search
    })
    return ApiHelpers.api_call_get(path);
  },
   PutApproveTheme: data => ApiHelpers.api_call_put(apiRoute, data),
   GetListThemeFile: (themeId: number, query = '', before = 0, after = 0, limit = 20) => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${themeId}/files`, {
      query: query,
      before: before,
      after: after,
      limit: limit
    });
    return ApiHelpers.api_call_get(path);
  },
};
