
import React from 'react';
import { Badges } from '@haravan/react-components';
import { IStatus } from '@haravan/react-components/lib/Badges';
import './status.css'

export const STATUS_BADGE = ['primary', 'error', 'success', 'warning', 'default', 'warning','warning','error','error', 'success', 'success'] as IStatus[];
export const STATUS_NAME = {
  "Mới tạo": 0,
  "Từ chối": 1,
  "Đã duyệt": 2,
  "Chờ duyệt": 3,
  "Đã hủy": 4,
  "Chờ duyệt giá": 5,
  "Chờ duyệt version" : 6,
  "Từ chối version" : 7,
  "Từ chối giá" : 8,
  "Đã duyệt giá" : 9,
  "Đã duyệt version" : 10,

  "Chưa thanh toán": 0,
  "Đã Xóa" : 1,
  "Chờ xác nhận ngân hàng": 1,
  "Đã thanh toán": 2,
  "Đang xử lý": 3,
  "Đang hoạt động": 2,
  "Ngưng hoạt động": 1,
  "canceled" : 1,
  "active" : 2,
  "trialing" : 3,
  "inactive" : 1,
  "Gói mặc định": 2,
  "Theme chính": 0,
  "dùng thử" : 0,
  "Cài app" : 0,
  "Gỡ app" : 1,
  "Cài theme" : 0,
  "Gỡ theme" : 1,
  "Đã cập nhật" : 3,
  "Paid": 2
}
export const STATUS = ['Chờ duyệt', 'Đã duyệt', 'Từ chối']

export const STATUS_APP_NAME = {
  Rejected: "Từ chối",
  Approved: "Đã duyệt",
  ApprovalWaiting: "Chờ duyệt",
}

export const PLAN_TYPE_PRICE = {
  free: 1,
  yearly: 2,
  month: 3
};

export const STATUS_THEME_NAME = {
  Rejected: "Từ chối",
  Approved: "Đã duyệt",
  ApprovalWaiting: "Chờ duyệt"
}

export const Status = ({ text, className = '', onClick = null }) => {
  const key = STATUS_BADGE[STATUS_NAME[text]] || 'default';
  return <div className={`status-wrapper ${className}`} onClick={() => onClick?.(text)}>
    <Badges status={key}>{text}</Badges>
  </div>
}
