import * as ApiHelpers from './api_helper';

const apiRoute = 'payouts';

export const PayoutRepository = {
  Export: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/export`, model);
  },
  Filter: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/filter`, model);
  },
};
