import { useParams } from "react-router-dom";
import { FormatDateTime } from "@haravan/react-components";
import { PartnerRepository } from "../../../../repositories";
import { Currency, Link, Status, ListViewHeader, ListViewPageSize, ListViewPagingInfo, ListViewSimplePaging, Detail  } from "../../../../components";

import "./index.css";
import { DetailPartnerShop } from "../../../../components/link/link";

const getData = (paging: ListViewPagingInfo, id) => {
  return PartnerRepository.GetPartnerBalanceByPartnerId(id, paging.searchText, paging.before, paging.after, paging.limit);
}

const headers = [
  { name: "Đối tác", className: "partner-payout-partner-name" },
  { name: "Ref", className: "partner-payout-ref" },
  { name: "Loại giao dịch", className: "partner-payout-transaction-type" },
  { name: "Cửa hàng", className: "partner-payout-shop-name" },
  { name: "Số tiền", className: "partner-payout-price-cost" },
  { name: "Ngày tạo", className: "partner-payout-created-date" },
  { name: "Ngày thanh toán", className: "partner-payout-created-date" },
  { name: "Trạng thái công nợ", className: "partner-payout-status" },
  { name: "Mã giao dịch", className: "partner-payout-transaction-id" },
  { name: "Trạng thái giao dịch", className: "partner-payout-transaction-status" },
] as ListViewHeader[];

export const PartnerPayout = (props) => {
  const { partnerId } = useParams();

  const renderRow = (payout, index) => {
    let shopPath =  `shops/${payout?.shop?.id}`;
    if (payout != null) {
      return (
        <>
          <div className="partner-payout-partner-name">
            <Detail to={`/partners/${payout?.partner?.id}`}>{`${payout?.partner?.lastName} ${payout?.partner?.firstName}`}</Detail>
          </div>
           <div className="partner-payout-ref">
            <p>{payout?.partner.ref}</p>
          </div>
          <div className="partner-payout-transaction-type">
          <p>{payout?.transaction?.type}</p>
          </div>
          <div className="partner-payout-shop-name">
            <DetailPartnerShop to={shopPath}>{`${payout?.shop?.name}`}</DetailPartnerShop>
          </div>
          <div className="partner-payout-price-cost">
            <Currency ammount={payout?.credit} />
          </div>
          <div className="partner-payout-created-date">{FormatDateTime(payout.createdDate)}</div>
          <div className="partner-payout-payment-date">{FormatDateTime(payout.paymentDate)}</div>
          <div className="partner-payout-status">
            <Status text={payout?.balanceStatus} />
          </div>
          <div className="partner-payout-transaction-id">
          <p>{payout?.transaction?.id}</p>
          </div>
          <div className="partner-payout-transaction-status">
            <Status text={payout?.transaction?.status} />
          </div>
        </>
      );
    }
  };

  return (
    <div className="container">
      <div className="row">
        <ListViewSimplePaging
          allowSearch
          searchPlaceHolder="Tìm kiếm theo ref, họ tên, email"
          className="payout-file-border"
          idField="partnerBalanceId"
          getData={(paging) => getData(paging, partnerId)}
          pageSize={ListViewPageSize.SMALL_PAGE_SIZE}
          renderRow={renderRow}
          headers={headers}
        />
      </div>
    </div>
  );
};
