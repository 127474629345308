import React, { useState } from 'react';
import { toNumberString } from '../../global/utils/functions';

import './input.css';

export const InputCurrency = (props) => {
    const {
        id, className, value, isDisabled, isReadOnly, name, pattern, maxLength,
        onChange, onFocus, onBlur, onKeyPress,
        prefix, classIconPrefix, suffix, classIconSuffix
    } = props;

    const [isFocus, setIsFocus] = useState(false);

    const changeValue = e => {
        const value = e?.target?.value?.replaceAll(',', '').replaceAll('.', '');
        let ammount = parseInt(value);
        onChange?.(ammount);
    }

    const focus = e => {
        setIsFocus(true);
        onFocus?.(e);
    }

    const blur = e => {
        setIsFocus(false);
        onBlur?.(e);
    }

    const wrapperClassname = `next-input--stylized ${className} ${isFocus && 'next-input--is-focused'} ${isDisabled && 'next-input--disabled'} ${isReadOnly && 'next-input--readonly'}`;

    const inputClassName = `next-input next-input--invisible text-right`;

    const prefixClassName = `next-input-add-on next-input__add-on--before ${classIconPrefix}`;

    const suffixClassName = `next-input-add-on next-input__add-on--after ${classIconSuffix}`;

    return <div className={wrapperClassname}>
        {prefix && <div className={prefixClassName}>{prefix}</div>}
        <input
            id={id}
            className={inputClassName}
            value={toNumberString(value)}
            disabled={isDisabled}
            readOnly={isReadOnly}
            name={name}
            onChange={changeValue}
            onFocus={focus}
            onBlur={blur}
            onKeyPress={onKeyPress}
            pattern={pattern}
            maxLength={maxLength}
        />
        <div className={suffixClassName}>{suffix ?? "₫"}</div>
    </div>
}
