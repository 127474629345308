import { UserManager } from 'oidc-client';
import { WebStorageStateStore } from "oidc-client-ts";

const config = {
  authority: process.env.REACT_APP_CLIENT_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  redirect_uri: process.env.REACT_APP_CLIENT_SIGN_IN,
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SCOPE,
  userStore: new WebStorageStateStore({ store: localStorage }),
  post_logout_redirect_uri: process.env.REACT_APP_CLIENT_SIGN_OUT,
};

const userManager = new UserManager(config);

export const getUser = async () => {
  try {
    const user = await userManager.getUser();
    return user;
  } catch (e) {
    console.error(`Get user error! ${e}`);
    return null;
  }
}

export const signinRedirect = () => {
  userManager.signinRedirect();
}

export const signinRedirectCallback = () => userManager.signinRedirectCallback();

export function signoutRedirect() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirect()
}

export function signoutRedirectCallback() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirectCallback()
}

export const removeUser = userManager.removeUser;

export default userManager