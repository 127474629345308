import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { DataSearch, textSearchChange } from '../data_search'
import { useTextSearch } from '../data_search/data_search'

import './hint_text_box.css'

export const HintTextBox = ({
  usedBy = '',
  placeholder = 'Nhập từ khóa để tìm kiếm',
  loadData,
  onSelect = null,
  displayField = 'name',
  valueField = 'id',
  className = '',
}) => {
  const dispatch = useDispatch()

  const textSearch = useTextSearch(usedBy)

  const [data, setData] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [isShowPanel, setShowPanel] = useState(false)
  const [isSelecting, setSelecting] = useState(false)

  useEffect(() => {
    if (textSearch && data?.[selectedIndex]?.[displayField] !== textSearch)
      loadData?.(textSearch, 10).then(data => {
        if (data) {
          setData(data)
          setShowPanel(true)
        }
      })
  }, [textSearch])

  useEffect(() => console.log(selectedIndex), [selectedIndex])

  const hover = index => {
    setSelecting(true)
    setSelectedIndex(index)
  }

  const blur = () => {
    setSelecting(false)
  }

  const select = () => {
    if (isSelecting && selectedIndex >= 0) {
      dispatch(textSearchChange({ usedBy, textSearch: data[selectedIndex]?.[displayField] }))
      onSelect?.(data[selectedIndex]?.[valueField])
    }
    setShowPanel(false)
    // setSelectedIndex(null)
  }

  const escape = () => {
    setSelecting(false)
    setSelectedIndex(null)
    setShowPanel(false)
  }

  const pressArrow = e => {
    let index = 0
    switch (e.key) {
      case 'ArrowUp':
        index = (selectedIndex ?? data.length) - 1
        setSelecting(true)
        setSelectedIndex((index < 0 || index > data.length - 1) ? data.length - 1 : index)
        break
      case 'ArrowDown':
        index = (selectedIndex ?? -1) + 1
        setSelecting(true)
        setSelectedIndex(index > data.length - 1 ? 0 : index)
        break
      case 'Enter':
        isSelecting && select()
        break
      case 'Escape':
        escape()
        break
    }
  }

  return (
    <div className={`hint-textbox ${className}`} onKeyDown={pressArrow}>
      <DataSearch textSearchBind placeholder={placeholder} onBlur={select} usedBy={usedBy} />
      {isShowPanel && data?.length > 0 && (
        <div className='hint-textbox-panel'>
          {data.map((item, index) => (
            <div key={item[valueField]} className={`user-info text-ellipsis ${index === selectedIndex}`} onMouseEnter={() => hover(index)} onMouseOut={blur}>
              {item[displayField]}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
