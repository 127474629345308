import React from "react";
import { toDateTimeString } from "../../../global/utils/functions";
import { ThemeRepository } from "../../../repositories";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resizeImage } from "../../../global/utils/functions";
import { CardInfo, DataSave, Download, Link, ListViewSimplePaging, StatusSelector, Svg } from "../../../components";
import { Badges, FormatDateTime, Loading, Textarea, Tooltip } from "@haravan/react-components";
import { ThemePrice } from "../price";
import { dataChanged, dataSaved, setLoading } from "../../../components/data_save/data_save_slice";
import { STATUS, STATUS_THEME_NAME } from "../../../components/status/status";

import "./index.css";
import { ListViewHeader, ListViewPageSize, ListViewPagingInfo } from "../../../components/list_view/listview_simple_paging";
import { PageLayout } from "../../../components/page/page_layout";
import { Card } from "react-bootstrap";
import { ContentEditorInside } from "../../../global/contentEditor/content_editor_inside";
import { formatDescription } from "../../../components/format/format";

export const ThemeDetail = () => {
  const { themeId } = useParams();
  const dispatch = useDispatch();

  const [theme, setTheme] = useState(null);
  const [isLoadingData, setLoadingData] = useState(true);
  const [isRejected, setRejection] = useState(false);
  const [clonedFiles, setClonedFiles] = useState(null);
  const [status_Theme, setStatus_Theme] = useState(null);
  const [files_Stt, setFiles_Stt] = useState(null);

  const getData = (paging: ListViewPagingInfo, themeId: number) =>
    ThemeRepository.GetListThemeFile(themeId, paging.searchText, paging.before, paging.after, paging.limit);

  const headers = [
    { name: "Phiên bản", className: "theme-file-version-header" },
    {
      name: "Trạng thái",
      className: "theme-file-status-header",
    },
    { name: "Ghi chú", className: "theme-file-desc-header" },
    { name: "Ngày tạo", className: "theme-file-date-header" },
  ] as ListViewHeader[];

  useEffect(() => {
    ThemeRepository.GetTheme(themeId).then((rs) => {
      if (rs?.data) {
        let theme = rs.data;
        theme.isPublished = theme.isPublished ? true : false; //Todo: need a new status
        setStatus_Theme(theme?.status);
        setTheme(theme);
        setClonedFiles(theme?.files.reverse());
        setFiles_Stt(theme?.files);
        setRejection(theme.status === false);
      }
      setLoadingData(false);
    });
  }, []);
  const saveData = () => {

    dispatch(setLoading(true));
    ThemeRepository.PutApproveTheme(theme).then((rs) => {
      if (rs?.data || !rs?.has_error) {
        dispatch(dataSaved());
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      else dispatch(setLoading(false));
    });
  };

  const handleOnChangeStatus = (value) => {
    const clonedTheme = { ...theme, status: value };
    setRejection(value === STATUS_THEME_NAME.Rejected);
    setTheme(clonedTheme);
    dispatch(dataChanged({ hasChanged: value !== theme.status, data: clonedTheme }));
  };

  const handleOnChangeFileStatus = (index, value) => {
    
    let clonedFiless = [...clonedFiles];
    let clonedFile = { ...theme.files[index], statusId: value };
    clonedFiless[index] = clonedFile;
    setClonedFiles(clonedFiless);
    let clonedTheme = { ...theme, files: clonedFiless };
    dispatch(dataChanged({ hasChanged: value !== theme.files[index]?.statusId, data: clonedTheme }));
    setTheme({...clonedTheme});
  };

  const handleOnChangeReason = (value) => {
    const clonedTheme = { ...theme, rejectionReason: value };
    setTheme(clonedTheme);
    dispatch(dataChanged({ hasChanged: value !== theme.rejectionReason, data: clonedTheme }));
  };

  const handleOnChangeStatusPrice = (value) => {
    let clonedPrice = { ...theme.price };
    clonedPrice = { ...clonedPrice, status: value };
    const clonedTheme = { ...theme, price: clonedPrice };
    setTheme(clonedTheme);
    dispatch(dataChanged({ hasChanged: value !== theme.price?.status, data: clonedTheme }));
  };

  const handleOnChangeReasonPrice = (value) => {
    let clonedPrice = { ...theme.price, rejectionReason: value };
    const clonedTheme = { ...theme, price: clonedPrice };
    setTheme(clonedTheme);
    dispatch(dataChanged({ hasChanged: value !== theme.price?.rejectionReason, data: clonedTheme }));
  };

  const handleOnChangePrice = (value) => {
    let clonedPrice = { ...theme.price };
    clonedPrice = { ...clonedPrice, costApproved: value };
    const clonedTheme = { ...theme, price: clonedPrice };
    setTheme(clonedTheme);
    dispatch(dataChanged({ hasChanged: value !== theme.price?.costApproved, data: clonedTheme }));
  };

  const renderRow = (file, index) => {
    return (
      <>
        <div className="theme-file-version">
          <Download className="theme-file-download" link={file?.file} children={file?.version} />
        </div>
        <div className="theme-file-status">
          <StatusSelector listStatus={STATUS} value={file?.statusId} 
          disableSelect={files_Stt[index]?.statusId == STATUS_THEME_NAME.Approved || files_Stt[index]?.statusId == STATUS_THEME_NAME.Rejected}
           onSelect={(value) => handleOnChangeFileStatus(index, value)} />
        </div>
        <div className="theme-file-desc">
          {file?.description != null ? formatDescription(file.description) : null}
        </div>
        <div className="theme-file-date">{FormatDateTime(file.updatedAt || file.createdAt)}</div>
      </>
    );
  };

  const renderInformation = () => {
    return (
      <PageLayout>
        <PageLayout.AnotatedSection
          title="Thông tin giao diện"
          description="Thông tin mô tả chi tiết giao diện">
          <CardInfo className="theme-content">
            <div className="theme-status">
              <StatusSelector listStatus={STATUS} value={theme?.status} 
              disableSelect={status_Theme == STATUS_THEME_NAME.Approved || status_Theme == STATUS_THEME_NAME.Rejected} onSelect={handleOnChangeStatus} />
            </div>
            {isRejected && (
              <div className="theme-rejection-reason">
                <span>{isRejected && "Lý do từ chối:"}</span>
                <Textarea value={theme?.rejectionReason ?? ""} onChange={handleOnChangeReason} />
              </div>
            )}
            <div className='app-partner'>
              <span>Phát triển bởi</span>
              <Link to={`/partners/${theme?.partner?.id}`}>{`${theme?.partner?.lastName} ${theme?.partner?.firstName}`}</Link>
            </div>
            <div className="theme-name">
              <span>Tên giao diện</span>
              <div>{theme?.name ?? ""}</div>
            </div>
            <div className="theme-description">
              <span>Mô tả</span>
              {theme?.description ? <ContentEditorInside value={theme?.description} /> : <div className="no-data"> Không có mô tả.</div>}
            </div>
            <div className="theme-site-demo-url">
              <span>Link demo</span>
              {theme?.siteDemoUrl ? (
                <div>
                  <a href={theme?.siteDemoUrl ?? ""} target="blank">
                    {theme?.siteDemoUrl ?? ""}
                  </a>
                </div>
              ) : (
                <div className="no-data"> Không có link site demo</div>
              )}
            </div>
            <div className="theme-default-categories">
              <span>Danh mục</span>
              {theme?.defaultCategories ? (
                <div>
                  <div>{theme?.defaultCategories ?? ""}</div>
                </div>
              ) : (
                <div className="no-data">Không có danh mục</div>
              )}
            </div>
            <div className="theme-code">
              <span>Theme code</span>
              {theme?.themeCode ? (
                <div>
                  <div>{theme?.themeCode ?? ""}</div>
                </div>
              ) : (
                <div className="no-data">Không có theme code</div>
              )}
            </div>
            <div>
              <div className="theme-image-labels">
                <span>Website image</span>
                <span>Mobile image</span>
              </div>
              <div className="theme-image-banner">
                <div>
                  {websiteImage && (
                    <div>
                      <a href={websiteImage.value} target="blank">
                        <img className="logo-img" src={resizeImage(websiteImage.value, "small")} alt="" loading="lazy" />
                      </a>
                    </div>
                  )}
                </div>
                <div>
                  {mobileImage && (
                    <div>
                      <a href={mobileImage.value} target="blank">
                        <img className="logo-img" src={resizeImage(mobileImage.value, "small")} alt="" loading="lazy" />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CardInfo>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  };

  const renderVersion =() =>{
    return (
      <PageLayout>
        <PageLayout.AnotatedSection
          title="Phiên bản giao diện"
          description="Bảng chứa các phiên bản của giao diện">
          <CardInfo className="theme-content">
          <div className="theme-version-file">
          <ListViewSimplePaging
            className="theme-file-border"
            getData={(paging) => getData(paging, theme.id)}
            pageSize={ListViewPageSize.SMALL_PAGE_SIZE}
            renderRow={renderRow}
            headers={headers}
          />
        </div>
          </CardInfo>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  }

  const renderPriceThemes = () =>{
    return (
      <PageLayout>
        <PageLayout.AnotatedSection
          title="Bảng giá giao diện"
          description="">
           <CardInfo className="theme-content-price">
          {theme?.price &&(
            <div className="theme-list-price">
            <ThemePrice
              price={theme?.price}
              onChangePrice={handleOnChangePrice}
              onChangeStatus={handleOnChangeStatusPrice}
              onChangeReason={handleOnChangeReasonPrice}
            />
            </div>
          )}
      </CardInfo>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  }

  const websiteImage = theme?.claims?.find((c) => c.type == "website-image");
  const mobileImage = theme?.claims?.find((c) => c.type == "mobile-image");

  return isLoadingData ? (
    <Loading className="hrv-loading-page" size="thumb" />
  ) : (
    <>
      <div className="theme-detail">
        {renderInformation()}
        {renderVersion()}
        {renderPriceThemes()}
      </div>
      <DataSave onSaveData={saveData} /></>
  );
};
