import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  content: null
}

export const tabItemSlice = createSlice({
  name: 'tab-item',
  initialState,
  reducers: {

  },
})                         

export default tabItemSlice.reducer
