import logo from "../../global/images/haravan-logo.svg";
import { Dropdown, Icon } from "@haravan/react-components";
import { getUrlAccount } from "../../global/utils/functions";
import { useSelector } from "react-redux";
import { selectUser } from "../../screens/auth/services/auth_slice";
import { useNavigate } from 'react-router-dom';

import "./header.css";
import { useEffect } from "react";
import { FormatEmai } from "../../components/format/format";

export const Header = () => {
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  const onClickViewProfile = () => {
    window.open(getUrlAccount(), "_blank");
  };

  const onClickLogout = () => {
    window.location.href = "./signout-callback-oidc";
  };

  const onClickHaravanIcon = () => {
    navigate("/shops");
  };
  const content = (
    <div className="header-modal shadow-lg rounded ">
      {
        user && <div className="account-info">
          <strong className="font-weight-bold m-0">{user.profile.name}</strong>
          <p className="account-infor-email m-0">{FormatEmai(user.profile.email)}</p>
        </div>
      }
      <div className="account-action">
        <li className="account-action-item font-weight-normal" onClick={onClickViewProfile}>
          <Icon type="userBorder" theme="regular" className="m-2" /> <span>Tài khoản</span>
        </li>
        <li className="account-action-item font-weight-normal " onClick={onClickLogout}>
          {" "}
          <Icon type="logout" theme="regular" className="m-2" /> <span>Đăng xuất</span>{" "}
        </li>
      </div>
    </div>
  );

  return user ? (
    <div className="header-wapper d-flex justify-content-between">
      <div className="header-logo">
        <a onClick={onClickHaravanIcon}><img src={logo} alt="" className="header-log-img" /></a>
      </div>
      <div className="header-user">
        <Dropdown content={content}>
          <div className=" d-flex justify-content-between  align-items-center p-2">
            <h5 className="m-0 text-white header-title">{user.profile.name}</h5>
            <div className="header-logo-account">
              <img src={user.profile.picture} alt="" style={{ height: "40px" }} />
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  ) : <></>;
};
