import React from 'react'
import { Loading } from '@haravan/react-components'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { dataChanged } from '../../../components/data_save/data_save_slice';
import { PartnerRepository } from '../../../repositories';
import { isEqual, setDefaultValue } from '../../../global/utils/functions';
import { CardDetail, Email, Phone } from '../../../components'
import { useParams } from 'react-router-dom';

export const PartnerInfo = (props) => {
    const { partnerId, onChangeData } = useParams();

    const [partnerOrigin, setPartnerOrigin] = useState(null)
    const [isLoadingData, setLoadingData] = useState(true)
    const [partner, setPartner] = useState(null)

    const dispatch = useDispatch();

    useEffect(() => {
        !partnerOrigin && PartnerRepository.GetPartner(partnerId)
            .then(rs => {
                setPartnerOrigin(setDefaultValue(rs.data))
                setPartner(setDefaultValue(rs.data))
                setLoadingData(false);
            })
        return () => { dispatch(dataChanged({ hasChanged: false, data: null })) }
    }, [])

    const changeData = (fieldName, oldData, newData) => {
        let partnerClone = { ...partner }
        const isChanged = oldData !== newData

        if (isChanged) partnerClone = { ...partner, [fieldName]: newData }
        else delete partnerClone[fieldName]

        dispatch(dataChanged({
            hasChanged: !isEqual(partnerOrigin, partnerClone),
            data: partnerClone
        }))
        setPartner(partnerClone)
    }


    return isLoadingData ? (
        <Loading className='hrv-loading-page' size='thumb' />
    ) : <div className='container'>
        <div className='row'>
            <div className='col-xl-6 mt-4'>
                <CardDetail
                    titleHeader={"Thông tin chung"}
                    data={[
                        { name: 'Họ tên:', content: `${partner?.lastName} ${partner?.firstName}` },
                        { name: 'Ref:', content: partner?.ref },
                        { name: 'Email đăng nhập:', content: <Email to={partner?.userName} /> },
                        { name: 'Email hỗ trợ:', content: <Email to={partner?.supportEmail} /> },
                        { name: 'Số điện thoại:', content: <Phone to={partner?.phone} /> },
                        { name: 'Địa chỉ:', content: partner?.address }
                    ]} />
            </div>
            <div className='col-xl-6 mt-4'>
                <CardDetail
                    titleHeader={" Thông tin thanh toán"}
                    data={[
                        { name: 'Tên ngân hàng:', content: partner?.bankInfo_BankName },
                        { name: 'Số tài khoản:', content: partner?.bankInfo_AccountNumber },
                        { name: 'Tên chủ tài khoản:', content: partner?.bankInfo_AccountUsername },
                        { name: 'Mã số thuế cá nhân:', content: partner?.personalId },
                        {
                            name: '% chia sẻ khi shop mua gói:',
                            content: partner?.payment_SharePercent_ShopPlan
                        },
                        {
                            name: '% chia sẻ khi shop mua app:',
                            content: partner?.payment_SharePercent_App
                        },
                        {
                            name: '% chia sẻ khi shop mua theme:',
                            content: partner?.payment_SharePercent_Theme
                        },
                    ]}
                />
            </div>
            <div className='col-xl-6 mt-4'>
                <CardDetail
                    titleHeader={" Thông tin dịch vụ"}
                    data={[
                        {
                            name: 'Thẻ:',
                            content: partner?.tags
                        },
                        {
                            name: 'Ghi chú:',
                            content: partner?.note
                        },
                    ]}
                />
            </div>
        </div>

    </div>

}
