import { useState } from 'react'
import CheckboxTree from 'react-checkbox-tree'
import { Icon } from '@haravan/react-components'

import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import './tree_view.css'
import { useEffect } from 'react';

export function TreeView({ data = [], selectedData = [], onCheckChanged = null }) {
  const [checkedItems, setCheckedItems] = useState(selectedData)
  const [expandedItems, setExpandedItems] = useState([])

  // useEffect(() => onCheckChanged?.([...checkedItems]), [onCheckChanged, checkedItems])

  useEffect(() => {
    setCheckedItems(selectedData)
  }, [selectedData])

  const handleOnCheck = (values, target) => {
    setCheckedItems(values)
    onCheckChanged?.([...values], { ...target })
  }

  return (
    <CheckboxTree
      icons={{
        check: <Icon type='check' theme='regular' />,
        // uncheck: <Icon type="circle" theme="solid"/>,
        expandClose: <Icon type='plus' theme='regular' />,
        expandOpen: <Icon type='minus' theme='regular' />,
        expandAll: <Icon type='plus' theme='regular' />,
        collapseAll: <Icon type='minus' theme='regular' />,
        // parentClose: <Icon type='plus' theme='regular' />,
        // parentOpen: <Icon type='plus' theme='regular' />,
        // leaf: <Icon type='plus' theme='regular' />,
      }}
      nodes={data}
      iconsClass='fa5'
      checked={checkedItems}
      expanded={expandedItems}
      onCheck={handleOnCheck}
      onExpand={setExpandedItems}
    />
  )
}
