import { useSelector } from "react-redux";
import { getMenus } from "./left_menu_slice";
import { MenuItem } from "./menu-item";

import "./menu_left.css";

export const MENU_MAPPER = {
  "/shops": "/shop",
  "/transactions": "/payment",
  "/partners": "/partner",
  "/apps": "/appstore",
  "/themes": "/themestore",
  "/payouts": "/balance",
  "/shopplans": "/shopplans",
  "/users": "/user",
  "/roles": "/roles",
  "/products": "/products",
}

export const MENU_LEFT = [
  { icon: "Store", text: "Cửa hàng", link: "/shops", active: true },
  { icon: "Exchange", text: "Giao dịch", link: "/transactions", active: false },
  { icon: "Partner", text: "Đối tác", link: "/partners", active: false },
  { icon: "App", text: "Ứng dụng", link: "/apps", active: false },
  { icon: "Theme", text: "Giao diện", link: "/themes", active: false },
  { icon: "Payout", text: "Công nợ", link: "/payouts", active: false },
  { icon: "Products", text: "Products", link: "/products", active: false, menuSub: [{ icon: "Products", text: "ShopPlans", link: "/shopplans" }] },
  { icon: "Shopplans", text: "Shop Plans", link: "/shopplans", active: false, display: false },
  { icon: "User", text: "Người dùng", link: "/users", active: false },
  { icon: "Permission", text: "Nhóm quyền", link: "/roles", active: false },
]

export const ALL_MENU_LINKS = MENU_LEFT.map(m => m.link.replace('/', ''))

export const LeftMenu = () => {
  const menus = useSelector(getMenus)
  return (
    <div className="menu-left">
      {menus.map((item, index) => (
        <MenuItem key={index} {...item} />
      ))}
    </div>
  );
};
