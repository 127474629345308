import { useEffect, useRef, useState } from "react";
import { Button, Selection, Icon, InputHasIcon } from "@haravan/react-components";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { OptionProps } from "@haravan/react-components/lib/Selection/Selection";
import { RenderValueFilter, FilterData, FilterSearchField, FilterDataDefault } from "../filter-bar/filter-data";
import { IDictionary } from "../../global/utils";
import { IFilterType } from "../../global/utils/functions";
import "./index.css";

interface FilterBarProps {
  type: IFilterType;
  onChange: (filters: FilterSearchField[]) => void;
}

export const FilterBar = (props: FilterBarProps) => {
  const { type, onChange } = props;
  const [titleFilter, setTitleFilter] = useState(null);
  const [keyFilter, setKeyFilter] = useState({} as IDictionary);
  const [showPopover, setShowPopover] = useState(false);
  const [optionsFilter, setOptionsFilter] = useState([] as OptionProps[]);
  const [filters, setFilters] = useState([] as FilterSearchField[]);
  const ref_element = useRef(null);

  const defaultDatafilter = () => {
    let dict = FilterDataDefault(type);
    setKeyFilter({ key: dict.key, value: dict.name } as IDictionary);
    setTitleFilter(dict.name);
  };

  useEffect(() => {
    setOptionsFilter(FilterData(type));
    defaultDatafilter();
  }, []);

  const onFilter = () => {
    onChange(filters);
    setShowPopover(!showPopover);
  };

  const onChangeFilterKey = (value) => {
    let opt = optionsFilter.find((o) => o.value == value);
    setKeyFilter({ key: opt.value, name: opt.children } as IDictionary);
  };

  const onExitOverlay = () => {
    defaultDatafilter();
  };

  const popover = (
    <Popover>
      <Popover.Header as="h6">Hiển thị tất cả {titleFilter} theo</Popover.Header>
      <Popover.Body>
        <div className="filter-content">
          <div className="filter-content-key" ref={ref_element} style={{ position: "relative" }}>
            {optionsFilter && optionsFilter.length > 0 && (
              <Selection defaultValue={optionsFilter[0].value} onChange={onChangeFilterKey} getPopupContainer={() => ref_element.current}>
                {optionsFilter.map((opt, i) => (
                  <Selection.Option {...opt}></Selection.Option>
                ))}
              </Selection>
            )}
          </div>
          <div className="filter-content-value flex">
            <span className="lable-is">
              <strong>Tại</strong>
            </span>
            <RenderValueFilter
              keyObject={keyFilter}
              type={type}
              onChange={(filters) => {
                setFilters(filters);
              }}
            />
          </div>
          <div className="filter-content-btn">
            <Button status="default" onClick={() => setShowPopover(!showPopover)}>
              <span className="pl-1">Huỷ</span>
            </Button>
          </div>
          <div className="filter-content-btn">
            <Button status="primary" onClick={() => onFilter()}>
              <span className="pl-1">Thêm điều kiện</span>
            </Button>
          </div>
          <div style={{ clear: "both" }}></div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger show={showPopover} onExit={onExitOverlay} overlay={popover} trigger="click" placement="bottom-start" rootClose>
        <Button
          status="default"
          onClick={() => {
            setShowPopover(!showPopover);
          }}
        >
          <svg width="14" height="14" viewBox="0 0 459 459">
            <g>
              <path d="M178.5,382.5h102v-51h-102V382.5z M0,76.5v51h459v-51H0z M76.5,255h306v-51h-306V255z"></path>
            </g>
          </svg>
          <span className="pl-1">Thêm điều kiện lọc</span>
        </Button>
      </OverlayTrigger>
    </>
  );
};

export const SearchBar = ({
  placeholder = "Nhập từ khóa để tìm kiếm",
  iconSize = 16,
  className = "",
  onChange = console.log,
  onBlur = console.log,
  value = ""
}) => {
  const [text, setText] = useState(null);

  useEffect(() => {
    setText(value);
  }, [value])

  const search = (textSearch) => {
    setText(textSearch);
    onChange(textSearch);
  };

  const blur = (textSearch) => {
    setText(textSearch);
    onBlur?.();
  };

  return (
    <div className={`text-search-wrapper ${className}`}>
      <InputHasIcon value={text} placeholder={placeholder} prefix={<Icon type="search" size={iconSize} />} onChange={search} onBlur={blur} />
    </div>
  );
};
