import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {}

interface DataFilterProps {
  usedBy?: string;
  filter: object;
}

export const dataFilterSlice = createSlice({
  name: 'data-filter',
  initialState,
  reducers: {
    changeFilter: (state, action: PayloadAction<DataFilterProps>) => {
      const { usedBy, filter } = action.payload;
      state[usedBy] = filter
    },
  },
});

export const { changeFilter } = dataFilterSlice.actions;

export const selectFilter = state => usedBy => state.dataFilter[usedBy];

export default dataFilterSlice.reducer;
