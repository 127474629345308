import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeTab: '.',
};

export const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    tabChange: (state, action) => {
      state.activeTab = action.payload.path
    }
  },
}); 

export const { tabChange } = tabSlice.actions;

export const selectActiveId = state => state.tab.activeTab;
export const selectActiveContent = state => state.tab.content;

export default tabSlice.reducer;
