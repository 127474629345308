import { Outlet } from 'react-router-dom'

import './tab.css'

interface TabProps {
  children: JSX.Element | JSX.Element[]
}

export const Tab = (props: TabProps) => {
  const { children } = props
  return (
    <div className='tab-wrapper'>
      <div className='tab-item-wrapper'>{children}</div>
      <div className='tab-content-wrapper'>
        <div className='tab-content'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
