import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, DropdownCustom, Notification, Loading, Selection, Badges, Input } from '@haravan/react-components'
import { InputDebounce, Status } from '../../../../../components'
import { HACRepository, StoreRepository, PermissionRepository } from '../../../../../repositories'
import { closePopup } from '../../../../../components/popup/popup_slice'
import { selectUser } from '../../../../auth/services/auth_slice'
import * as Env from '../../../../../global/utils/env';

import './index.css'

export function RequestAccess() {
  const dispatch = useDispatch()
  const { shopId } = useParams()

  const [user, setUser] = useState({ userName: '', name: '', description: '', orgId: 0, approvedBy: 0, data: null, groupNameRoles: [], expiredAt: null })
  const [roles, setRoles] = useState([])
  const [groupRoles, setGroupRoles] = useState([])
  const [listAdmins, setListAdmins] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedDate, selectDate] = useState([expiredDates[0]])
  const userLogin = useSelector(selectUser)
  const group_social_roles = ["social_group_admin", "social_group_chat_agent", "social_group_marketing_agent", "social_group_reporter_agent"]

  useEffect(() => {
    let date = new Date()
    fetchConstRoles();
    fetchConstAdmins();
    date.setDate(date.getDate() + expiredDates[0].dayCount)
    setUser({ ...user, orgId: parseInt(shopId), name: userLogin?.profile?.name, userName: userLogin?.profile?.email, expiredAt: date.toISOString() })
  }, [])

  const selectRole = items => {
    var role_social = items.filter(o => group_social_roles.includes(o.code));
    if (role_social.length > 1) {
      items = items.filter(o => !group_social_roles.includes(o.code));
      items.push(role_social[0]);
    }

    setRoles(items)
    let roles = [];
    let names = [];
    let attr = null;
    items.map(item => {
      item.code.split(",").map(code => {
        if(group_social_roles.includes(code))
        {
          attr = {social_group_permission: code};
          roles.push("com_api.harasocial.read")
          if(code == "social_group_admin")
            roles.push("hac_api.user.read")
        } else roles.push(code)
      });
     
      names.push(item.name)
    });
    var data = JSON.stringify({ roles: roles, attribute: attr });
    setUser({ ...user, data: data, groupNameRoles: names })
  }

  const fetchConstRoles = () => {
    PermissionRepository.GetRolesForRequestAccess().then(rs => {
      var roles = [] as any[];
      rs.data.map(o => {
        roles.push({ name: o.key, code: o.value })
      })
      setGroupRoles(roles);
    })
  }

  const fetchConstAdmins = () => {
    HACRepository.GetListAdmins(shopId).then(rs => {
      setListAdmins(rs.data);
    })
  }

  const selectExpiredDate = days => {
    let date = new Date()
    date.setDate(date.getDate() + days[0].dayCount)
    setUser({ ...user, expiredAt: date.toISOString() })
    selectDate(days)
  }

  const save = () => {
    if (!user.userName) {
      Notification.error({ message: 'Chưa có email', duration: 2 })
      return
    }
    if (!Env.checkEmail(user.userName)) {
      Notification.error({ message: 'Email không hợp lệ', duration: 2 })
      return
    }
    if (process.env.NODE_ENV === 'production' && !user.userName.toLowerCase().includes("@haravan.com")) {
      Notification.error({ message: 'Email không hợp lệ', duration: 2 })
      return
    }

    if (!user.approvedBy) {
      user.approvedBy = listAdmins[0]?.id as number;
    }
    if (!user.description) {
      Notification.error({ message: 'Chưa nhập lý do', duration: 2 })
      return
    }
    if (!user.expiredAt) {
      Notification.error({ message: 'Chưa nhập thời gian hết hạn', duration: 3 })
      return
    }
    if (roles == null || roles.length <= 0) {
      Notification.error({ message: 'Chưa chọn quyền', duration: 2 })
      return
    }

    setIsLoading(true);
    HACRepository.CreateTempUser(user)
      .then(rs => {
        if (rs?.error) Notification.error({ message: rs.message, duration: 3 })
        else if (rs) {
          Notification.success({ message: 'Gửi thành công', duration: 2 })
          dispatch(closePopup())
        }
        else Notification.error({ message: 'Có lỗi xảy ra', duration: 2 })
      })
      .catch(err => Notification.error({ message: 'Có lỗi xảy ra', duration: 2 }))
      .finally(() => {
        setIsLoading(false);
      })
  }

  return (
    <div className='shop-user'>
      {user && <div className='class-subscription'>
        <span>Email:</span>
        {/* <HintTextBox placeholder='Nhập email người dùng' loadData={fetchSSOUsers} onSelect={selectUser} displayField='userName' valueField='userName' /> */}
        <Input value={user.userName} onChange={value => setUser({ ...user, userName: value })} />
      </div>}
      {listAdmins?.length > 0 && <div className='class-subscription'>
        <span>Duyệt bởi:</span>
        <Selection defaultValue={listAdmins[0].id} onChange={value => setUser({ ...user, approvedBy: value as number })}>
          {listAdmins.map(o => {
            return <Selection.Option value={o.id} >{`${o.name} (${o.username})`} {o.isRoot && <Badges className='badges_owner' status='primary'>Owner</Badges>}</Selection.Option>
          })}
        </Selection>
      </div>
      }
      <div className='class-subscription'>
        <span>Lý do:</span>
        <InputDebounce onChange={description => setUser({ ...user, description })} />
      </div>
      <div className='class-subscription'>
        <span>Thời gian hết hạn:</span>
        <DropdownCustom
          placeHolder='Chọn ngày hết hạn'
          listValue={selectedDate}
          listData={expiredDates}
          keyId='dayCount'
          keyName='name'
          onChangeItems={selectExpiredDate}
          isHideSearch
          isHideIconRemove
        />
      </div>
      <div className='class-subscription'>
        <span>Danh sách quyền:</span>
        <DropdownCustom
          placeHolder='Chọn quyền'
          listData={groupRoles}
          listValue={roles}
          mutilple
          typeTag
          keyId='code'
          keyName='name'
          isHideBtnAdvandce
          onChangeItems={selectRole}
        />
      </div>
      <div className='shop-user-btn'>
        <Button status='default' onClick={() => dispatch(closePopup())}>
          Hủy
        </Button>
        <Button status='primary' onClick={save}>
          {!isLoading ? "Gửi" : <Loading className='hrv-loading-btn' size='pico' />}
        </Button>
      </div>
    </div>
  )
}

const expiredDates = [
  {
    dayCount: 1,
    name: '1 ngày',
  },
  {
    dayCount: 3,
    name: '3 ngày',
  },
  {
    dayCount: 7,
    name: '7 ngày',
  },
  {
    dayCount: 15,
    name: '15 ngày',
  },
  {
    dayCount: 30,
    name: '30 ngày',
  },
]
