import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  menus: [],
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    changeMenu: (state, action: PayloadAction<string>) => {
      state.menus.forEach(item => item.active = item.link === action.payload)
    },
    storeMenu: (state, action: PayloadAction<any>) => {
      state.menus = action.payload;
    }
  },
});

export const { changeMenu, storeMenu } = menuSlice.actions;
export const getMenus = state => state.menu.menus;
export default menuSlice.reducer;
