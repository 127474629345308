import { Popover } from "@haravan/react-components";
import React, { useEffect, useRef, useState } from "react";
import { Status } from "../status/status";
import * as HrvComponents from '@haravan/react-components';

import './status_selector.css';

export const StatusSelector = ({ listStatus = [], value, onSelect, disableSelect = false }) => {
  const [selectedStatus, setSelectedStatus] = useState(value);
  const [isShowRemaining, setShowRemaining] = useState(false);
  const ref_element = useRef(null);

  const selectStatus = (status) => {
    setSelectedStatus(status);
    setShowRemaining(false);
    onSelect(status);
  }

  const showRemaining = (visible) => {
    setShowRemaining(visible);
  }

  const remainingStatus = listStatus.filter(s => s !== selectedStatus);

  const statusList = (isShowRemaining && <div className="status-list">
    {remainingStatus.map((s, i) => <Status key={i} className="status-remaining" text={s} onClick={selectStatus} />)}
  </div>);

  return <div className="status-selector" >
    <div ref={ref_element} style={{ position: "relative" }}>
      {
        <div ref={ref_element} style={{ position: "relative" }}>
          <HrvComponents.Selection getPopupContainer={() => ref_element.current} disabled={disableSelect} defaultValue={selectedStatus} onChange={selectStatus}>
            {listStatus.map((value, i) => (
              <HrvComponents.Selection.Option key={"status-selector" + i} value={value}>{value}</HrvComponents.Selection.Option>
            ))}
          </HrvComponents.Selection>
        </div>
      }
    </div>
  </div>
}