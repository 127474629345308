import * as ApiHelpers from './api_helper';

const apiRoute = 'transactions';

export const TransactionRepository = {
  Filter: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/filter`, model);
  },
  GetDetail: (id) => ApiHelpers.api_call_get(`${apiRoute}/${id}`),
  Export: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/export`, model);
  },
  ElectronicBill: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/electronic-bill`, model);
  },
  UpdateNote : (id, model) => ApiHelpers.api_call_put(`${apiRoute}/${id}/note`,model),
  UpdateInvoiceno : (id, model) => ApiHelpers.api_call_put(`${apiRoute}/${id}/invoiceno`,model),
  UpdateTransactionUser : (id, model) => ApiHelpers.api_call_put(`${apiRoute}/${id}/user`,model),
};
