import { useParams } from 'react-router-dom';
import { FormatDateTime } from "@haravan/react-components";
import { PartnerRepository } from "../../../../repositories";
import { Currency, Detail, Link, Status } from "../../../../components";
import { ListViewHeader, ListViewPageSize, ListViewPagingInfo, ListViewSimplePaging } from "../../../../components";
import { STATUS_APP_NAME } from '../../../../components/status/status'

import "./index.css";

const getData = (paging: ListViewPagingInfo, id) => PartnerRepository.GetAppByPartnerId(id, paging.searchText, paging.before, paging.after, paging.limit);

const headers = [
  { name: "Tên ứng dụng", className: "partner-app-name" },
  { name: "Đối tác", className: "partner-app-partner-name" },
  { name: "Giá bán", className: "partner-app-price-cost" },
  { name: "Hạn dùng thử", className: "partner-app-trial-days" },
  { name: "Ngày đăng ký", className: "partner-app-created-date" },
  { name: "Trạng thái", className: "partner-app-status" },
] as ListViewHeader[];

export const PartnerApp = (props) => {
  const { partnerId } = useParams();

  const renderRow = (app, index) => {
    let prices = app?.prices?.filter((p) => p.status == STATUS_APP_NAME.Approved).map((p) => p.priceApprove);
    let min = prices?.length ? Math.min(...prices) : 0;
    let max = prices?.length ? Math.max(...prices) : 0;
    if (app != null) {
      return (
        <>
          <div className="partner-app-name">
            <Detail to={`./${app?.id}`}>{`${app?.name}`}</Detail>
          </div>
          <div className="partner-app-partner-name">
            <Detail to={`/partners/${app?.partner?.id}`}>{`${app?.partner?.lastName} ${app?.partner?.firstName}`}</Detail>
          </div>
          <div className="partner-app-price-cost">
            {prices?.length > 1 ? (
              <>
                <Currency ammount={min} /> <div className='partner-app-price-mid'>-</div> <Currency ammount={max} />
              </>
            ) : (
              <Currency ammount={min} />
            )}
          </div>
          <div className="partner-app-trial-days">
            <p>{app?.trialDay}</p>
          </div>
          <div className="partner-app-created-date">{FormatDateTime(app.createdAt)}</div>
          <div className="partner-app-status">
            <Status text={app.status} />
          </div>
        </>
      );
    }
  };

  return (
    <div className="container">
      <div className="row">
        <ListViewSimplePaging
          allowSearch
          searchPlaceHolder="Tìm kiếm theo id, tên"
          className="theme-file-border"
          getData={(paging) => getData(paging, partnerId)}
          pageSize={ListViewPageSize.SMALL_PAGE_SIZE}
          renderRow={renderRow}
          headers={headers}
        />
      </div>
    </div>
  );
};
