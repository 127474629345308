import { ShopplanRepository } from '../../../repositories';
import { Currency, DataList, DataSearch, Detail, Status } from '../../../components';

import './index.css';
import { FormatCategory } from '../../../components/format/format';

const headers = [
  { name: 'Name', className: 'plans-name-col' },
  { name: 'Code', className: 'plans-planCode-col' },
  { name: 'Product', className: 'plans-planCode-col' },
  { name: 'Cost', className: 'plans-cost-col' },
  { name: 'Ref Cost', className: 'plans-ref-col' },
  { name: 'Interval', className: 'interval-col' },
  { name: 'Interval Count', className: 'interval-count-col' },
  { name: 'Status', className: 'plans-isactive-col' }
];

interface ShopPlansListProps {
  productId?: any,
  pageSize: number
}

export function PlansListComponent(props: ShopPlansListProps) {
  const { productId, pageSize } = props;
  const fetchShopPlans = async (page, page_size, search) => {
    const response = await ShopplanRepository.GetListShopPlans(page, pageSize, search, productId);
    const list = response?.data?.data?.map(a => ({
      name: <Detail to={`/products/${a.productId}/shopplans/${a.id}`}>{a.name}</Detail>,
      productCode: a.productCode != null ? FormatCategory(a.productCode) : '',
      productName: a.product ? <Detail to={`/products/${a.productId}`}>{a.product.name}</Detail> : <></>,
      price: <Currency ammount={a.cost} />,
      refCost: <Currency ammount={a.refCost} />,
      Interval: a.interval,
      intervalCount: a.intervalCount,
      isactive: a.isActived ? <Status text="active" /> : <Status text="inactive" />
    }));
    const rawData = response?.data?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  };

  return (<div className='shopplans-list'>
    <DataSearch className='mb-md' placeholder='Tìm kiếm theo id, tên, code' />
    <DataList page_size={pageSize} headers={headers} loadData={fetchShopPlans} />
  </div>)
}

export const ShopPlansList = () => {
  return <PlansListComponent pageSize={20} />
};
