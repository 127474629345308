import { StoreRepository } from '../../../../../repositories'
import { toDateTimeString } from '../../../../../global/utils/functions'
import { useParams } from 'react-router-dom'
import { Collapse, Icon } from '@haravan/react-components'
import { DataList } from '../../../../../components'
import { ListViewHeader } from '../../../../../components/list_view/listview_simple_paging'
import './index.css'

const headersTickets = [
    { name: 'Id', className: 'tickets-id' },
    { name: 'Ticket', className: 'tickets-name' },
    { name: 'Loại', className: 'tickets-type' },
    { name: 'Nhân viên', className: 'tickets-emp' },
    { name: 'Ngày ghi nhận', className: 'tickets-created-date' }
] as ListViewHeader[]
const chooseIcon = ({ isActive }) => isActive ? <Icon type='minusCircle' theme='solid' /> : <Icon type='plusCircle' theme='solid' />;

export const Tickets = props => {
    const { shopId } = useParams()

    const getTickets = async (page, pageSize, search, filter) => {
        const response = await StoreRepository.GetTiketsByShopId(shopId, page, pageSize);
        let list = [];
        response?.data?.data.map((obj) => {
            let data = {
                id: <p>{obj.id}</p>,
                subject: <p><a href={obj.url} target="_blank">{obj.subject}</a></p>,
                type: <p>{obj.type}</p>,
                agentEmail: <p>{obj.agentEmail}</p>,
                createdAt: <p>{toDateTimeString(obj.createdAt)}</p>
            }
            list.push(data);
        });
        const rawData = response?.data?.data
        const total = response?.data?.totalRecord
        return { list, rawData, total }
    };

    return (
        <Collapse expandIcon={chooseIcon} defaultActiveKey='1'>
            <Collapse.Panel header="Tickets" key="1">
                <DataList headers={headersTickets} loadData={getTickets} header_normal={true} />
            </Collapse.Panel>
        </Collapse>
    )
}