import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@haravan/react-components";
import { closePopup, selectPopupContent, selectPopupStatus, selectPopupClassName } from "./popup_slice";

import "./popup.css";

export const Popup = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(selectPopupStatus);
  const class_name = useSelector(selectPopupClassName);

  const { headerText, bodyContent, acceptText, dangerAccept, onAccept, cancelText, onCancel, closeOnClickOutside, dimOutside } = useSelector(selectPopupContent);

  const handleCancel = () => {
    onCancel?.();
    dispatch(closePopup());
  };

  const handleAccept = () => {
    onAccept?.() && dispatch(closePopup());
  };

  return isOpen ? (
    <div className={`popup-outside ${dimOutside && 'dim'} ${class_name}`} onClick={() => closeOnClickOutside && dispatch(closePopup())}>
      <div className="popup-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="popup">
          <div className="popup-header">{headerText}</div>
          <div className="popup-body">{bodyContent}</div>
          {
            cancelText && acceptText &&
            <div className="popup-footer">
              {
                cancelText &&
                <Button status="default" onClick={handleCancel}>
                  {cancelText}
                </Button>
              }
              {
                acceptText &&
                <Button status={dangerAccept ? "danger" : "primary"} onClick={handleAccept}>
                  {acceptText}
                </Button>
              }
            </div>
          }
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
