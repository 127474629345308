import { Button } from '@haravan/react-components'
import { DataList, DataSearch, Detail, Email, Svg } from '../../../components'
import { PermissionRepository } from '../../../repositories'
import { useDispatch } from 'react-redux'
import { openEditorPopup } from '../../../components/popup/popup_slice'

import './index.css'
import { Permission } from './permission'

const headers = [
  { name: 'Id', className: 'user-userworking-id' },
  { name: 'Tên đăng nhập', className: 'user-username' },
  { name: 'Email', className: 'user-email' },
  { name: 'Tên', className: 'user-name' },
  { name: 'Department', className: 'department-name' },
  { name: 'Org Name', className: 'org-name' },
  { className: 'user-role-btn' },
]

export const UserList = props => {
  const dispatch = useDispatch()
  const openUserAccessPopup = (id, userName) => {
    dispatch(
      openEditorPopup({
        title: 'Phân quyền',
        acceptText: '',
        cancelText: '',
        content: <Permission id={id} userName={userName} />,
        closeOnClickOutside: false,
      })
    )
  }

  const fetchUsers = async (page, pageSize, search, filter) => {
    const response = await PermissionRepository.GetUsers(page, pageSize, search)
    const list = response?.data?.data?.map(u => ({
      id: u.id,
      userName: `${u.userName}`.trim(),
      email: <Email to={u.email} />,
      name: u.name,
      department: u.departmentName,
      orgname : u.orgName,
      Button: (
        <Button status='primary' onClick={() => openUserAccessPopup(u.id, u.userName)} className='btn-user-config'>
          <Svg className='menu-item-icon' type='UserConfig' size={20} />
        </Button>
      ),
    }))
    const rawData = response?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  }

  return (
    <div className='user-list-wrapper'>
      <div className='flex stretch mb-md'>
        <DataSearch usedBy='user-list' placeholder='Tìm kiếm theo ref, họ tên, email' />
      </div>
      <DataList usedBy='user-list' headers={headers} loadData={fetchUsers} valueField='partnerBalanceId' />
    </div>
  )
}
