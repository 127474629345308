import { Svg } from '../../../components/svg/svg';
import { Tooltip } from '@haravan/react-components';
import { useDispatch } from 'react-redux';
import { changeMenu } from '../left_menu_slice';
import { useNavigate, NavLink, Link, useLocation } from 'react-router-dom';

import './menu_item.css';

export const MenuItem = ({ icon = null, link = '', text = '', active = false, display = true, menuSub = null }) => {
    const textElement = <div className='menu-item-tooltip-text'>{text}</div>;

    return display && <NavLink to={link}>
        <div className={`menu-item-wrapper ${active ? 'menu-item-active' : ''}`} >
            <div className='menu-item-tooltip'>
                <Tooltip placement='right' title={textElement}>
                    <span><Svg className='menu-item-icon' type={icon} size={20} /></span>
                </Tooltip>
            </div>
            <div className='menu-item'>
                <Svg className='menu-item-icon' type={icon} size={20} />
                <div className='menu-item-text'>{text}</div>
            </div>
            {menuSub && menuSub.map(o => {
                return <NavLink to={o.link}>
                    <div className='menu-item-sub'>
                        <Svg className='menu-item-icon' type={icon} size={17} />
                        <div>{o.text}</div>
                    </div></NavLink>
            })}
        </div>
    </NavLink>
}
