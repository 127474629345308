
import {Tab, TabItem } from '../../../components'

import './index.css'

export const PartnerDetail = props => {

  return (
    <div className='shop-detail-wrapper'>
      <Tab>
        <TabItem path='.' name='Thông tin đối tác' />
        <TabItem path='apps' name='Ứng dụng' />
        <TabItem path='themes' name='Giao diện' />
        <TabItem path='payouts' name='Công nợ' />
      </Tab>
    </div>
  )
}
