import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { tabChange, selectActiveId } from '../tab_slice'
import { ActionConfirm } from '../..'

import './tab_item.css'

const DEFAULT_CONFIRM_HEADER_TEXT = 'Xác nhận chuyển tab'
const DEFAULT_CONFIRM_TEXT = 'Mọi dữ liệu chưa được lưu sẽ bị mất.'
const DEFAULT_CONFIRM_ACCEPT_TEXT = 'Đồng ý'
const DEFAULT_CONFIRM_CANCEL_TEXT = 'Quay lại'

interface TabItemProps {
  path?: string
  name: string
  children?: JSX.Element | JSX.Element[]
  hidden?: boolean
  tabChangeConfirmed?: boolean
  confirmHeaderText?: string
  confirmText?: string
  confirmAcceptText?: string
  confirmCancelText?: string
}

export const TabItem = (props: TabItemProps) => {
  const { path='.', name, hidden, tabChangeConfirmed, confirmHeaderText, confirmText, confirmAcceptText, confirmCancelText } = props

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const url = useLocation();
  var activeId = useSelector(selectActiveId)

  useEffect(() => {
    dispatch(tabChange({path:'.'}));
    if(url.pathname.includes("transactions")) dispatch(tabChange({path:'transactions'}));
    if(url.pathname.includes("appinstalls")) dispatch(tabChange({path:'appinstalls'}));
    if(url.pathname.includes("themeinstalls")) dispatch(tabChange({path:'themeinstalls'}));
    if(url.pathname.includes("tickets")) dispatch(tabChange({path:'tickets'}));
    if(url.pathname.includes("apps")) dispatch(tabChange({path:'apps'}));
    if(url.pathname.includes("themes")) dispatch(tabChange({path:'themes'}));
    if(url.pathname.includes("payouts")) dispatch(tabChange({path:'payouts'}));
    if(url.pathname.includes("support")) dispatch(tabChange({path:'support'})); 
  }, [url])

  const changeTab = () => {
    dispatch(tabChange({ path }));
    navigate(path)
  }

  return (
    !hidden && (
      <div className={`tab-item nav-item ${activeId} ${activeId === path ? 'tab-active' : ''}`}>
        <ActionConfirm
          isConfirm={tabChangeConfirmed}
          headerText={confirmHeaderText ?? DEFAULT_CONFIRM_HEADER_TEXT}
          bodyText={confirmText ?? DEFAULT_CONFIRM_TEXT}
          cancelText={confirmAcceptText ?? DEFAULT_CONFIRM_CANCEL_TEXT}
          acceptText={confirmCancelText ?? DEFAULT_CONFIRM_ACCEPT_TEXT}
          dangerAccept
          handleOnAccept={changeTab}
        >
          <div className='tab-name' key={path}>
            {name}
          </div>
        </ActionConfirm>
      </div>
    )
  )
}
