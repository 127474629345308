import * as HrvComponents from '@haravan/react-components';
import { StoreRepository } from '../../../../../repositories'
import { toDateTimeString } from '../../../../../global/utils/functions'
import { useParams } from 'react-router-dom'
import { Currency, Detail, DataList, DataSearch, Status } from '../../../../../components'
import { ListViewHeader } from '../../../../../components/list_view/listview_simple_paging'

import './index.css'
import { DetailThemeInstall } from '../../../../../components/link/link'

const headers = [
  { name: 'Tên theme', className: 'theme-name' },
  { name: 'Tên theme gốc', className: 'theme-name' },
  { name: 'Theme code', className: 'theme-code' },
  { name: 'Version', className: 'version-theme' },
  { name: 'Latest Version', className: 'version-theme' },
  { name: 'Ngày cập nhật', className: 'install-date' },
  { name: 'User cập nhật', className: 'install-date' },
  { name: 'Đối tác', className: 'partner' },
] as ListViewHeader[]

export const ShopThemeInstallList = () => {
  const { shopId } = useParams()

  const fetchThemeInstalls = async (page, pageSize, search) => {
    const response = await StoreRepository.GetThemesInstallByShopId(shopId, page, pageSize, search);
    const list = response?.data?.data?.map(a => ({
      name: a.theme ? <DetailThemeInstall to={`/themes/${a.theme.id}`} isLink={true} isPublish={a.isPublished}>{a.theme.name}</DetailThemeInstall>
        : <DetailThemeInstall isPublish={a.isPublished}>{a.name}</DetailThemeInstall>,
      nameOriginal: a.theme && <DetailThemeInstall to={`/themes/${a.theme.id}`} isLink={true}>{a.theme.nameOriginal}</DetailThemeInstall>,
      code: a.theme ? a.theme.themeCode : "",
      version: a.theme?.version,
      latestVersion: a.theme?.latestVersion,
      createuserTime: toDateTimeString(a.createdDate),
      updateUser: a.updatedUser > 0 ? a.updatedUser : '',
      partner: a.partner && <Detail to={`/partners/${a.partner.id}`}>{`${a.partner.lastName ?? ''} ${a.partner.firstName ?? ''}`}</Detail>,
     }));
    const rawData = response?.data?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  };

  return (
    <div className='theme-install-list'>
      <DataSearch className='mb-md' placeholder='Tìm kiếm theo tên theme' />
      <DataList headers={headers} loadData={fetchThemeInstalls} />
    </div>
  );
}