import { Svg } from '../svg'
import { Button, InputDatetimePicker, Selection } from '@haravan/react-components'
import { useState } from 'react'
import { HintTextBox } from '../hint_text_box'
import { Popover } from '../popover/popover'
import { InputDebounce } from '../input'
import { useDispatch, useSelector } from 'react-redux'
import { textSearchChange } from '../data_search'
import { changeFilter, selectFilter } from './data_filter_slice'

import './data_filter.css'
import { useEffect } from 'react';
import { useTextSearch } from '../data_search/data_search'
import { OptionProps } from '@haravan/react-components/lib/Selection/Selection'

export enum DataFilterType {
  Text,
  Select,
  Search,
  Date,
}

export interface DataFilterProps {
  usedBy?: string
  type: DataFilterType
  fieldName: string[],
  loadData?: any,
  selectionData?: OptionProps[],
  onChange?: (val) => void
}

export const DataFilter = (props: DataFilterProps) => {
  const dispatch = useDispatch()

  const { usedBy, type, fieldName, loadData, selectionData, onChange } = props
  const [showPopup, setShowPopup] = useState(false)
  const [value, setValue] = useState(null)

  const [appliedTextSearch, setAppliedTextSearch] = useState('')

  const [options, setOptions] = useState(selectionData ?? [])

  const textSearch = useTextSearch(usedBy)
  const filter = useFilter(usedBy)

  useEffect(() => {
    if (type === DataFilterType.Select) {
      loadData?.()?.then(setOptions)
    }
    dispatch(changeFilter({ usedBy, filter: {} }))
    return () => { dispatch(changeFilter({ usedBy, filter: {} })) }
  }, [])

  useEffect(() => setShowPopup(!!value && filterIsChanged()), [value])

  const filterIsChanged = () => {
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        if (filter[fieldName[i]] !== value[i]) {
          return true
        }
      }
    }
    else if (filter[fieldName[0]] !== value) return true
    return false
  }

  const changeVisible = isShow => {
    if (isShow) setShowPopup(true)
    else if (value) {
      setShowPopup(isShow)
      if (Array.isArray(value)) {
        let clonedValue = [...value]
        for (let i = 0; i < value.length; i++) {
          if (filter[fieldName[i]] !== value[i]) {
            clonedValue[i] = filter[fieldName[i]]
          }
        }
        setValue(clonedValue)
      }
      else if (filter[fieldName[0]] !== value) {
        setValue(filter[fieldName[0]])
        if (type === DataFilterType.Search) {
          dispatch(textSearchChange({ usedBy, textSearch: appliedTextSearch }))
        }
      }
    }
  }

  const changeDate = (start, end) => {
    const startDate = start.utc().toDate()
    const endDate = end.utc().toDate()
    setValue(startDate && endDate && [startDate, endDate] || null)
  }

  const apply = () => {
    let newFilter = { ...filter }
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        newFilter[fieldName[i]] = value[i]
      }
    }
    else newFilter[fieldName[0]] = value
    dispatch(changeFilter({ usedBy, filter: newFilter }))
    setAppliedTextSearch(textSearch)
    onChange?.(value)
    setShowPopup(false)
  }

  const clear = () => {
    if (type === DataFilterType.Search) dispatch(textSearchChange({ usedBy, textSearch: '' }))
    setValue(null)

    let newFilter = { ...filter }
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        delete newFilter[fieldName[i]]
      }
    }
    else delete newFilter[fieldName[0]]
    dispatch(changeFilter({ usedBy, filter: newFilter }))

    onChange?.(null)
    setShowPopup(false)
  }
  return (
    <div className={`data-filter-wrapper ${value && 'has-value'}`}>
      <Svg type='Filter' size='calc(0.6rem + 0.2vw)' onClick={() => setShowPopup(true)} />
      <Popover visible={showPopup} onVisibleChange={changeVisible} placement='bottomLeft'>
        <div className='data-filter-content'>
          {type === DataFilterType.Date ? (
            <InputDatetimePicker
              placement='left'
              singleDatePicker={false}
              placeholder='Chọn ngày'
              format='DD/MM/YYYY HH:mm'
              timePicker24Hour
              startDate={value?.[0]}
              endDate={value?.[1]}
              onChange={changeDate}
            />
          ) : type === DataFilterType.Select ? (
            //  <Svg type='SelectChevron' size={15} />
            <Selection onChange={setValue}>
              {options.map(opt => <Selection.Option {...opt}></Selection.Option>)}
            </Selection>
          ) : type === DataFilterType.Search ? (
            <HintTextBox loadData={loadData} onSelect={setValue} usedBy={usedBy} />
          ) : (
            <InputDebounce value={value} onChange={setValue} debounceTime={500} />
          )}
          <div className='mt-md flex space-between'>
            <Button status='danger' onClick={clear}>
              {/* <Svg type='FilterRemove' size='calc(0.6rem + 0.2vw)' /> */}
              Xóa
            </Button>
            <Button status='primary' onClick={apply}>
              Áp dụng
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  )
}

export function useFilter(usedBy) {
  const getFilter = useSelector(selectFilter)
  return getFilter(usedBy)
}
