import { NumeralInit, MomentInit } from '@haravan/omnicontrols';
import { ConfigLocale } from '@haravan/react-components/lib/Utils/FormatHelpers';
import * as Env from '../../global/utils/env';
import { join } from 'lodash';
export { messages_vi } from './vi';
export { messages_en } from './en';

let localMessages: { [key: string]: string };

export function setLocale(locale) {
  Env.setCookie('locale', locale);
};

export function initLocale(messages, locale) {
  localMessages = messages;
  MomentInit(locale);
  ConfigLocale(locale == 'vi'? 'vi':'en-au');
}

export function translate(key: string) {
  if (!localMessages || !key) return key;
  let message = localMessages[key] 
  if (!message) {
    message = localMessages[capitalizeFirstChar(key)]
    if (!message) {
      message = localMessages[key.toLowerCase()]
    }
    else
      message = unCapitalizeFirstChar(message)
  }
  return message ?? key;
}

export const transParagraph = (paragraph: string) => {
  const words = paragraph.split(' ')
  const transWords = words.map(w => trans(w))
  return join(transWords, ' ')
}

export const trans = translate

export function getLocale(): string {
  var locale = Env.getCookie('locale');
  if(!locale) locale = navigator.language.split(/[-_]/)[0];
  return locale;
}

const capitalizeFirstChar = (string) => (string?.charAt(0)?.toUpperCase() + string?.slice(1)) ?? '';
const unCapitalizeFirstChar = (string) => (string?.charAt(0)?.toLowerCase() + string?.slice(1)) ?? '';

export function getKey(msg, params = [], replacements = []) {
  for (let i = 0; i < params.length; i++) {
    msg = msg.replace(params[i], replacements[i])
  }
  return msg
}

export function replaceTrans(msg, params = [], replacements = []) {
  for (let i = 0; i < params.length; i++) {
    msg = msg.replace(replacements[i], params[i])
  }
  return msg
}

export function dynamicTrans(msg = '', params = [], replacements = []) {
  const key = getKey(msg, params, replacements)
  //key: "{title} must be between {min} and {max} characters long"
  //value: "{title} must be between {min} and {max} characters long"
  return replaceTrans(localMessages[key], params, replacements)
  
}
