import React from 'react';
import classNames from 'classnames';
import './page.css';

interface PageContentProps {
  fullWidth?: boolean;
  children: any;
}

interface PageLayoutProps {
  seperator?: boolean;
  children: any;
}

interface PageSectionProps {
  children?: React.ReactNode;
  secondary?: boolean;
  title?: any;
  fullWidth?: boolean;
  oneHalf?: boolean;
  oneThird?: boolean;
  className?: string;
}
export const PageContent = function PageContent(props: PageContentProps) {
  const className = props.fullWidth ? 'container-fluid' : 'container';
  return <div className={className}>{props.children}</div>;
};

export const PageLayout = function PageLayout(props: PageLayoutProps) {
  const className = classNames(
    props.seperator && 'seperator'
  )
  return <div className={className}><div className='no-gutters page-content'>{props.children}</div></div>;
};

PageLayout.AnotatedSection = function AnotatedSection(props: {
  title: string;
  description?: string;
  sectionClassName?: string;
  children?: React.ReactNode;
  isRequire? : boolean
}) {
  return (
    <div className="page-section-wrapper">
      <PageSection secondary className="section-title text-break">
          <div className="anotated-header">{props.title}{props.isRequire? <div className="require">*</div> :""}</div>
          {props.description && <div className="anotated-description">{props.description}</div>}
      </PageSection>
      <PageSection className="section-content">{props.children}</PageSection>
    </div>
  );
};

export const PageSection = function PageSection({
  children,
  className
}: PageSectionProps) {
  return <div className={`page-section ${className}`}>{children}</div>;
};
