import * as React from 'react';
import { ISVGEmpty } from './icon_svg';
import './index.css';

interface IError {
    title: string,
    content: string,
    hasBtnRedirectHome?: boolean
}

export class Error extends React.Component<IError, {}> {
    static defaultProps = {
        hasBtnRedirectHome: true
    }

    public render() {
        return <div className='ui-layout-error'>
            <div className='container'>
                <div className='ui-layout-error--box'>
                    <div className='ui-layout-error--icon'>{ISVGEmpty()}</div>
                    <div className='ui-layout-error--content'>
                        {this.props.title && <div className='ui-layout-error--title'>{this.props.title}</div>}
                        {this.props.content && <div className='ui-layout-error--description'>{this.props.content}</div>}
                    </div>
                    {this.props.hasBtnRedirectHome &&
                        <div className='ui-layout-error--action'>
                            <a href='/'>Về trang chính</a>
                        </div>}
                </div>
            </div>
        </div>
    }
}
