export const downloadLink = (url, fileName='file_export.csv') => 
  {
    var a = document.createElement("a");
    a.href = url;
    // var fileName = url.split("/").pop();
    // alert(fileName);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    // window.URL.revokeObjectURL(url);
    a.remove();
  }