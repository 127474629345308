import { Notification } from "@haravan/react-components";
import { T } from '../components'
import { signinRedirect } from "../screens/auth/services/auth_service";

var authHeader = ''

export function setBearerToken(token) {
  authHeader = token ? 'Bearer ' + token : ''
}

export enum ApiType {
  partner_api = "partner_api",
}

export const parseObjectToParam = (obj) => {
  let path = '';
  Object.keys(obj).forEach(key => {
    if (obj[key]) path += `&${key}=${encodeURIComponent(obj[key])}`;
  });
  path = path.substring(1);
  return path;
}

export const GetApiUrl = (path, value): string => {
  if (value) {
    var param = parseObjectToParam(value)
    if (param) path += `?${param}`
  }
  return path
}

const getCanvasFingerprint = () => {
  var canvas = document.createElement("canvas");
  canvas.width = 100;
  canvas.height = 40;
  canvas.style.zIndex = "-10";
  canvas.style.position = "absolute";
  canvas.style.display = "none";

  var ctx = canvas.getContext("2d");

  ctx.fillStyle = "rgb(255,0,255)";
  ctx.beginPath();
  ctx.rect(20, 20, 150, 100);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  ctx.beginPath();
  ctx.fillStyle = "rgb(0,255,255)";
  ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();

  const txt = "praveen1180#$%^@£éú";
  ctx.textBaseline = "top";
  ctx.font = '17px "Arial 17"';
  ctx.textBaseline = "alphabetic";
  ctx.fillStyle = "rgb(255,5,5)";
  ctx.rotate(.03);
  ctx.fillText(txt, 4, 17);
  ctx.fillStyle = "rgb(155,255,5)";
  ctx.shadowBlur = 8;
  ctx.shadowColor = "red";
  ctx.fillRect(20, 12, 100, 5);

  // hashing function
  const src = canvas.toDataURL();
  let hash = 0;

  for (let i = 0; i < src.length; i++) {
    const char = src.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }

  return hash.toString();
}

export const api_call = async (method, path, body = null, origin = process.env.REACT_APP_BASE_API_URL) => {
  let url = `${origin}/${path}`;

  try {
    let rp = await fetch(url, {
      method,
      body: body ? JSON.stringify(body) : null,
      // credentials: 'include', //.AllowCredentials() in .net API CORS to allow this
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authHeader,
        'x-shopid': document['user_id'],
        'x-clientid': getCanvasFingerprint()
      }
    });
    if (rp.status === 401) {
      // store.dispatch(openErrorPopup({ error_code: rp.status }));
      // signinRedirect();
    } else if (rp.status === 403) {
      // if (env.layout)
      //   env.layout.redirectPagePermission()
      console.error(rp);
    }
    else if (rp.status === 200 || rp.status === 201 || rp.status === 304) {
      const rs = await rp.json();
      const { has_error, error_code, error_message } = rs;
      if (has_error) {
        Notification.error({ message: T(error_code), duration: 1 + (error_message?.length / 10 ?? 0) });
        // Notification.error({ message: 'Đã xảy ra lỗi', description: error_message || error_code, duration: 1 + (error_message?.length/10 ?? 0) });
      }
      return rs;
    } else {
      // if (errorSystem && env.layout) {
      //   env.layout.ShowMessage('error', errorSystem)
      // }
    }
  }
  catch (e) {
    Notification.error({ message: 'Đã xảy ra lỗi', description: "", duration: 2 });
    throw e;
  }
}
export const api_call_get_partner = (apiId: string, path: string): Promise<any> => {
  return api_call('GET', path, null, apiId);
}

export const api_call_get = (path: string) => api_call('GET', path);

export const api_call_post = (path: string, body?: any) => api_call('POST', path, body);

export const api_upload_file = async (blob: Blob, fileName: string, path) => {
  let formData = new FormData();
  formData.append('file', blob);
  let url = `${process.env.REACT_APP_BASE_API_URL}/${path}`;
  let rp = await fetch(url, {
    method:'POST',
    body: formData,
    headers: {
      'Authorization': authHeader,
      'x-shopid': document['user_id'],
      'x-clientid': getCanvasFingerprint()
    }
  });
  return rp;
}

export const api_call_put = (path: string, body?: any) => api_call('PUT', path, body);

export const api_call_delete = (path: string) => api_call('DELETE', path);

export const api_upload_file_multi = async (blob: any[], productId: number): Promise<any> => {
  let url = `${origin}call/${ApiType.partner_api}/products/${productId}/images`
  let input = new FormData();
  for (var i = 0; i != blob.length; i++) {
    input.append("files", blob[i]);
  }
  try {
    let rp = await fetch(url, {
      method: 'POST',
      body: input,
      credentials: 'include'
    });
  }
  finally { }
}


