
import * as HrvComponents from '@haravan/react-components';
import { useParams } from 'react-router-dom'

import './index.css';
import { useEffect, useState } from 'react';
import { ShopplanRepository } from '../../../repositories/shopplan_repository';
import { Button, Loading } from '@haravan/react-components';
import { CardShopPlan } from '../../../components/card_detail/card_detail';
import { Notification } from '@haravan/react-components';
import { Modal } from 'react-bootstrap';
import { isEqual } from 'lodash';
import { PageSection } from '../../../components/page/page_layout';
import { Currency, Status, PlansListComponent } from '../../../components';
import { DetailShopplan } from '../../../components/link/link';
import { FormatCategory, FormatKeyProduct, FormatValueProduct, PlanCode } from '../../../components/format/format';

export const ProductDetail = () => {
  const { Id, Clone_Id } = useParams()
  const [product, setProduct] = useState(null);
  const [productClone, setProductClone] = useState(null);
  const [isLoadingData, setLoadingData] = useState(false);
  const [modalAddMeta, setModalAddMeta] = useState(false);
  const [itemAdd, setItemAdd] = useState(null);
  const [modalDeleteMeta, setModalDeleteMeta] = useState(false);
  const [modalEditMeta, setModalEditMeta] = useState(false);
  const [itemEdit, setItemEdit] = useState(null);
  const [indexEdit, setIndexEdit] = useState(null);
  const [indexDetele, setIndexDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [metaDTCheck, setMetaDTCheck] = useState(null);


  useEffect(() => {
    let product_id = Id ?? Clone_Id
    product_id && ShopplanRepository.GetProduct(product_id).then(rs => {
      if (!rs.has_error) {
        setProduct(rs?.data);
        setProductClone(rs?.data);
        let dataMeta = []
        if (rs.data?.metaDatas.length !== 0) {
          rs.data?.metaDatas.map(o => {
            dataMeta.push({ ...o })
          })
        }
        setMetaDTCheck(dataMeta);
      } else window.location.replace('products');
    })
    !Id && setProduct({ ...product, metaDatas: [] })
  }, [Id, Clone_Id])

  useEffect(() => {
    if (product != null) {
      if (!isEqual(metaDTCheck, product?.metaDatas)) setChange(!isEqual(metaDTCheck, product?.metaDatas));
      else setChange(!isEqual(product, productClone));
    }
  }, [product])

  const saveData = () => {
    if (product != null) {
      setLoading(true)
      if (Clone_Id) product.id = 0;
      ShopplanRepository.SetProducts(product).then(rs => {
        if (rs) {
          if (!rs.has_error) {
            Notification.success({ message: 'Cập nhật thành công' });
            const newShopPlan = 'products/' + rs?.data?.id;
            window.location.replace(newShopPlan);
          } else {
            setLoading(false);
          }
        }
        else { // rs : undefined
          Notification.error({ message: "Cập nhật thất bại" });
          setLoading(false);
        }

      })
    }
  }

  const addMetaData = () => {
    setModalAddMeta(true);
  }

  const exitModalAdd = () => {
    setModalAddMeta(false);
    setItemAdd(null);
  }

  const editMetaData = (it, index) => {
    setModalEditMeta(true);
    setItemEdit(it);
    setIndexEdit(index);
  }

  const removeMetaDatas = (index) => {
    setModalDeleteMeta(true);
    setIndexDelete(index);
  }

  const editMetaDatas = () => {
    if (!itemEdit?.key || itemEdit?.key?.trim() === '') {
      return HrvComponents.Notification.error({
        message: "Key không được để trống"
      });
    }
    if (!itemEdit?.value || itemEdit?.value?.trim() === '') {
      return HrvComponents.Notification.error({
        message: "Value không được để trống"
      });
    }
    let item = product;
    let keyDup = 0;
    item.metaDatas.map((o, i) => {
      if (o.key.trim() == itemEdit.key.trim() && i != indexEdit) keyDup++;
    })
    if (keyDup > 0) return HrvComponents.Notification.error({
      message: "Key bị trùng"
    });
    setModalEditMeta(false);
    item.metaDatas[indexEdit].key = itemEdit.key;
    item.metaDatas[indexEdit].value = itemEdit.value;
    setProduct({ ...item });
  }

  const changeKeyMetaData = (value) => {
    setItemEdit({ ...itemEdit, key: value })
  }

  const deleteMetaDatas = (index) => {
    setModalDeleteMeta(false);
    let item = product;
    item.metaDatas.splice(index, 1);
    setProduct({ ...item });
  }

  const changeValueMetaData = (value) => {
    setItemEdit({ ...itemEdit, value: value })
  }

  const addMetaDatas = () => {
    let item = { ...product };
    if (!itemAdd?.key || itemAdd?.key?.trim() === '') {
      return HrvComponents.Notification.error({
        message: "Key không được để trống"
      });
    }
    if (!itemAdd?.value || itemAdd?.value?.trim() === '') {
      return HrvComponents.Notification.error({
        message: "Value không được để trống"
      });
    }
    let bug = false;
    item.metaDatas.map((o) => {
      if (o.key.trim() == itemAdd.key.trim()) bug = true;
    })
    if (bug) return HrvComponents.Notification.error({
      message: "Key bị trùng"
    });
    setModalAddMeta(false);
    item.metaDatas.push({ key: itemAdd?.key, value: itemAdd?.value });
    setProduct({ ...item });
    setItemAdd(null);
  }

  const renderShopPlan = () => {
    return (
      product?.shopPlans?.length > 0 ? <div className='listview-content'>
        <HrvComponents.Table
          renderTableHeader={
            <tr>
              <th><b>Name</b></th>
              <th><b>Code</b></th>
              <th><b>Cost</b></th>
              <th><b>Ref Cost</b></th>
              <th className='status-shopplan'><b>Status</b></th>
            </tr>
          }
          renderTableBody={
            product?.shopPlans?.map((item, index) => {
              let shopplanPath = `/shopplans/${item?.id}`;
              return <tr className='tb-list-meta'>
                <td className='item-meta' >  <a href={shopplanPath} target="_blank"><DetailShopplan>{FormatCategory(item.name)}</DetailShopplan></a></td>
                <td className='item-meta' >{PlanCode(item.productCode)}</td>
                <td className="item-meta"><Currency ammount={item.cost} /></td>
                <td className="item-meta"><Currency ammount={item.refCost} /></td>
                <td className="status-shopplan">{item.isActived ? <Status text="active" /> : <Status text="inactive" />}</td>
              </tr>
            })
          } />
      </div> : renderEmptyShopPlan()
    )
  }

  const renderMetaDatas = () => {
    return (
      product?.metaDatas?.length > 0 ? <div className='list-metadatas'>
        <HrvComponents.Table
          renderTableHeader={
            <tr>
              <th className='key-meta'><b>Key</b></th>
              <th className='value-meta'><b>Value</b></th>
              <th></th>
              <th></th>
            </tr>
          }
          renderTableBody={
            product?.metaDatas?.map((item, index) => {
              return <tr className='tb-list-meta'>
                <td className='key-meta' >{FormatKeyProduct(item.key)}</td>
                <td className='value-meta' >{item.value}</td>
                <td className="list-btn">
                  <div className='btn-edit'>
                    <HrvComponents.Tooltip title='Sửa'>
                      <HrvComponents.Button onClick={() => editMetaData(item, index)} status='link'><HrvComponents.Icon type='pencil' theme='solid' /></HrvComponents.Button>
                    </HrvComponents.Tooltip>
                  </div>
                  <div className='btn-delete'>
                    <HrvComponents.Tooltip title='Xóa'>
                      <HrvComponents.Button onClick={() => removeMetaDatas(index)} status='link'><HrvComponents.Icon type='trash' theme='solid' /></HrvComponents.Button>
                    </HrvComponents.Tooltip>
                  </div>
                </td>
                <td></td>
              </tr>
            })
          } />
        {renderEditMetaDatas()}
        {renderDeleleMetaDatas()}
      </div> : renderEmpty()
    )
  }
  const renderLable = () => {
    if (Clone_Id)
      return `Clone Product ${product?.name ?? ''}`
    if (Id == undefined)
      return 'New Product'
    return `Product ${product?.name ?? ''}`
  }

  const renderBody = () => {
    return (
      <div className="product-screen">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">{renderLable()}</h3>
          </div>
          {renderProduct()}
        </div>
        {Id && <div className="panel panel-default">
          <div className="panel-heading-list-shopplan">
            <h3 className="panel-title">Shop Plan</h3>
            <div className="btn-add-metadata">
              <HrvComponents.Tooltip title='Thêm'>
                <HrvComponents.Button onClick={() => { window.location.href = `products/${Id}/shopplans/new` }} size='small' status='primary'><HrvComponents.Icon type='plusCircle' theme='solid' /></HrvComponents.Button>
              </HrvComponents.Tooltip>
            </div>
          </div>
          <div className='body-shopplan'>
            <PlansListComponent pageSize={10} productId={Id} />
          </div>
        </div>}
        <div className="panel panel-default">
          <div className="panel-heading-shopplan">
            <h3 className="panel-title-shopplan">Meta Data</h3>
            <div className="btn-add-metadata">
              <HrvComponents.Tooltip title='Thêm'>
                <HrvComponents.Button onClick={() => addMetaData()} size='small' status='primary'><HrvComponents.Icon type='plusCircle' theme='solid' /></HrvComponents.Button>
              </HrvComponents.Tooltip>
            </div>
          </div>
          <div className='body-shopplan'>
            {renderMetaDatas()}
          </div>
          {renderAddMetaDatas()}
        </div>
      </div>
    )
  }

  const renderAddMetaDatas = () => {
    return (
      <Modal show={modalAddMeta} onHide={() => exitModalAdd()}>
        <Modal.Header closeButton>
          <Modal.Title>Thêm Meta Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label >Key</label>
            <HrvComponents.Input value={itemAdd?.key ?? ''} onChange={key => setItemAdd({ ...itemAdd, key })} className='input-name-metadata' />
            <label >Value</label>
            <HrvComponents.Input value={itemAdd?.value ?? ''} onChange={value => setItemAdd({ ...itemAdd, value })} className='input-name-metadata' />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <HrvComponents.Button status='danger' onClick={() => exitModalAdd()}>
            Hủy
          </HrvComponents.Button>
          <HrvComponents.Button status="primary" onClick={() => addMetaDatas()}>
            Xác nhận
          </HrvComponents.Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const renderEditMetaDatas = () => {
    return (
      <Modal show={modalEditMeta} onHide={() => setModalEditMeta(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Sửa Meta Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label >Key</label>
            <HrvComponents.Input value={itemEdit?.key ?? ''} onChange={key => changeKeyMetaData(key)} className='input-name-metadata' />
            <label >Value</label>
            <HrvComponents.Textarea value={itemEdit?.value ?? ''} onChange={value => changeValueMetaData(value)} className='input-name-metadata' />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <HrvComponents.Button status='danger' onClick={() => setModalEditMeta(false)}>
            Hủy
          </HrvComponents.Button>
          <HrvComponents.Button status="primary" onClick={() => editMetaDatas()}>
            Xác nhận
          </HrvComponents.Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const renderDeleleMetaDatas = () => {
    return (
      <Modal show={modalDeleteMeta} onHide={() => setModalDeleteMeta(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Xóa Meta Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc muốn xoá Meta Data này?</Modal.Body>
        <Modal.Footer>
          <HrvComponents.Button status='danger' onClick={() => setModalDeleteMeta(false)}>
            Hủy
          </HrvComponents.Button>
          <HrvComponents.Button status="primary" onClick={() => deleteMetaDatas(indexDetele)}>
            Xác nhận
          </HrvComponents.Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const renderProduct = () => {
    return (
      <div className='render-product'>
        <div className='col-md-6'>
          <CardShopPlan name="Name" value={<HrvComponents.Input value={product?.name ?? ''} onChange={value => setProduct({ ...product, name: value })} className='input-name-shopplan' />}></CardShopPlan>
          <CardShopPlan name="Code" value={<HrvComponents.Input readOnly={Id != null ? true : false} value={product?.code ?? ''} onChange={value => setProduct({ ...product, code: value })} className='input-name-shopplan' />}></CardShopPlan>
        </div>
        <div className='col-md-6'>
          <CardShopPlan name="Description" value={<HrvComponents.Input value={product?.description ?? ''} onChange={value => setProduct({ ...product, description: value })} className='input-name-shopplan' />}></CardShopPlan>
          <CardShopPlan name="Actived" value={<HrvComponents.Switch checked={product?.isActived} onChange={value => setProduct({ ...product, isActived: value })} className='checkbox-shopplan' />}></CardShopPlan>
        </div>
      </div>
    )
  }

  const renderEmpty = () => {
    return (
      <PageSection>
        <div className="p-5 text-center">
          <h6>No data yet</h6>
        </div>
      </PageSection>
    );
  };
  const renderEmptyShopPlan = () => {
    return (
      <PageSection>
        <div className="p-5 text-center">
          <h6>Hiện tại chưa có Shop Plan nào</h6>
        </div>
      </PageSection>
    );
  };

  return isLoadingData ? (
    <Loading className='hrv-loading-page' size='thumb' />
  ) : (
    <div className="product-detail">
      {renderBody()}
      <div className={`data-save-wrapper has-changed`}>
        <div className='hrv-btn-group--item-1'></div>
        <div className='hrv-btn-group--item'>
          <Button onClick={saveData} status='primary' loading={loading}>Lưu</Button>
          {Clone_Id == undefined && Id && <Button onClick={() => { window.location.href = `products/clone/${Id}` }} status='success'>Nhân bản</Button>}
        </div>
      </div>
    </div>
  )

};
