import * as React from 'react';
import ReactDOM from 'react-dom';
import *  as HrvComponents from '@haravan/react-components';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import {CKEditor} from 'ckeditor4-react';

interface IPropsContentEditorInside {
    onChangeValueFromEditor?: Function
    value?: any,
    isLoading?: boolean,
}

interface IStatesContetEditorInside {
    isOpen: boolean,
    readOnly: boolean,
    isLoading: boolean,
    isUploading?: boolean,
    isLockButton: boolean,
    listImagesUpload: [] | any[],
    selectedImages: any[],
    alt: string,
    imageQueryUpload: string,
    imageQueryProduct: string,
    imageUrl: string,
    value: string,
    navTab: string,
    currentPageModalImage: number,
    modalImageAddHasNext: boolean,
    modalImageAddHasPrev: boolean,
    nameEditor: string,
    isReadyEditor: boolean,
    isTabImagesOfProduct: boolean,
    listImagesOfProduct: any[],
}

export class ContentEditorInside extends React.Component<IPropsContentEditorInside, IStatesContetEditorInside>{
    mounted = false;
    ref_contentEditor: HrvComponents.ContentEditor;
    ref_ImageUrl: HrvComponents.InputHasIcon;
    constructor(props: any) {
        super(props)
        this.state = {
            isOpen: false,
            readOnly: true,
            isLoading: false,
            isUploading: false,
            isLockButton: false,
            modalImageAddHasNext: false,
            modalImageAddHasPrev: false,
            currentPageModalImage: 1,
            listImagesUpload: [],
            listImagesOfProduct: [],
            isTabImagesOfProduct: false,
            selectedImages: [],
            navTab: 'upload-image',
            value: this.props.value,
            imageQueryUpload: '',
            imageQueryProduct: '',
            alt: '',
            imageUrl: '',
            nameEditor: 'editor1',
            isReadyEditor: false,
        }
        this.onEditorChange = this.onEditorChange.bind(this);
    }

    static contextTypes = {
        ShowMessage: PropTypes.func,
        SetBreadcrumb: PropTypes.func
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.value != nextProps.value)
            this.setState({ value: nextProps.value || '' })
    }

    componentDidMount() {
        this.mounted = true
        if (this.ref_contentEditor?.ref_CKEditor?.editorInstance) {
            this.setState({
                nameEditor: this.ref_contentEditor.ref_CKEditor.editorInstance.name
            })
        }
        if (this.props.value != this.state.value) this.setState({ value: this.props.value });
    }

    componentWillUnmount() {
        this.mounted = false
        const { nameEditor } = this.state
        try {
            let CKEDITOR = window['CKEDITOR'];
            if (CKEDITOR) {
                const config = CKEDITOR["config"]
                if (config) CKEDITOR.config["extraPlugins"] = '';
                if (CKEDITOR.plugins && CKEDITOR.plugins.registered && CKEDITOR.plugins.registered.ImageList)
                    delete CKEDITOR.plugins.registered.ImageList;
                let htmlContainer = document.getElementsByTagName("html") as any,
                    bodyContainer = document.getElementsByTagName("body") as any;
                if (htmlContainer && bodyContainer) {
                    let htmlStyle = htmlContainer[0] ? htmlContainer[0].getAttribute("style") : null,
                        bodyStyle = bodyContainer[0] ? bodyContainer[0].getAttribute("style") : null;
                    if (htmlStyle == "position: fixed; z-index: 9995; width: 0px; height: 0px;" && bodyStyle == "position: static; z-index: 9995; width: 0px; height: 0px;") {
                        if (CKEDITOR.instances[nameEditor])
                            CKEDITOR.instances[nameEditor].execCommand("maximize");
                    }
                }
            }
        }
        catch { }
    }

  
    
    private Inited = () => {
        let CKEDITOR = window['CKEDITOR'];
        if (CKEDITOR.config.extraPlugins.indexOf('ImageList') !== -1) return;
        let self = this;
        CKEDITOR.config.extraPlugins = 'ImageList';
        CKEDITOR.plugins.add('ImageList', {
            init: function (editor) {
                editor.ui.addButton('BtnImageList', {
                    label: 'Insert Images',
                    icon: 'https://hstatic.net/0/0/global/ckeditor4/skins/moono-lisa/icons_hidpi.png',
                    click: (e) => {
                        self.setState({ isOpen: true, nameEditor: e.name })
                    }
                });
            }
        });
    }


    private insertHTML = () => {
        const CKEDITOR = window['CKEDITOR']
        if (CKEDITOR) {
            const { imageUrl, nameEditor, alt } = this.state
            const image = CKEDITOR.dom.element.createFromHtml(`<p><img src='${imageUrl}' alt='${alt}'/></p>`);
            CKEDITOR.instances[nameEditor] && CKEDITOR.instances[nameEditor].insertElement(image);
        }
    }

    private onEditorChange = (event) => {
        let { onChangeValueFromEditor } = this.props
        let value = event.editor.getData()
        this.setState({ value }, () => onChangeValueFromEditor && onChangeValueFromEditor(value));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.value == nextState.value;
    }

    public render() {
        const { value, readOnly} = this.state
        return <article className='omni-content-editor main-page'>
            <CKEditor
                editorUrl = "https://hstatic.net/0/0/global/ckeditor4.15.1/ckeditor.js"
                readOnly={readOnly}
                initData={value}
                onChange={(event) => this.onEditorChange(event)}
                onBeforeLoad={this.Inited}
                config={{
                    toolbar: [
                        ['Undo', 'Redo'],
                        ['Format', 'FontSize'],
                        ['Bold', 'Italic', 'Underline', 'Strike', "Subscript", "Superscript", 'RemoveFormat'],
                        ['NumberedList', 'BulletedList'],
                        ['TextColor', 'BGColor'],
                        ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                        ['Link', 'Unlink'],
                        ['Outdent', 'Indent'],
                        ['HorizontalRule', 'Blockquote'],
                        ['BtnImageList', 'Embed', 'Table', "SpecialChar"],
                        // ['Maximize'],
                        ['Sourcedialog']
                    ],
                    extraAllowedContent: 'a[*];iframe[*];div[*];table[*];img[alt,!src,width,height];*{*};*(*);h1[id];h2[id];h3[id];h4[id];h5[id];h6[id]',
                    startupFocus: false,
                    toolbarLocation: 'top',
                    language: 'en',
                    plugins: 'quicktable,dragresize,specialchar,table,tabletools,tableselection,wysiwygarea,toolbar,undo,format,font,basicstyles,removeformat,colorbutton,justify,link,list,indentlist,indent,horizontalrule,blockquote,colordialog,tableresize,maximize,sourcedialog,mentions,autogrow,clipboard,pastefromword,embed',
                    autoGrow_minHeight: 200,
                    autoGrow_maxHeight: 500,
                    autoGrow_onStartup: true,
                    removeDialogTabs: 'link:advanced',
                    embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
                    height: 400,
                    qtColumns: 10,
                    qtRows: 10,
                    qtBorder: '1',
                    qtWidth: '100%',
                    qtStyle: { 'border-collapse': 'collapse' },
                    qtClass: '',
                    qtCellPadding: '0',
                    qtCellSpacing: '0',
                    qtPreviewBorder: '1px solid black',
                    qtPreviewSize: '20px',
                    qtPreviewBackground: '#c8def4',
                    contentsCss: 'https://static.hara.vn/200000004934/200000026276/443abdb75320452999edec1a0f4d6f04.css'
                }}
            />
        </article>
    }
}
