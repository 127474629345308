import React, { CSSProperties, useEffect, useState } from 'react'
import { Icon, InputHasIcon, Loading, Selection } from '@haravan/react-components'
import { Svg } from '../../components/svg/svg'
import { debounce } from 'lodash'
import { renderNotFoundImage } from './images'

import './listview_simple_paging.css'

export interface ListViewPagingInfo {
  searchText?: string
  before?: number
  after?: number
  limit?: number
}

export interface ListViewHeader {
  fieldName?: string
  name: string
  style?: CSSProperties
  className?: string
}

export interface ListViewProps {
  className?: string
  headers?: ListViewHeader[]
  searchPlaceHolder?: string
  allowSearch?: boolean
  idField?: string
  getData?: Function
  data?: any
  pageSize?: number
  renderHeader?: Function
  renderRow?: Function
  renderFooter?: Function
  renderEmpty?: Function
  triggerReload?: number
}

type FetchType = 'none' | 'loading' | 'complete' | 'prev' | 'next' | 'paging' | 'search'

export const ListViewSimplePaging = (props: ListViewProps) => {
  const { className, data, allowSearch, searchPlaceHolder, headers, renderHeader, idField, getData, pageSize, renderRow, renderEmpty, renderFooter, triggerReload } = props

  const [dataInfo, setDataInfo] = useState({
    data: [],
    paging: {
      searchText: '',
      before: 0,
      after: 0,
      limit: pageSize,
    } as ListViewPagingInfo,
    hasBefore: false,
    hasAfter: false,
    fetchType: 'none' as FetchType,
    dataHeight: 0,
    isCustomPaging: false,
    triggerReload: triggerReload,
  })

  useEffect(() => {
    if (!['loading', 'complete'].includes(dataInfo.fetchType)) {
      setDataInfo({ ...dataInfo, fetchType: 'loading' })
      getData?.(dataInfo.paging)?.then?.(rs => {
        const rsData = rs?.data
        if (!rsData) return
        const newData = rsData?.data as any[]
        newData &&
          setDataInfo({
            ...dataInfo,
            data: [...newData],
            paging: {
              ...dataInfo.paging,
              before: 0,
              after: 0,
            },
            hasBefore: rsData?.hasBefore ?? false,
            hasAfter: rsData?.hasAfter ?? false,
            fetchType: 'complete',
            dataHeight: 0,
          })
      })
    }
  }, [dataInfo.fetchType])

  useEffect(() => {
    if (data) {
      setDataInfo({
        ...dataInfo,
        data: [...data],
        paging: {
          ...dataInfo.paging,
          before: 0,
          after: 0,
        },
        hasBefore: data?.hasBefore ?? false,
        hasAfter: data?.hasAfter ?? false,
        fetchType: 'complete',
        dataHeight: 0,
        triggerReload,
      })
    }
  }, [data])

  useEffect(() => {
    if (dataInfo.fetchType === 'complete' && dataInfo.triggerReload !== triggerReload) {
      getData?.(dataInfo.paging)?.then?.(rs => {
        const rsData = rs?.data
        if (!rsData) return
        const newData = rsData?.data as any[]
        newData &&
          setDataInfo({
            ...dataInfo,
            data: [...newData],
            paging: {
              ...dataInfo.paging,
              before: 0,
              after: 0,
            },
            hasBefore: rsData?.hasBefore ?? false,
            hasAfter: rsData?.hasAfter ?? false,
            fetchType: 'complete',
            dataHeight: 0,
            triggerReload,
          })
      })
    }
  }, [props.triggerReload])

  const prepareFetch = (type: FetchType) => {
    const { data, paging, dataHeight } = dataInfo
    const isPrev = type === 'prev' && dataInfo.hasBefore
    const isNext = type === 'next' && dataInfo.hasAfter

    const listDataDiv = document.getElementById('listview-data')
    const newDataHeight = dataHeight > 0 ? dataHeight : listDataDiv.clientHeight

    data &&
      paging &&
      setDataInfo({
        ...dataInfo,
        paging: {
          ...paging,
          before: isPrev ? data[0]?.[idField ?? 'id'] : 0,
          after: isNext ? data[data.length - 1]?.[idField ?? 'id'] : 0,
        },
        fetchType: type,
        hasBefore: false,
        hasAfter: false,
        dataHeight: newDataHeight,
      })
  }

  const changePaging = (val: number) =>
    setDataInfo({
      ...dataInfo,
      fetchType: 'paging',
      paging: {
        ...dataInfo.paging,
        limit: val,
      },
    })

  const selectPaging = (val: number) =>
    setDataInfo({
      ...dataInfo,
      fetchType: val ? 'paging' : dataInfo.fetchType,
      paging: {
        ...dataInfo.paging,
        limit: val || dataInfo.paging.limit,
      },
      isCustomPaging: val === 0,
    })

  const search = value => {
    setDataInfo({
      ...dataInfo,
      fetchType: 'search',
      paging: {
        ...dataInfo.paging,
        searchText: value,
      },
    })
  }

  const dataStyle = {
    height: dataInfo.dataHeight ? `${dataInfo.dataHeight}px` : null,
  } as CSSProperties

  return (
    <div className={className}>
      <div className='listview'>
        <div className='listview-content'>
          {allowSearch && (
            <div className='listview-searchbox'>
              <div className='no-gutters ui-stack-item ui-stack-item-fill'>
                <div className='col'>
                  <InputHasIcon
                    placeholder={searchPlaceHolder}
                    value={dataInfo.paging.searchText}
                    prefix={<Icon type='search' />}
                    onChange={debounce(search, 750)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className='listview-header'>
            {headers?.length > 0 &&
              headers.map((h, i) =>
                renderHeader ? (
                  renderHeader(h, i)
                ) : (
                  <div key={`header-${i}`} className={`${HEADER_CELL_CLASSNAME} ${h.className}`} style={h.style ?? {}}>
                    {h.name}
                  </div>
                )
              )}
          </div>
          <div id='listview-data' className='listview-data' style={dataStyle}>
            {dataInfo.fetchType !== 'complete' ? (
              <Loading />
            ) : dataInfo.data?.length > 0 ? (
              dataInfo.data.map((obj, i) => (
                <div key={`row-${i}`} className={ROW_CLASSNAME}>
                  {renderRow
                    ? renderRow(obj, i)
                    : Object.entries(obj).map(e =>
                      headers?.map((h) =>
                        e[0] === h.fieldName && (
                          <div key={`item-${e[0]}`} className={CELL_CLASSNAME} style={h.style ?? {}}>
                            {e[1]}
                          </div>
                        )
                      )
                    )}
                </div>
              ))
            ) : (
              <div className='listview-empty pd20'>{renderEmpty ? renderEmpty() : renderFilterEmpty()}</div>
            )}
          </div>
          <div className='listview-footer'>
            {renderFooter ? (
              renderFooter()
            ) : (
              <div className='footer-wrapper'>
                <div className='footer-left'>
                  {!data && (<Selection value={dataInfo.paging.limit} onChange={val => selectPaging(parseInt(val.toString()))}>
                    <option value={SMALL_PAGE_SIZE}>{SMALL_PAGE_SIZE}</option>
                    <option value={MEDIUM_PAGE_SIZE}>{MEDIUM_PAGE_SIZE}</option>
                    <option value={LARGE_PAGE_SIZE}>{LARGE_PAGE_SIZE}</option>
                  </Selection>)}

                  <div>
                    <button disabled={!dataInfo.hasBefore} onClick={() => prepareFetch('prev')} className='hrv-btn hrv-btn-default button-small border-ellipse'>
                      <Svg size='calc(1.5rem + 0.5vw)' type='caretCircleLeft' />
                    </button>
                    <button disabled={!dataInfo.hasAfter} onClick={() => prepareFetch('next')} className='hrv-btn hrv-btn-default button-small border-ellipse'>
                      <Svg size='calc(1.5rem + 0.5vw)' type='caretCircleRight' />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const HEADER_CELL_CLASSNAME = 'listview-header-cell'
export const ROW_CLASSNAME = 'listview-row'
export const CELL_CLASSNAME = 'listview-cell'

export const TINY_PAGE_SIZE = 2
export const SMALL_PAGE_SIZE = 5
export const MEDIUM_PAGE_SIZE = 10
export const LARGE_PAGE_SIZE = 20
export const HUGE_PAGE_SIZE = 50

export const ListViewPageSize = {
  SMALL_PAGE_SIZE,
  MEDIUM_PAGE_SIZE,
  LARGE_PAGE_SIZE,
}

const renderFilterEmpty = () => (
  <div className='ui-section-item'>
    <div className='ui-section-empty pd20'>
      <div className='flex center ui-section-empty-icon'>{renderNotFoundImage()}</div>
      <div className='text-center'>
        <div className='ui-section-empty-title'>Chưa có dữ liệu.</div>
        <div className='ui-section-empty-description'>Thử thay đổi bộ lọc hoặc cụm từ tìm kiếm.</div>
      </div>
    </div>
  </div>
)
