import React from "react"

import './card_info.css'

function CardInfoJsx({ className = '', children }) {
  return <div className={`card-info ${className}`}>
    {children}
  </div>
}

export const CardInfo = React.memo(CardInfoJsx)