import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Tab, TabItem } from '../../../components'

export const RoleList = () => {
  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState('')
  useEffect(() => {
    let paths = pathname.split('/')
    let endPath = paths[paths.length - 1]
    let nearEndPath = paths[paths.length - 2]
    setActiveTab(Number.isInteger(endPath) ? nearEndPath : endPath)
  }, [pathname])
  return (
    <div className='shop-detail-wrapper'>
      <Tab>
        <TabItem path='.' name='Nhóm quyền Inside' />
        <TabItem path='support' name='Nhóm quyền hỗ trợ cửa hàng' />
      </Tab>
    </div>
  )
}
