import { useState, useEffect } from 'react'
import { debounce } from 'lodash'
import { Icon, InputHasIcon } from '@haravan/react-components'
import { useSelector, useDispatch } from 'react-redux'
import { selectText, textSearchChange } from './data_search_slice'

import './data_search.css'

export const DataSearch = ({ usedBy = '', textSearchBind = false, placeholder = 'Nhập từ khóa để tìm kiếm', iconSize = 16, delayTime = 500, className = '', onChange = console.log, onBlur = console.log }) => {
  const [text, setText] = useState('')
  const dispatch = useDispatch()

  const textSearch = useTextSearch(usedBy)

  useEffect(() => {
    return () => {
      dispatch(textSearchChange({ usedBy, textSearch: '' }))
    }
  }, [])

  useEffect(() => {
    textSearchBind && setText(textSearch)
  }, [textSearch])

  useEffect(() => {
    changeSearch(text);
  }, [text])

  const changeSearch = (textSearch) => {
    dispatch(textSearchChange({ usedBy, textSearch }))
    onChange(textSearch)
  }

  const search = debounce(textSearch => {
    setText(textSearch)
  }, 1000)

  const blur = (textSearch) => {
    setText(textSearch)
    onBlur?.()
  }

  return (
    <div className={`text-search-wrapper ${className}`}>
      <InputHasIcon
        value={text}
        placeholder={placeholder}
        prefix={<Icon type='search' size={iconSize} />}
        onChange={search}
        onBlur={blur}
      />
    </div>
  )
}

export function useTextSearch(usedBy) {
  const getText = useSelector(selectText)
  return getText(usedBy)
}
