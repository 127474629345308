import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Input, InputNumber, Textarea } from '@haravan/react-components';
import { InputCurrency } from './input_currency';

import './input.css';

export interface InputDebounceProps {
  id?: any,
  className?: string
  readOnly?: boolean
  type?: 'text' | 'textarea' | 'number' | 'currency'
  value?: string | number
  maxLength?: number
  rowCount?: number
  debounceTime?: number
  onChange?: (value) => void
}

export const InputDebounce = (props: InputDebounceProps) => {
  const { readOnly, className, type, value, maxLength, rowCount, debounceTime = 500, onChange = console.log } = props;
  const [displayValue, setDisplayValue] = useState(null);
  const refAfterChange = useRef(debounce(onChange, debounceTime)); //it catches a snapshot of onChange!

  useEffect(() => {
    setDisplayValue(value);
  }, [])

  useEffect(() => {
    displayValue != null
      && displayValue != value
      && refAfterChange.current(displayValue);
  }, [displayValue])

  useEffect(() => {
    refAfterChange.current = debounce(onChange, debounceTime)
  }, [onChange]) //re-assign if onChange changes ^^

  return <div className={`textbox-debounce-wrapper ${className}`}>
    {
      type === 'number'
        ? <InputNumber className="textbox-debounce-input" readOnly={readOnly}
          value={displayValue || 0} onChange={setDisplayValue} maxLength={maxLength}
        />
        : type === 'currency'
          ? <InputCurrency className="textbox-debounce-input" isReadOnly={readOnly}
            value={displayValue || 0} onChange={setDisplayValue} maxLength={maxLength}
          />
          : type === 'textarea'
            ? <Textarea className="textbox-debounce-input" readOnly={readOnly}
              value={displayValue ?? ''} onChange={setDisplayValue} rows={rowCount}
            />
            : <Input className="textbox-debounce-input" readOnly={readOnly}
              value={displayValue ?? ''} onChange={setDisplayValue}
            />
    }
  </div>
}
