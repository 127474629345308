import { Button, Loading, Notification } from "@haravan/react-components"
import { useEffect, useRef, useState } from "react"
import { useParams } from 'react-router-dom'
import * as HrvComponents from '@haravan/react-components';
import { Modal } from 'react-bootstrap';

import './index.css';
import { ShopplanRepository } from "../../../repositories/shopplan_repository"
import { CardShopPlan } from "../../../components/card_detail/card_detail"
import { isEqual } from 'lodash';
import { PageSection } from "../../../components/page/page_layout";
import { FormatKey, FormatValue } from "../../../components/format/format";
import { InputCurrency } from "../../../components/input";

export const ShopPlansDetail = () => {
  const { Id, ProId, Clone_Id } = useParams()
  const [shopPlans, setShopPlans] = useState(null);
  const [shopPlansClone, setShopPlansClone] = useState(null);
  const [listProduct, setListProduct] = useState([]);
  const [isLoadingData, setLoadingData] = useState(true);
  const [modalDeleteMeta, setModalDeleteMeta] = useState(false);
  const [modalEditMeta, setModalEditMeta] = useState(false);
  const [modalAddMeta, setModalAddMeta] = useState(false);
  const [indexDetele, setIndexDelete] = useState(null);
  const [itemEdit, setItemEdit] = useState(null);
  const [indexEdit, setIndexEdit] = useState(null);
  const [itemAdd, setItemAdd] = useState(null);
  const [metaDTCheck, setMetaDTCheck] = useState(null);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [value, setValue] = useState([]);
  const [queryProduct, setQueryProduct] = useState(null);
  const [productId, setProductId] = useState(0);
  const [shopPlanId, setShopPlanId] = useState(0);
  const ref_element = useRef(null);

  const listInterval = [{ Id: 1, Name: 'year' }, { Id: 2, Name: 'month' }];

  useEffect(() => {
    setProductId(parseInt(ProId));
    var shopPlanId = Id ?? Clone_Id;
    setShopPlanId(parseInt(shopPlanId));
    if (shopPlanId) {
      ShopplanRepository.GetShopPlansById(shopPlanId, ProId).then(rs => {
        if (rs?.data) {
          let shopPlans = rs.data;
          setShopPlans(shopPlans);
          setShopPlansClone(shopPlans);
          let dataMeta = []
          if (rs.data?.metaDatas.length !== 0) {
            rs.data?.metaDatas.map(o => {
              dataMeta.push({ ...o })
            })
          }
          let interval = []
          listInterval.map(t => {
            if (t.Name == rs.data.interval) {
              interval.push({ ...t })
              setValue(interval);
            }
          })
          setMetaDTCheck(dataMeta);
        } else {
          const newShopPlan = 'products/' + ProId;
          window.location.replace(newShopPlan);
        }
        setLoadingData(false)
      })
    } else setShopPlans({ ...shopPlans, metaDatas: [], cost: 0, refCost: 0 })
  }, [Id, ProId, Clone_Id])

  useEffect(() => {
    let __pro_id = productId;
    if (queryProduct) __pro_id = 0;
    ShopplanRepository.GetListProducts(1, 5, queryProduct, __pro_id).then(rs => {
      if (!rs.has_error) {
        setListProduct(rs.data.data);
        if (__pro_id > 0 && shopPlans) setShopPlans({ ...shopPlans, productId: __pro_id })
      }
      setLoadingData(false)
    })
  }, [queryProduct, productId])

  useEffect(() => {
    if (shopPlans != null) {
      if (!isEqual(metaDTCheck, shopPlans?.metaDatas)) setChange(!isEqual(metaDTCheck, shopPlans?.metaDatas));
      else setChange(!isEqual(shopPlans, shopPlansClone));
    }
  }, [shopPlans])

  const SetShopPlans = () => {
    if (shopPlans != null) {
      setLoading(true)
      if (Clone_Id) shopPlans.id = 0;
      ShopplanRepository.SetShopPlans(shopPlans).then(rs => {
        if (rs) {
          if (!rs.has_error) {
            Notification.success({ message: 'Cập nhật thành công' });
            let url = `products/${rs?.data?.productId}/shopplans/${rs?.data?.id}`;
            window.location.replace(url);
          }
          setLoading(false);
        } else {
          Notification.error({ message: "Cập nhật thất bại" });
          setLoading(false);
        }
      })
    }
  }

  const addMetaData = () => {
    setModalAddMeta(true);
  }

  const addProduct = () => {
    window.open("products/new", "_blank");
  }



  const saveData = () => {
    SetShopPlans();
  }

  const addMetaDatas = () => {
    let item = { ...shopPlans };
    if (!itemAdd?.key || itemAdd?.key?.trim() === '') {
      return HrvComponents.Notification.error({
        message: "Key không được để trống"
      });
    }
    if (!itemAdd?.value || itemAdd?.value?.trim() === '') {
      return HrvComponents.Notification.error({
        message: "Value không được để trống"
      });
    }
    let bug = false;
    item.metaDatas.map((o) => {
      if (o.key.trim() == itemAdd.key.trim()) bug = true;
    })
    if (bug) return HrvComponents.Notification.error({
      message: "Key bị trùng"
    });
    setModalAddMeta(false);
    item.metaDatas.push({ key: itemAdd?.key, value: itemAdd?.value });
    setShopPlans({ ...item });
    setItemAdd(null);
  }

  const deleteMetaDatas = (index) => {
    setModalDeleteMeta(false);
    let item = shopPlans;
    item.metaDatas.splice(index, 1);
    setShopPlans({ ...item });
  }

  const editMetaData = (it, index) => {
    setModalEditMeta(true);
    setItemEdit(it);
    setIndexEdit(index);
  }

  const removeMetaDatas = (index) => {
    setModalDeleteMeta(true);
    setIndexDelete(index);
  }

  const changeKeyMetaData = (value) => {
    setItemEdit({ ...itemEdit, key: value })
  }

  const changeValueMetaData = (value) => {
    setItemEdit({ ...itemEdit, value: value })
  }

  const editMetaDatas = () => {
    if (!itemEdit?.key || itemEdit?.key?.trim() === '') {
      return HrvComponents.Notification.error({
        message: "Key không được để trống"
      });
    }
    if (!itemEdit?.value || itemEdit?.value?.trim() === '') {
      return HrvComponents.Notification.error({
        message: "Value không được để trống"
      });
    }
    let item = shopPlans;
    let keyDup = 0;
    item.metaDatas.map((o, i) => {
      if (o.key.trim() == itemEdit.key.trim() && i != indexEdit) keyDup++;
    })
    if (keyDup > 0) return HrvComponents.Notification.error({
      message: "Key bị trùng"
    });
    setModalEditMeta(false);
    item.metaDatas[indexEdit].key = itemEdit.key;
    item.metaDatas[indexEdit].value = itemEdit.value;
    setShopPlans({ ...item });
  }

  const exitModalAdd = () => {
    setModalAddMeta(false);
    setItemAdd(null);
  }
  const renderLable = () => {
    if (Clone_Id)
      return `Clone Shop Plan ${shopPlans?.name ?? ''}`
    if (Id == undefined)
      return 'New Shop Plan'
    return `Shop Plan ${shopPlans?.name ?? ''}`
  }

  const renderBody = () => {
    return (
      <div className="shopplans-screen">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h3 className="panel-title">{renderLable()}</h3>
            </div>
            {renderShopPlans()}
          </div>
        </div>
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-heading-shopplan">
              <h3 className="panel-title-shopplan">Meta Data</h3>
              <div className="btn-add-metadata">
                <HrvComponents.Tooltip title='Thêm'>
                  <HrvComponents.Button onClick={() => addMetaData()} size='small' status='primary'><HrvComponents.Icon type='plusCircle' theme='solid' /></HrvComponents.Button>
                </HrvComponents.Tooltip>
              </div>
            </div>
            <div className="body-shopplan">
              {renderMetaDatas()}
            </div>
            {renderAddMetaDatas()}
          </div>
        </div>
      </div>
    )
  }

  const changeInterval = (value) => {
    setValue(value)
    setShopPlans({ ...shopPlans, interval: value[0]?.Name })
  }

  const renderShopPlans = () => {
    return (
      <div className="col-md-12 flex">
        <div className="col-md-6">
          <CardShopPlan name="Name" value={<HrvComponents.Input value={shopPlans?.name ?? ''} onChange={value => setShopPlans({ ...shopPlans, name: value })} className='input-name-shopplan' />}></CardShopPlan>
          <CardShopPlan name="Code " value={<HrvComponents.Input disabled={Id != null ? true : false} value={shopPlans?.productCode ?? ''} onChange={value => setShopPlans({ ...shopPlans, productCode: value })} className='input-name-shopplan' />}></CardShopPlan>
          <CardShopPlan name="Cost" value={<InputCurrency className="input-number-shopplan" value={shopPlans?.cost ?? 0} onChange={value => Number.isNaN(value) ? setShopPlans({ ...shopPlans, cost: 0 }) : setShopPlans({ ...shopPlans, cost: value })} />}></CardShopPlan>
          <CardShopPlan name="Ref Cost" value={<InputCurrency className="input-number-shopplan" value={shopPlans?.refCost ?? ''} onChange={value => Number.isNaN(value) ? setShopPlans({ ...shopPlans, refCost: 0 }) : setShopPlans({ ...shopPlans, refCost: value })} />}></CardShopPlan>
          <CardShopPlan name="Description" value={<HrvComponents.Input value={shopPlans?.description ?? ''} onChange={value => setShopPlans({ ...shopPlans, description: value })} className='input-name-shopplan' />}></CardShopPlan>
        </div>
        <div className="col-md-6">
          <CardShopPlan name="Product" value={
            <div className="flex">
              <div className="select-product" ref={ref_element} style={{ position: "relative" }}>
                <HrvComponents.Selection showSearch placeholder='Chọn Product' onSearch={(value) => setQueryProduct(value)} value={productId} onChange={(value) => { setProductId(value); setShopPlans({ ...shopPlans, productId: value }) }} getPopupContainer={() => ref_element.current}>
                  {listProduct && listProduct.map((item, index) => {
                    const key = 'selectionItem-' + index;
                    return <HrvComponents.Selection.Option key={key} value={item.id}>{item.name}</HrvComponents.Selection.Option>
                  })}
                </HrvComponents.Selection>
              </div>
              <div>
                <HrvComponents.Tooltip title='Thêm'>
                  <HrvComponents.Button onClick={() => addProduct()} size='small' status='primary'><HrvComponents.Icon type='plusCircle' theme='solid' /></HrvComponents.Button>
                </HrvComponents.Tooltip>
              </div>
            </div>
          }></CardShopPlan>
          <CardShopPlan name="Attributes" value={<HrvComponents.Input value={shopPlans?.attributes ?? ''} onChange={value => setShopPlans({ ...shopPlans, attributes: value })} className='input-name-shopplan' />}></CardShopPlan>
          <CardShopPlan name="Interval" value={<HrvComponents.DropdownCustom placeHolder={"Chọn"} listData={listInterval} listValue={value} keyId='Id' keyName='Name' onChangeItems={(data) => { changeInterval(data) }} />}></CardShopPlan>
          <CardShopPlan name="Interval Count" value={<HrvComponents.InputNumber value={shopPlans?.intervalCount ?? ''} onChange={value => setShopPlans({ ...shopPlans, intervalCount: value })} className='input-number-shopplan' />}></CardShopPlan>
          <CardShopPlan name="Trial Days" value={<HrvComponents.InputNumber value={shopPlans?.trialPeriodDays ?? ''} onChange={value => setShopPlans({ ...shopPlans, trialPeriodDays: value })} className='input-number-shopplan' />}></CardShopPlan>
          <CardShopPlan name="Actived" value={<HrvComponents.Switch checked={shopPlans?.isActived} onChange={value => setShopPlans({ ...shopPlans, isActived: value })} className='checkbox-shopplan' />}></CardShopPlan>
        </div>
      </div>
    )
  }

  const renderMetaDatas = () => {
    return (
      shopPlans?.metaDatas?.length > 0 ? <div className='list-metadatas'>
        <HrvComponents.Table
          renderTableHeader={
            <tr>
              <th><b>Key</b></th>
              <th><b>Value</b></th>
              <th></th>
              <th></th>
            </tr>
          }
          renderTableBody={
            shopPlans?.metaDatas?.map((item, index) => {
              return <tr className='tb-list-meta'>
                <td className='item-meta' >{FormatKey(item.key)}</td>
                <td className='item-meta' >{item.value}</td>
                <td className="list-btn">
                  <div className='btn-edit'>
                    <HrvComponents.Tooltip title='Sửa'>
                      <HrvComponents.Button onClick={() => editMetaData(item, index)} status='link'><HrvComponents.Icon type='pencil' theme='solid' /></HrvComponents.Button>
                    </HrvComponents.Tooltip>
                  </div>
                  <div className='btn-delete'>
                    <HrvComponents.Tooltip title='Xóa'>
                      <HrvComponents.Button onClick={() => removeMetaDatas(index)} status='link'><HrvComponents.Icon type='trash' theme='solid' /></HrvComponents.Button>
                    </HrvComponents.Tooltip>
                  </div>
                </td>
                <td></td>
              </tr>
            })
          } />
        {renderEditMetaDatas()}
        {renderDeleleMetaDatas()}
      </div> : renderEmpty()
    )
  }

  const renderAddMetaDatas = () => {
    return (
      <Modal show={modalAddMeta} onHide={() => exitModalAdd()}>
        <Modal.Header closeButton>
          <Modal.Title>Thêm Meta Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>Key</label>
            <HrvComponents.Input value={itemAdd?.key ?? ''} onChange={key => setItemAdd({ ...itemAdd, key })} className='input-name-metadata' />
            <label>Value</label>
            <HrvComponents.Input value={itemAdd?.value ?? ''} onChange={value => setItemAdd({ ...itemAdd, value })} className='input-name-metadata' />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <HrvComponents.Button status='danger' onClick={() => exitModalAdd()}>
            Hủy
          </HrvComponents.Button>
          <HrvComponents.Button status="primary" onClick={() => addMetaDatas()}>
            Xác nhận
          </HrvComponents.Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const renderEditMetaDatas = () => {
    return (
      <Modal show={modalEditMeta} onHide={() => setModalEditMeta(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Sửa Meta Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>Key</label>
            <HrvComponents.Input value={itemEdit?.key ?? ''} onChange={key => changeKeyMetaData(key)} className='input-name-metadata' />
            <label>Value</label>
            <HrvComponents.Textarea value={itemEdit?.value ?? ''} onChange={value => changeValueMetaData(value)} className='input-name-metadata' />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <HrvComponents.Button status='danger' onClick={() => setModalEditMeta(false)}>
            Hủy
          </HrvComponents.Button>
          <HrvComponents.Button status="primary" onClick={() => editMetaDatas()}>
            Xác nhận
          </HrvComponents.Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const renderDeleleMetaDatas = () => {
    return (
      <Modal show={modalDeleteMeta} onHide={() => setModalDeleteMeta(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Xóa Meta Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc muốn xoá Meta Data này?</Modal.Body>
        <Modal.Footer>
          <HrvComponents.Button status='danger' onClick={() => setModalDeleteMeta(false)}>
            Hủy
          </HrvComponents.Button>
          <HrvComponents.Button status="primary" onClick={() => deleteMetaDatas(indexDetele)}>
            Xác nhận
          </HrvComponents.Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const renderEmpty = () => {
    return (
      <PageSection>
        <div className="p-5 text-center">
          <h6>No data yet</h6>
        </div>
      </PageSection>
    );
  };

  return isLoadingData ? (
    <Loading className='hrv-loading-page' size='thumb' />
  ) : (
    <div className="shopplans-detail">
      {renderBody()}
      <div className='data-save-wrapper has-changed'>
        <div className='hrv-btn-group--item-1'></div>
        <div className='hrv-btn-group--item'>
          <Button onClick={saveData} status='primary' loading={loading}>Lưu</Button>
          {Clone_Id == undefined && Id && <Button onClick={() => { window.location.href = `products/${ProId}/shopplans/clone/${Id}` }} status='success'>Nhân bản</Button>}
        </div>
      </div>
    </div>
  )
}