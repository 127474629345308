import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { DataSave, Tab, TabItem } from '../../../components'
import { TransactionInfo } from './info'
import { PartnerRepository } from '../../../repositories'
import { dataSaved, selectChangedData, selectHasChanged } from '../../../components/data_save/data_save_slice'

import './index.css'

export const TransactionDetail = () => {

  return (
    <div className='shop-detail-wrapper'>
      <Tab>
        <TabItem name='Chi tiết giao dịch' />
      </Tab>
    </div>
  )
}
