import { toDateTimeString, resizeImage } from '../../../global/utils/functions';
import { AppRepository } from '../../../repositories';
import { Currency, DataList, DataSearch, Detail, Status } from '../../../components';

import './index.css';
import { FormatAppName } from '../../../components/format/format';

const headers = [
  { name: 'Tên ứng dụng', className: 'app-name-col' },
  { name: 'Đối tác', className: 'app-partner-col' },
  { name: 'Giá bán', className: 'app-cost-col' },
  { name: 'Hạn dùng thử', className: 'app-trial-day-col' },
  { name: 'Ngày đăng ký', className: 'app-date-col' },
  { name: 'Trạng thái', className: 'app-status-col' },
];

export const AppList = props => {
  // const [filterFields, setFilterFields] = useState();

  const fetchStores = async (page, pageSize, search, filter) => {
    const response = await AppRepository.GetListApp(page, pageSize, search);
    const list = response?.data?.data?.map(a => ({
      name: <div className='app-image-name'>
        <img className='app-image-col' src={resizeImage(a.icon, 'thumb')} alt=''/>
        <Detail to={`/apps/${a.id}`}>{FormatAppName(a.name)}</Detail>
      </div>,
      // description: a.description,
      partner: a.partner && <Detail to={`/partners/${a.partner.id}`}>{`${a.partner.lastName ?? ''} ${a.partner.firstName ?? ''}`}</Detail>,
      price: <Currency ammount={a.prices?.[0]?.priceApprove}/>,
      trialDay: `${a.trialDay} ngày`,
      createdDate: toDateTimeString(a.createdAt),
      status: <Status text={a.status}/>,
    }));
    const rawData = response?.data?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  };

  return (
    <div className='app-list'>
      <DataSearch className='mb-md' placeholder='Tìm kiếm theo id, tên'/>
      <DataList headers={headers} loadData={fetchStores} />
    </div>
  );
};
