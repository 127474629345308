import { Notification } from '@haravan/react-components';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasChanged: false,
  changedData: {},
  isLoading: false
};

export const dataSaveSlice = createSlice({
  name: 'data-save',
  initialState,
  reducers: {
    dataChanged: (state, action) => {
      state.hasChanged = action.payload.hasChanged
      state.changedData = action.payload.data
    },
    dataSaved: state => {
      state.hasChanged = false
      state.isLoading = false
      state.changedData = {}
      Notification.success({ message: 'Lưu thành công', duration: 2 })
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    }
  }
});

export const { dataChanged, dataSaved, setLoading } = dataSaveSlice.actions;

export const selectHasChanged = state => state.dataSave.hasChanged;
export const selectChangedData = state => state.dataSave.changedData;
export const getLoading = state => state.dataSave.isLoading;
export default dataSaveSlice.reducer;
