import { Currency, TableFilter, Detail, Status, FilterSearchModel, IRequestExportModel } from '../../../components'
import { PayoutRepository, PermissionRepository } from '../../../repositories'
import { toDateTimeString, IFilterType } from '../../../global/utils/functions'
import { Notification } from '@haravan/react-components'

import './index.css'
import { downloadLink } from '../../../global/utils/file_helper'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../auth/services/auth_slice'

const usedBy = 'payout-list'
const headers = [
  { name: 'Id', className: 'payout-id' },
  { name: 'Mã giao dịch', className: 'payout-transaction' },
  { name: 'Đối tác', className: 'payout-partner' },
  { name: 'Ref', className: 'payout-partner-ref' },
  { name: 'Loại giao dịch', className: 'payout-type' },
  { name: 'Cửa hàng', className: 'payout-store' },
  { name: 'Số tiền', className: 'payout-ammount' },
  { name: 'Ngày tạo', className: 'payout-created-date' },
  { name: 'Ngày thanh toán', className: 'payout-paid-date' },
  { name: 'Trạng thái công nợ', className: 'payout-status' },
  { name: 'Trạng thái giao dịch', className: 'payout-order-status' },
]

export const PayoutList = props => {

  const [canRequestAccess, setCanRequestAccess] = useState(false)
  const user = useSelector(selectUser)
  const fetchPayouts = async (filter: FilterSearchModel) => {
    const response = await PayoutRepository.Filter(filter)
    const list = response?.data?.data?.map(p => ({
      id: p.partnerBalanceId,
      transactionId: <>{p.transaction && <Detail to={`/transactions/${p.transaction.id}`}>{p.transaction.id}</Detail>}</>,
      partnerName: <Detail to={`/partners/${p.partner.id}`}>{`${p.partner.lastName} ${p.partner.firstName}`.trim()}</Detail>,
      partnerRef: p.partner.ref,
      transactionType: p.transaction && p.transaction.type,
      shopName: <>{p.shop && <Detail to={`/shops/${p.shop.id}`}>{p.shop.name}</Detail>}</>,
      credit: <Currency ammount={p.credit} />,
      createdDate: toDateTimeString(p.createdDate),
      paymentDate: toDateTimeString(p.paymentDate),
      balanceStatus: <Status text={p.balanceStatus} />,
      transactionStatus: <>{p.transaction && <Status text={p.transaction.status} />}</>,
    }))
    const rawData = response?.data?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  }

  const exportData = async (data: IRequestExportModel) => {
    var response = await PayoutRepository.Export(data)
    if (!response.has_error)
      Notification.success({ message: 'Danh sách công nợ đã bắt đầu được trích xuất. Khi hoàn tất, chúng tôi sẽ gửi một email cùng với file đến cho bạn', duration: 2 })
  }

  useEffect(() => {
    user &&
    PermissionRepository.GetFunctions(user.profile?.sub).then(rs => {
        setCanRequestAccess(rs?.data?.findIndex(r => r.code === 'inside.partner-balance.export') > -1)
      })
  }, [user])

  return (
    <div className='shop-list-wrapper'>
      <TableFilter type={IFilterType.payouts} usedBy={usedBy} canFilter={true} canExportData={true} processExportData={exportData} headers={headers} loadData={fetchPayouts} placeholder="Tìm kiếm theo mã giao dịch " />
    </div>
  )
}
