import { FormatDateTime } from "@haravan/react-components";
import { PartnerRepository } from "../../../../repositories";
import { Currency, Detail, Download, Link, Status } from "../../../../components";
import { STATUS_THEME_NAME } from '../../../../components/status/status'
import { ListViewHeader, ListViewPageSize, ListViewPagingInfo, ListViewSimplePaging } from "../../../../components/list_view/listview_simple_paging";

import "./index.css";
import { useParams } from "react-router-dom";

const getData = (paging: ListViewPagingInfo, partnerId) => PartnerRepository.GetThemeByPartnerId(partnerId, paging.searchText, paging.before, paging.after, paging.limit);

const headers = [
  { name: "Tên giao diện", className: "partner-theme-name" },
  { name: "Đối tác", className: "partner-theme-partner-name" },
  { name: "Giá bán", className: "partner-theme-price-cost" },
  { name: "Ngày tạo", className: "partner-theme-created-date" },
  { name: "Tải xuống", className: "partner-theme-download" },
  { name: "Trạng thái", className: "partner-theme-status" },
] as ListViewHeader[];

export const PartnerTheme = (props) => {
  const { partnerId } = useParams();


  const renderRow = (theme, index) => {
    let price = theme?.price?.status == STATUS_THEME_NAME.Approved ? theme?.price?.costApproved : 0;

    if (theme != null) {
      return (
        <>
          <div className="partner-theme-name">
            <Detail to={`./${theme?.id}`}>{`${theme?.name}`}</Detail>
          </div>
          <div className="partner-theme-partner-name">
            <Detail to={`/partners/${theme?.partner?.id}`}>{`${theme?.partner?.lastName} ${theme?.partner?.firstName}`}</Detail>
          </div>
          <div className="partner-theme-price-cost">
            {price && (
              <>
                <Currency ammount={price} />
              </>
            )}
          </div>
          <div className="partner-theme-created-date">{FormatDateTime(theme.createdDate)}</div>
          <Download className="partner-theme-download" link={theme?.files?.map((p) => p.file)} />
          <div className="partner-theme-status">
            <Status text={theme.status} />
          </div>
        </>
      );
    }
  };

  return (
    <div className="container">
      <div className="row">
        <ListViewSimplePaging
          allowSearch
          searchPlaceHolder="Tìm kiếm theo id, tên"
          className="theme-file-border"
          getData={(paging) => getData(paging, partnerId)}
          pageSize={ListViewPageSize.SMALL_PAGE_SIZE}
          renderRow={renderRow}
          headers={headers}
        />
      </div>
    </div>
  );
};
