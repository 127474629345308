import React from 'react'
import { AppRepository } from '../../../repositories'
import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { toDateString } from '../../../global/utils/functions'
import { DataSave, StatusSelector, InputDebounce, ListViewSimplePaging, Link } from '../../../components'
import {Loading, Textarea, Input, Button, Notification} from '@haravan/react-components'
import { AppPrice } from '../price'
import { dataChanged, dataSaved, setLoading, getLoading } from '../../../components/data_save/data_save_slice'
import { STATUS_APP_NAME, STATUS, Status } from '../../../components/status/status'
import { Card } from 'reactstrap';
import * as HrvComponents from '@haravan/react-components';

import './index.css'
import { PageLayout } from '../../../components/page/page_layout'
import { SMALL_PAGE_SIZE } from '../../../components/list_view/listview_simple_paging'
import { toFormattedNumber } from '../../../global/functions/functions'
import { ContentEditorInside } from '../../../global/contentEditor/content_editor_inside'
import { FormatPriceName } from '../../../components/format/format'

export const AppDetail = () => {
  const { appId } = useParams()
  const dispatch = useDispatch()
  const isLoading = useSelector(getLoading);
  const [app, setApp] = useState(null)
  const [isLoadingData, setLoadingData] = useState(true)
  const [isRejected, setRejection] = useState(false)
  const [status_App, setStatus_App] = useState(null)
  const [modalConfirmPublish, setModalConfirmPublish] = useState(false);
  
  useEffect(() => {
    AppRepository.GetApp(appId).then(rs => {
      if (rs?.data) {
        let app = rs.data
        setApp(app)
        setStatus_App(app.status);
        setRejection(app.status === STATUS_APP_NAME.Rejected)
      }
      setLoadingData(false)
    })
  }, [])

  const saveData = () => {
    dispatch(setLoading(true))
    AppRepository.PutApproveApp(app).then(rs => {
      if (rs?.data && !rs?.has_error) {
        window.location.reload();
      }
      else dispatch(setLoading(false))
    })
  }

  const handleOnChangePublishedApp = (isPublished) => {
    dispatch(setLoading(true))
   
    AppRepository.PutPublishedApp(appId, {isPublished : isPublished}).then(rs => {
      if(!rs.has_error)
      {
        Notification.success({ message: 'Cập nhật thành công' });
        setModalConfirmPublish(false);
        setApp({ ...app, isPublished: isPublished })
      }
      dispatch(setLoading(false))
    })
  }

  const handleOnChangeStatus = value => {
    const clonedApp = { ...app, status: value }
    setRejection(value === STATUS_APP_NAME.Rejected)
    setApp(clonedApp)
    dispatch(dataChanged({ hasChanged: value !== app.status, data: clonedApp }))
  }

  const handleOnChangeReason = value => {
    const clonedApp = { ...app, rejectionReason: value }
    setApp(clonedApp)
    dispatch(dataChanged({ hasChanged: value !== app.rejectionReason, data: clonedApp }))
  }

  const handleOnChangeStatusPrice = (value, index) => {
    let clonedPrices = [...app.prices]
    clonedPrices[index] = { ...clonedPrices[index], status: value }
    const clonedApp = { ...app, prices: clonedPrices }
    setApp(clonedApp)
    dispatch(dataChanged({ hasChanged: value !== app.prices[index]?.status, data: clonedApp }))
  }

  const handleOnChangeReasonPrice = (value, index) => {
    let clonedPrices = [...app.prices]
    clonedPrices[index] = { ...clonedPrices[index], rejectionReason: value }
    const clonedApp = { ...app, prices: clonedPrices }
    setApp(clonedApp)
    dispatch(dataChanged({ hasChanged: value !== app.prices[index]?.rejectionReason, data: clonedApp }))
  }

  const handleOnChangePrice = (value, index) => {
    let clonedPrices = [...app.prices]
    clonedPrices[index] = { ...clonedPrices[index], priceApprove: value }
    const clonedApp = { ...app, prices: clonedPrices }
    setApp(clonedApp)
    dispatch(dataChanged({ hasChanged: value !== app.prices[index]?.priceApprove, data: clonedApp }))
  }

  const renderInformation = () => {
    return (<>
          {app?.addedToStore && <div className='hrv-switch-custom-app'>
            <HrvComponents.Switch checkedChildren='Hiển thị' unCheckedChildren='Ẩn' checked={app.isPublished} onChange={() => setModalConfirmPublish(true)} />
          </div>}
          <PageLayout>
            <PageLayout.AnotatedSection
              title="Thông tin ứng dụng"
              description="Thông tin này sẽ được sử dụng để xác định ứng dụng của bạn.">
              <Card>
                <div className='app-status'>
                  <StatusSelector listStatus={STATUS}
                    value={app?.status}
                    onSelect={handleOnChangeStatus}
                    disableSelect={status_App == STATUS_APP_NAME.Approved || status_App == STATUS_APP_NAME.Rejected}
                  />
                  <div className='notify-request'>
                    {status_App  == STATUS_APP_NAME.ApprovalWaiting && renderNotifyRequest()}
                    {status_App  == STATUS_APP_NAME.Approved && <HrvComponents.Tooltip title="Đã duyệt"><HrvComponents.Icon style={{ color: "#22c993" }} type='checkCircle' theme='solid' /></HrvComponents.Tooltip>}
                    {status_App  == STATUS_APP_NAME.Rejected && <HrvComponents.Tooltip title="Từ chối"><HrvComponents.Icon style={{ color: "#e64242" }} type='timesCircle' theme='solid' /></HrvComponents.Tooltip>}
                  </div>
                  
                </div>
                {isRejected && (
                  <div className='app-rejection-reason'>
                    <b>{isRejected && 'Lý do từ chối:'}</b>
                    <Textarea value={app?.rejectionReason ?? ''} disabled={status_App == STATUS_APP_NAME.Rejected} onChange={handleOnChangeReason} />
                  </div>
                )}
                <div className="setting-form-section">
                  <label className="input-label-top">Tên ứng dụng</label>
                  <Input className="input-body" disabled type="text" value={app?.name ?? ''} />
                  {app?.partner &&
                    <div className='app-partner-info'>
                      <span>Phát triển bởi</span>
                      <Link to={`/partners/${app?.partner?.id}`}>{`${app?.partner?.lastName} ${app?.partner?.firstName}`}</Link>
                    </div>
                  }
                  <label className="input-label-top">Client Id</label>
                  <Input className="input-body" disabled type="text" value={app?.clientId ?? ''} />
                  {app?.description &&
                    (<div> <label className="input-label-top">Mô tả ngắn</label>
                      <InputDebounce type="textarea" maxLength={1000} className="input-body" readOnly={true} rowCount={2} value={app?.description ?? ''} /> </div>)}
                  {app?.redirectUris && app?.redirectUris.length > 0 &&
                    <div>
                      <label className="input-label-top">Link cài ứng dụng </label>
                      {app?.redirectUris?.map((o) => {
                        return (<Input className="input-body-url" disabled type="text" value={o?.redirectUri ?? ''} />)
                      })}
                    </div>}
                </div>
              </Card>
            </PageLayout.AnotatedSection>
          </PageLayout>    
        </>
    );
  };

  const renderContent = () => {
    return (app?.content &&
      <PageLayout>
        <PageLayout.AnotatedSection
          title="Mô tả chi tiết"
          description="Mô tả tính năng ứng dụng, chi tiết ứng dụng chi tiết hơn, dễ dàng tiếp cận nhà bán hàng trên kho ứng dụng Haravan" >
          <Card>
            <div className='input-body'>
              <ContentEditorInside value={app?.content} />
            </div>
            {/* <InputDebounce type="textarea" maxLength={1000} className="input-body" readOnly={true} rowCount={2} value={app?.content ?? ''} /> */}
          </Card>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  };

  const renderAppIcon = () => {
    return (app?.icon &&
      <PageLayout>
        <PageLayout.AnotatedSection
          title="Ảnh ứng dụng "
          description="Biểu tượng giúp người bán nhận ra và xác định ứng dụng của bạn.">
          <Card>
            <div className="card-image">
              <div className="image-area">
                <div className='border-photo'>
                  <HrvComponents.ImageResize className='image-border' src={app.icon} size="small" />
                  <div className='hrv-fileupload--content'>Định dạng ảnh ứng dụng  .jpg hoặc .png, và kích thước 160px x 160px</div>
                </div>
              </div>
            </div>
          </Card>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  };

  const renderBanner = () => {
    return (app?.banner &&
      <PageLayout>
        <PageLayout.AnotatedSection
          title="Ảnh bìa ứng dụng"
          description="Ảnh này sẽ được Haravan sử dụng quảng cáo ứng dụng mới ">
          <Card>
            <div className="card-image">
              <div className="image-area">
                <div className='border-photo'>
                  <HrvComponents.ImageResize className='image-border' src={app.banner} size="medium" />
                  <div className='hrv-fileupload--content'>Định dạng ảnh bìa ứng dụng .jpg hoặc .png, và kích thước 465px x 150px</div>
                </div>
              </div>
            </div>
          </Card>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  };

  const renderPricingDetails = () => {
    return (
      <PageLayout>
        <PageLayout.AnotatedSection
          title="Chi tiết giá"
          description="Có thể đưa ra mức giá và chờ duyệt. Mỗi ứng dụng có thể có nhiều mức giá.">
          <Card>
            <div className='price-trial'>
              <div>
                <div className='label-trial-day'>Bản dùng thử miễn phí
                  <HrvComponents.Input readOnly className='input-trial-day' value={app?.trialDay} /> ngày
                </div>
              </div>
            </div>
          </Card>
          <div className='app-list-price'>
            {app?.prices.map((p, i) => (
              <AppPrice
                status={p.status}
                key={i + '_' + p.id}
                price={p}
                index={i}
                onChangePrice={handleOnChangePrice}
                onChangeStatus={handleOnChangeStatusPrice}
                onChangeReason={handleOnChangeReasonPrice}
              />
            ))}
          </div>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  };

  const renderPriceHistory = () => {
    return (
      <PageLayout>
        <PageLayout.AnotatedSection
          title="Bảng giá"
          description="Có thể đưa ra mức giá và chờ duyệt. Mỗi ứng dụng có thể có nhiều mức giá.">
          <Card>
            <div className="app-price-history">
              <ListViewSimplePaging
                className="border"
                allowSearch={false}
                headers={HISTORY_HEADERS}
                data={app?.prices}
                pageSize={SMALL_PAGE_SIZE}
                renderRow={renderTableRow}
                renderEmpty={renderEmpty}
              // triggerReload={reloadHistory}
              />
            </div>
          </Card>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  };

  const renderTableRow = price => {
    return (
      <>
        <div className="history-plan-name">
          <p className="text-break">
            {FormatPriceName(price.planName)}
          </p>
        </div>
        <div className="history-date-create">
          <p className="text-break">
            {toDateString(price.createdAt)}
          </p>
        </div>
        <div className="history-status">
          <div className='status-history'>
            <Status text={price.status} />
            {/* <div className='pricing-price'>
              <HrvComponents.Tooltip title={app?.appFeeType == "Purchase" ? getPricing(price.planCode, price.planName) : "Plan Free"}>
                <HrvComponents.Icon style={{ color: "19AFA5" }} type='exclamationCircle' theme='solid' />
              </HrvComponents.Tooltip>
            </div> */}
          </div>
        </div>
        <div className="history-suggested-price">
          {toFormattedNumber(price.priceRecommend || 0)}
        </div>
        <div className="history-approved-price">
          {toFormattedNumber(price.priceApprove || 0)}
        </div>
        <div className='history-reasons-for-canceling'>
          {price?.isRejected && (
            <HrvComponents.Tooltip title={price.rejectionReason}>
              <HrvComponents.Icon style={{ color: "ffb72d" }} type='exclamationCircle' theme='solid' />
            </HrvComponents.Tooltip>)}
        </div>
      </>
    );
  };
  const renderEmpty = () => <Card body className="text-center">
    <div className="p-5">
      <h3>No data found.</h3>
      <p className="mt5">No data on price updates yet</p>
    </div>
  </Card>

  const HISTORY_HEADERS = [
    {
      name: 'Tên gói giá',
      className: 'history-plan-name',
    },
    {
      name: 'Ngày tạo',
      className: 'history-date-create',
    },
    {
      name: 'Trạng thái',
      className: 'history-status',
    },
    {
      name: 'Giá đề xuất',
      className: 'history-suggested-price',
    },
    {
      name: 'Giá duyệt',
      className: 'history-approved-price',
    },
    {
      name: 'Lý do huỷ',
      className: 'history-reasons-for-canceling',
    },
  ];

  const renderNotifyRequest = () => {
    if (app?.tags?.length <= 0) return <></>
    let tags = app?.tags?.split(",");
    return tags?.length > 0 &&
      <HrvComponents.Tooltip title={<>
        <label>Các thay đổi cần duyệt: </label>
        <ul>
          {tags.includes("ch_name") && <li>Tên ứng dụng</li>}
          {tags.includes("ch_desc") && <li>Mô tả ngắn</li>}
          {tags.includes("ch_redirect") && <li>Link cài ứng dụng</li>}
          {tags.includes("ch_content") && <li>Mô tả chi tiết</li>}
          {tags.includes("ch_icon") && <li>Ảnh ứng dụng</li>}
          {tags.includes("ch_banner") && <li>Ảnh bìa ứng dụng</li>}
          {tags.includes("ch_trialday") && <li>Ngày dùng thử</li>}
          {tags.findIndex(o => o.startsWith("ch_prices_defaul")) >= 0 && <li>Gói mặc định</li>}
          {tags.includes("ch_prices") && <li>Gói giá</li>}
        </ul>
      </>}>
        <HrvComponents.Icon style={{ color: "ffb72d" }} type='exclamationCircle' theme='solid' />
      </HrvComponents.Tooltip>
  }
  
  const renderConfirmPublishApp = () => {
    return (
        <Modal show={modalConfirmPublish} onHide={() => setModalConfirmPublish(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Hiển thị ứng dụng</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Bạn có muốn {!app.isPublished ? 'hiển thị': 'ẩn'} ứng dụng này ở cửa hàng ?</div>
          </Modal.Body>
          <Modal.Footer>
            <HrvComponents.Button status='default' onClick={() => setModalConfirmPublish(false)}>
              Hủy
            </HrvComponents.Button>
            <HrvComponents.Button status="primary"  loading={isLoading} onClick={() => handleOnChangePublishedApp(!app.isPublished)}>
              Xác nhận
            </HrvComponents.Button>
          </Modal.Footer>
        </Modal>
    )
  }

  return isLoadingData ? (
    <Loading className='hrv-loading-page' size='thumb' />
  ) : (
      <div className="app-detail">
        {renderInformation()}
        {renderContent()}
        {renderAppIcon()}
        {renderBanner()}
        {renderConfirmPublishApp()}
        <div className='horizontal-line'/>
        {renderPricingDetails()}
        <div className='horizontal-line'/>
        {renderPriceHistory()}
        <DataSave onSaveData={saveData} />
      </div>
  )
}
