import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { signinRedirect, signinRedirectCallback } from './services/auth_service'

export const SigninOidc = () => {
  const navigate = useNavigate();
  
  const signinAsync = async () => {
    signinRedirectCallback()
    .then(() => {
      navigate('/')
    })
    .catch(err => {
      console.error(err, 'trying to reconnect...')
      signinRedirect()
    });
  }

  useEffect(() => {
    signinAsync()
  }, [])
  
  return <div> Redirecting... </div>
}
