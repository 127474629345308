import { useEffect, useState } from 'react'
import * as HaravanComponents from '@haravan/react-components'

import './popover.css'

export function Popover({ visible = false, children, placement = 'bottom' as HaravanComponents.TooltipPlacement, onVisibleChange }) {

  const className = visible ? 'enter' : 'leave'
  const content = <div className={`popover-content-wrapper zoom-big-${className} zoom-big-${className}-active`}>{children}</div>

  const changeVisible = isVisible => {
    onVisibleChange?.(isVisible)
  }

  return (
    <div className='popover-wrapper'>
      <HaravanComponents.Popover visible={visible} onVisibleChange={changeVisible} content={content} placement={placement}></HaravanComponents.Popover>
    </div>
  )
}
