import "./card_detail.css";
import * as HrvComponents from '@haravan/react-components';
import { FormatAddress } from "../format/format";
interface ICardDetailState {
  titleHeader: string;
  data: any;
}
interface ICardDetailItemState {
  name: string;
  content: string;
}

interface ICardDetailIforState {
  name: string;
  value: any;
  tilte?: string;
  phoneVerifired?: boolean;
}
const CardDetailItem = (props: ICardDetailItemState) => {
  const { name, content } = props;
  return (
    <>
      <div className="carddetail-title">{name}</div>
      <span className="carddetail-text">{content ? content : <span className="text-danger italic">Chưa có thông tin</span>}</span>
    </>
  );
};

export const CardDetail = (props: ICardDetailState) => {
  const { titleHeader, data } = props;
  return (
    <div className="h-100">
      <div className="p-2 carddetail-header">{titleHeader}</div>
      <div>
        <div className="container" style={{ overflow: "hidden" }}>
          <div className="row">
            {data.map((item, index) => (
              <div className="col-6">
                <CardDetailItem {...item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CardDetailInfo = (props: ICardDetailIforState) => {
  const { name, value, tilte } = props;
  return (
    <div className="infor-detail">
      <div className="name-detail">
        <div className="text-child"><b>{name}</b> <HrvComponents.Tooltip title={tilte}><HrvComponents.Icon style={{ color: "rgb(67 139 202)" }} type='exclamationCircle' theme='solid' /></HrvComponents.Tooltip></div>
      </div>
      <div className="value-detail">
        <div className="text-child">{value}</div></div>
    </div>
  );
};

export const CardPhoneDetailInfo = (props: ICardDetailIforState) => {
  const { name, value, tilte, phoneVerifired } = props;
  return (
    <div className="infor-detail">
      <div className="name-detail">
        <div className="text-child"><b>{name}</b> <HrvComponents.Tooltip title={tilte}><HrvComponents.Icon style={{ color: "rgb(67 139 202)" }} type='exclamationCircle' theme='solid' /></HrvComponents.Tooltip></div>
      </div>
      <div className="value-detail">
        {phoneVerifired ? <div className="phone-verified"> <div className="text-child">{value}</div> <div className="icon-phone-verified"><HrvComponents.Tooltip title="Đã xác thực"><HrvComponents.Icon style={{ color: "#22c993" }} type='checkCircle' theme='solid' /></HrvComponents.Tooltip></div>  </div> :
          <div className="text-child">{value}</div>
        }
      </div>
    </div>
  );
};

export const CardAddressShop = (props: ICardDetailIforState) => {
  const { name, value, tilte } = props;
  return (
    <div className="infor-detail">
      <div className="name-detail">
        <div className="text-child"><b>{name}</b> <HrvComponents.Tooltip title={tilte}><HrvComponents.Icon style={{ color: "rgb(67 139 202)" }} type='exclamationCircle' theme='solid' /></HrvComponents.Tooltip></div>
      </div>
      <div className="value-detail">
        <div className="text-child">{FormatAddress(value)}</div></div>
    </div>
  );
};

export const CardTransaction = (props: ICardDetailIforState) => {
  const { name, value } = props;
  return (
    <div className="info-detail">
      <div className="lb-name"><b>{name}</b></div>
      <div className="lb-value">{value}</div>
    </div>
  );
};

export const CardShopPlan = (props: ICardDetailIforState) => {
  const { name, value } = props;
  return (
    <div className="info-shopplan">
      <div className="lb-name-shopplan"><b>{name}</b></div>
      <div className="lb-value-shopplan">{value}</div>
    </div>
  );
};

export const CardTransactionInfo = (props: ICardDetailIforState) => {
  const { name, value } = props;
  return (
    <div className="info-detail-info">
      <div className="lb-name-info"><b>{name}</b></div>
      <div className="lb-value-info">{value}</div>
    </div>
  );
};
export const CardAddress = (props: ICardDetailIforState) => {
  const { name, value } = props;
  return (
    <div className="info-detail-info">
      <div className="lb-name-info"><b>{name}</b></div>
      <div className="lb-value-info">{FormatAddress(value)}</div>
    </div>
  );
};

