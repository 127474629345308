import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { changeMenu, getMenus } from "./layouts/left-menu/left_menu_slice";
import {
  AppList,
  PartnerList,
  PartnerDetail,
  ShopList,
  ShopDetail,
  ThemeList,
  AppDetail,
  ThemeDetail,
  PayoutList,
  PayoutDetail,
  TransactionDetail,
  TransactionList,
  PermissionError,
  NotFoundError,
  SigninOidc,
  SignoutOidc,
  UserList,
  RoleList,
  RoleTabList,
  RoleDetail,
  ShopInfo,
  ShopTransactionList,
  ShopAppInstallList,
  ShopThemeInstallList,
  TransactionInfo,
  PartnerInfo,
  Tickets,
  PartnerApp,
  PartnerTheme,
  PartnerPayout,
  ShopPlansList,
  ShopPlansDetail,
  ProductDetail,
  ProductsList
} from "./screens";
import LayoutComponent from "./layouts/layout/layout";

const AppRouter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const menus = useSelector(getMenus);
  const [defaultPath, setDefaultPath] = useState(null);

  useEffect(() => {
    if (menus.length && !defaultPath) {
      const menuLinks = menus.map((m) => m.link?.substring(1));
      const path = menuLinks?.[0] || "/";
      setDefaultPath(path);
      if (location.pathname === "/permission-error") dispatch(changeMenu(""));
      else navigate(location.pathname + location.search);
    }
  }, [menus]);

  return (<Routes>
    <Route element={<LayoutComponent />}>
      {ProtectedRoutes.map((route: any, index: number) => {
        const Component = route.component;
        return <Route key={`${index}-${route.path}`} path={route.path}
          element={<Component />}
        >
          {route?.childrent?.map((rout: any, index: number) => {
            const Comp = rout.component;
            return <Route key={`${index}-${rout.path}`} path={rout.path}
              element={<Comp />}
            >
            </Route>
          })}
        </Route>
      })}
    </Route>
    {ProtectedRoute.map((route: any, index: number) => {
      const Component = route.component;
      return <Route key={`${index}-${route.path}`} path={route.path}
        element={<Component />}
      >
      </Route>
    })}
  </Routes>
  )
}

export default AppRouter;

const ProtectedRoute: any = [
  {
    path: "/signin-oidc",
    component: SigninOidc,
  },
  {
    path: "/signout-callback-oidc",
    component: SignoutOidc,
  }
];

const ProtectedRoutes: any = [
  {
    path: "/",
    component: ShopList,
  },
  {
    path: "/users",
    component: UserList,
  },
  {
    path: "shops",
    component: ShopList,
  },
  {
    path: "/apps",
    component: AppList,
  },
  {
    path: "/shopplans",
    component: ShopPlansList,
  },
  {
    path: "products/:ProId/shopplans/new",
    component: ShopPlansDetail,
  },
  {
    path: "products/:ProId/shopplans/:Id",
    component: ShopPlansDetail,
  },
  {
    path: "products/:ProId/shopplans/clone/:Clone_Id",
    component: ShopPlansDetail,
  },
  {
    path: "/products/new",
    component: ProductDetail,
  },
  {
    path: "/products",
    component: ProductsList,
  },
  {
    path: "/products/:Id",
    component: ProductDetail,
  },
  {
    path: "/products/clone/:Clone_Id",
    component: ProductDetail,
  },
  {
    path: "/products/:Id/shopplans",
    component: ProductDetail,
  },
  {
    path: "/shops/:shopId",
    component: ShopDetail,
    childrent: [
      {
        path: "",
        component: ShopInfo,
      },
      {
        path: "transactions",
        component: ShopTransactionList,
      },
      {
        path: "appinstalls",
        component: ShopAppInstallList,
      },
      {
        path: "themeinstalls",
        component: ShopThemeInstallList,
      },
      {
        path: "tickets",
        component: Tickets,
      },
    ]
  },

  {
    path: "transactions",
    component: TransactionList,
  },
  {
    path: "themes",
    component: ThemeList,
  },
  {
    path: "partners",
    component: PartnerList,
  },
  {
    path: "payouts",
    component: PayoutList,
  },
  {
    path: "/transactions/:transactionId",
    component: TransactionDetail,
    childrent: [
      {
        path: "",
        component: TransactionInfo,
      },
    ]
  },
  {
    path: "/partners/:partnerId",
    component: PartnerDetail,
    childrent: [
      {
        path: "",
        component: PartnerInfo,
      },
      {
        path: "apps",
        component: PartnerApp,
      },
      {
        path: "themes",
        component: PartnerTheme,
      },
      {
        path: "payouts",
        component: PartnerPayout,
      },]
  },
  {
    path: "/partners/:partnerId/apps/:appId",
    component: AppDetail,
  },
  {
    path: "/partners/:partnerId/themes/:themeId",
    component: ThemeDetail,
  },
  {
    path: "/apps/:appId",
    component: AppDetail,
  },
  {
    path: "/themes/:themeId",
    component: ThemeDetail,
  },
  {
    path: "/roles",
    component: RoleList,
    childrent: [
      {
        path: "",
        component: RoleTabList,
      },
      {
        path: "support",
        component: RoleTabList,
      }
    ]
  },
  {
    path: "/roles/new",
    component: RoleDetail,
  },
  {
    path: "/roles/:roleId",
    component: RoleDetail,
  },
  {
    path: "/permission-error",
    component: PermissionError,
  },
  {
    path: "*",
    component: NotFoundError,
  },
  {
    path: "/products",
    component: ProductsList,
  },
];
