import { toDateTimeString } from '../../../global/utils/functions'
import { ThemeRepository } from '../../../repositories';
import { Currency, DataList, DataSearch, Detail, Download, Status } from '../../../components';

import './index.css';

const headers = [
    { name: 'Tên giao diện', className: 'theme-name' },
    { name: 'Đối tác', className: 'theme-partner' },
    { name: 'Giá bán', className: 'theme-cost' },
    { name: 'Ngày tạo', className: 'theme-date' },
    { name: 'Tải xuống', className: 'theme-download' },
    { name: 'Trạng thái', className: 'theme-status' },
]

export const ThemeList = (props) => {

    const fetchStores = async (page, pageSize, search, filter) => {
        const response = await ThemeRepository.GetListTheme(page, pageSize, search)
        const list = response?.data?.data?.map(t => ({
            name: <Detail to={`/themes/${t.id}`}>{t.name}</Detail>,
            partnerName: t.partner ? <Detail to={`/partners/${t.partner.id}`}>{`${t.partner.lastName} ${t.partner.firstName}`}</Detail> : "",
            cost: <Currency ammount={t?.cost} />,
            createdDate: toDateTimeString(t.createdDate),
            download: <Download className='theme-download' link={t?.zipUrl} />,
            status: StatusTheme(t?.status, t?.files[t?.files?.length - 1]?.statusId, t?.price?.status),
        })
        )
        const rawData = response?.data?.data
        const total = response?.data?.totalRecord
        return { list, rawData, total }
    }

    return <div className="theme-list-wrapper">
        <DataSearch className='mb-md' placeholder='Tìm kiếm theo id, tên' />
        <DataList headers={headers} loadData={fetchStores} />
    </div>
}

const StatusTheme = (sttInfo, sttFile, sttPrice) => {
    const listStatus = [];

    if (sttInfo === "Chờ duyệt" && sttFile === "Chờ duyệt" && sttPrice === "Chờ duyệt") {
        listStatus.push("Chờ duyệt");
        return <Status text={listStatus[0]} />;
    }
    else if (sttInfo === "Đã duyệt" && sttFile === "Đã duyệt" && sttPrice === "Đã duyệt") {
        listStatus.push("Đã duyệt");
        return <Status text={listStatus[0]} />;
    }
    else if (sttInfo === "Từ chối" && sttFile === "Từ chối" && sttPrice === "Từ chối") {
        listStatus.push("Từ chối");
        return <Status text={listStatus[0]} />;
    } else {
        if (sttInfo === "Đã duyệt") listStatus.push("Đã duyệt");
        if (sttInfo === "Chờ duyệt") listStatus.push("Chờ duyệt");
        if (sttInfo === "Từ chối") listStatus.push("Từ chối");
        if (sttFile === "Đã duyệt") listStatus.push("Đã duyệt version");
        if (sttFile === "Chờ duyệt") listStatus.push("Chờ duyệt version");
        if (sttFile === "Từ chối") listStatus.push("Từ chối version");
        if (sttPrice === "Đã duyệt") listStatus.push("Đã duyệt giá");
        if (sttPrice === "Chờ duyệt") listStatus.push("Chờ duyệt giá");
        if (sttPrice === "Từ chối") listStatus.push("Từ chối giá");
        return listStatus.map((stt) => {
            return <Status text={stt} />;
        });
    }
}
