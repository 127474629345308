import * as HrvComponents from '@haravan/react-components';
import "./index.css";
import { useEffect, useState } from 'react'
import { PermissionRepository } from '../../../../repositories/permission_repository';
import { NavLink, useSearchParams, useLocation } from 'react-router-dom';

export function RoleTabList() {
    const { pathname } = useLocation()
    const [listRole, setListRole] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [flowRequestAccsess, setFlowRequestAccsess] = useState(false);
    useEffect(() => {
        let paths = pathname.split('/')
        let endPath = paths[paths.length - 1]
        var is_request_accsess = endPath == "support"
        setFlowRequestAccsess(is_request_accsess);
        getData(is_request_accsess)
    }, [pathname]);

    const getData = (is_request_accsess) => {
        PermissionRepository.GetListRole(is_request_accsess ? "request_accsess" : null
        ).then(rs => {
            setListRole(rs.data);
            return rs.data;
        });
    }
    return (listRole &&
        <div className="permission-screen">
            <div className='listview'>
                <div className='header-permission'>
                    <div><b>Nhóm quyền {flowRequestAccsess ? 'hỗ trợ cửa hàng' : 'Inside'}</b></div>
                    <div>
                        <div className="empty-state-button">
                            <a
                                className="hrv-btn hrv-btn-primary"
                                href={`/roles/new${flowRequestAccsess ? '?flow=request_accsess' : ''}`}>
                                Tạo nhóm mới
                            </a>
                        </div>
                    </div>
                </div>
                <div className='listview-content'>
                    {listRole.length <= 0 ? <h6 className="text-center">Chưa có dữ liệu</h6> : <HrvComponents.Table
                        renderTableHeader={
                            <tr>
                            </tr>
                        }
                        renderTableBody={
                            listRole.map((item, index) => {
                                return <tr key={item.id + index}>
                                    <td className='item-role'><NavLink to={`/roles/${item.id}`.trim()}>{<div className='permission-item'><a >{item.name}</a></div>} </NavLink></td>
                                </tr>
                            })
                        } />
                    }
                </div>
            </div>
        </div>
    )
};
