export const messages_vi = {
  "ERR_APP_DOES_NOT_EXIST": "Ứng dụng không tồn tại.",
  "ERR_APP_IS_NOT_OWNED": "Ứng dụng này không thuộc quyền sở hữu.",
  "ERR_APP_CREATE_FAILED": "Không tạo được ứng dụng.",
  "ERR_APP_UPDATE_FAILED": "Không cập nhật được ứng dụng.",
  "ERR_APP_BUY_FAILED": "Có lỗi xảy ra khi thanh toán mua ứng dụng.",
  "ERR_APP_NAME_IS_EMPTY": "Tên ứng dụng không được bỏ trống.",
  "ERR_APP_DESCRIPTION_IS_EMPTY": "Mô tả không được bỏ trống.",
  "ERR_APP_REDIRECT_URI_IS_INVALID": "Đường dẫn cài đặt không hợp lệ.",
  "ERR_APP_CONTENT_IS_EMPTY": "ERR_APP_CONTENT_IS_EMPTY",
  "ERR_APP_CONTENT_HAS_IMAGE_CODE": "Hình ảnh trong nội dung không hợp lệ.",
  "ERR_APP_ICON_IS_EMPTY": "Chưa có hình icon.",
  "ERR_APP_BANNER_IS_EMPTY": "Chưa có hình banner.",
  "ERR_APP_PRICE_DOES_NOT_EXIST": "Ứng dụng chưa có giá.",
  "ERR_APP_PRICE_MUST_HAVE_VALUE": "Giá duyệt phải lớn hơn 0.",
  "ERR_APP_PRICE_PLAN_NAME_MUST_HAVE_VALUE": "Vui lòng nhập tên gói.",
  "ERR_APP_PRICE_PLAN_DESC_MUST_HAVE_VALUE": "Vui lòng nhập mô tả gói.",
  "ERR_APP_PRICE_IS_NOT_NEW": "Chưa có giá mới được đề xuất.",
  "ERR_APP_PRICE_IS_NOT_PENDING": "Chưa có giá nào đang chờ duyệt.",
  "ERR_APP_PRICE_IS_NOT_CHANGE": "Giá không thay đổi.",
  "ERR_APP_PRICE_IS_APPROVED": "Giá này hiện đang được duyệt.",
  "ERR_APP_PRICE_CREATE_FAILED": "Không tạo được giá hoặc ứng dụng.",
  "ERR_APP_PRICE_UPDATE_FAILED": "Không cập nhật được giá.",
  "ERR_APP_PRODUCT_VARIANT_CREATE_FAILED": "Không tạo được variant hoặc product.",
  "ERR_APP_PRODUCT_PLAN_CREATE_FAILED": "Không tạo được plan hoặc product.",
  "ERR_APP_FLOW_ACTION_DOES_NOT_EXIST": "Hành động không tồn tại.",
  "ERR_APP_FLOW_ACTION_NAME_IS_EMPTY": "Tên hành động không được bỏ trống.",
  "ERR_APP_FLOW_ACTION_NAME_IS_DUPLICATE": "Tên hành động bị trùng.",
  "ERR_APP_FLOW_ACTION_POST_URI_IS_EMPTY": "Đường dẫn hành động không được bỏ trống.",
  "ERR_APP_FLOW_ACTION_POST_URI_IS_INVALID": "Đường dẫn hành động không hợp lệ.",
  "ERR_APP_FLOW_ACTION_FIELD_DOES_NOT_EXIST": "Thuộc tính hành động không tồn tại.",
  "ERR_APP_FLOW_ACTION_FIELD_NAME_IS_EMPTY": "Tên thuộc tính hành động không được bỏ trống.",
  "ERR_APP_FLOW_ACTION_FIELD_DATA_TYPE_IS_EMPTY": "Kiểu dữ liệu của thuộc tính hành động không được bỏ trống.",
  "ERR_APP_FLOW_ACTION_FIELD_VALUE_IS_EMPTY": "Giá trị của thuộc tính hành động không được bỏ trống.",
  "ERR_APP_FLOW_TRIGGER_DOES_NOT_EXIST": "Kích hoạt không tồn tại.",
  "ERR_APP_FLOW_TRIGGER_NAME_IS_EMPTY": "Tên kích hoạt không được bỏ trống.",
  "ERR_APP_FLOW_TRIGGER_NAME_IS_DUPLICATE": "Tên kích hoạt bị trùng.",
  "ERR_APP_FLOW_TRIGGER_POST_URI_IS_EMPTY": "Đường dẫn kích hoạtg không được bỏ trống.",
  "ERR_APP_FLOW_TRIGGER_POST_URI_IS_INVALID": "Đường dẫn kích hoạtg không hợp lệ.",
  "ERR_APP_FLOW_TRIGGER_FIELD_DOES_NOT_EXIST": "Thuộc tính kích hoạt không tồn tại.",
  "ERR_APP_FLOW_TRIGGER_FIELD_NAME_IS_EMPTY": "Tên thuộc tính kích hoạt không được bỏ trống.",
  "ERR_APP_FLOW_TRIGGER_FIELD_DATA_TYPE_IS_EMPTY": "Kiểu dữ liệu của thuộc tính kích hoạt không được bỏ trống.",
  "ERR_APP_FLOW_TRIGGER_FIELD_VALUE_IS_EMPTY": "Giá trị của thuộc tính kích hoạt không được bỏ trống.",
  "ERR_APP_UPDATE_STATUS_FAILED": "Trạng thái phê duyệt app không hợp lệ.",
  "ERR_APP_UPDATE_STATUS_REJECT_NOT_REASON": "Vui lòng nhập lý do từ chối phê duyệt app",
  "ERR_THEME_UPDATE_STATUS_REJECT_NOT_REASON": "Vui lòng nhập lý do từ chối phê duyệt theme",
  "ERR_APP_PRICE_STATUS_FAILED": "Trạng thái phê duyệt giá không hợp lệ.",
  "ERR_APP_PRICE_DEFAULT_PLAN_FAILED": "Gói mặc định đang không hợp lệ.",
  "ERR_APP_PRICE_UPDATE_STATUS_REJECT_NOT_REASON": "Vui lòng nhập lý do từ chối phê duyệt giá.",
  "ERR_THEME_PRICE_UPDATE_STATUS_REJECT_NOT_REASON": "Vui lòng nhập lý do từ chối phê duyệt giá.",
  "ERR_APP_UPDATE_REASONS_FOR_REJECT_120_CHARACTERS": "Lý do từ chối không được lớn hơn 120 ký tự",
  "ERR_THEME_UPDATE_STATUS_FAILED":"Trạng thái phê duyệt theme không hợp lệ",
  "ERR_DATA_INVALID":"Dữ liệu không hợp lệ",
  "ERR_PERMISSION_INVALID":"Bạn không có quyền để thực hiện",
  "plan is not exist": "Shop plan không tìm thấy",
  
  "Clothing" : "Thời trang",
  "Momandbaby" : "Mẹ và bé",
  "Technology" : "Công nghệ / Phụ kiện",
  "Furniture" : "Nội ngoại thất",
  "Food" : "Thực phẩm tươi sống",
  "Other" : "Khác",
  "Cosmetics" : "Mỹ phẩm / Làm đẹp",
  "Services" : "Dịch vụ",
  "Restaurant" : "Nhà hàng",
  "Gift" : "Quà tặng / Sở thích",
  "Coffee" : "Cafe",
  "Unknow" : "Tôi chưa biết",
  "ElectricMachine" : "Điện máy",
  "Motorcycle" : "Xe cộ / Máy móc / Vật tư / Linh kiện",
  "Books" : "Sách / Văn phòng phẩm",
  "Supermarket" : "Siêu thị",
  "Pharmacy" : "Nhà thuốc",
  "UnknowBusiness" : "Tôi chưa biết kinh doanh gì",
  "ERR_FUNCTION_EXIST": "Quyền này đã tồn tại.",
  "ERR_FUNCTION_NOT_EXIST": "Quyền này không tồn tại.",
  "ERR_FUNCTION_SYSTEM": "Đây là quyền của hệ thống.",
  "ERR_FUNCTION_NAME_CAN_NOT_BE_BLANK": "Tên quyền không được bỏ trống.",
  "ERR_FUNCTION_USED": "Quyền đang được sử dụng."
}

