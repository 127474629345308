// import thunk from 'redux-thunk'
// import { connectRouter, routerMiddleware } from 'connected-react-router'
import { applyMiddleware, combineReducers, compose, createStore } from '@reduxjs/toolkit'

import AuthReducer from '../screens/auth/services/auth_slice'
import DataSearchReducer from '../components/data_search/data_search_slice'
import DataFilterReducer from '../components/data_filter/data_filter_slice'
import DataListReducer from '../components/data_list/data_list_slice'
import DataSaveReducer from '../components/data_save/data_save_slice'
import TabReducer from '../components/tab/tab_slice'
import TabItemReducer from '../components/tab/tab-item/tab_item_slice'
import PopupReducer from '../components/popup/popup_slice'
import MenuReducer from '../layouts/left-menu/left_menu_slice'

// // The top-level state object
// export interface ApplicationState {
//     counter: Counter.CounterState | undefined
//     weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined
// }

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    auth: AuthReducer,
    dataSearch: DataSearchReducer,
    dataFilter: DataFilterReducer,
    dataList: DataListReducer,
    dataSave: DataSaveReducer,
    tab: TabReducer,
    tabItem: TabItemReducer,
    popup: PopupReducer,
    menu: MenuReducer
}

// // This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// // correctly typed to match your store.
// export interface AppThunkAction<TAction> {
//     (dispatch: (action: TAction) => void, getState: () => ApplicationState): void
// }

export function configureStore() {
    // const middleware = [
    //     thunk,
    //     routerMiddleware(history)
    // ]

    const rootReducer = combineReducers({
        ...reducers,
        // router: connectRouter(history)
    })

    const enhancers = []
    const windowIfDefined = typeof window === 'undefined' ? null : window as any // eslint-disable-line @typescript-eslint/no-explicit-any
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__())
    }

    return createStore(
        rootReducer,
        compose(
            // applyMiddleware(...middleware), 
            ...enhancers
        )
    )
}
