import { NavLink, useNavigate } from "react-router-dom";
import * as HrvComponents from '@haravan/react-components';

import "./link.css";
import { Status } from "../status/status";

export const Detail = ({ to, children = "", className = "" }) => {
  return<NavLink className={`${className} bold`} to={to}>{children || to} </NavLink>;
};

export const DetailThemeInstall = ({ to = "", children = "", className = "",isLink = false ,isPublish = false}) => {
  return <div className="card-theme">
    <div className="card-theme-install">{isLink ? <NavLink className={`${className} bold`} to={to}>{children || to} </NavLink> :
    children}</div>
    {isPublish && <div ><Status text="Theme chính" /></div>}
  </div>;
};

export const DetailTransaction = ({ children = "", className = "" }) => {
  return <a className={`${className} bold`} >
    {children}
  </a>;
};

export const DetailShopplan = ({ children = "", className = "" }) => {
  return <a className={`${className} bold`} >
    {children}
  </a>;
};

export const DetailPartnerShop = ({ to, children = "", className = "" }) => {
  const navigate = () => {
    window.location.href = to;
  }
  return <a className={`${className} bold`} onClick={() => navigate()}>
    {children || to}
  </a>;
};

export const DetailShop = ({ to, isDeleted, children = "", className = "" }) => {
  return (isDeleted == true ? <div className="is-deleted">
      <NavLink className={`${className} bold`} to={to}>{children || to} </NavLink></div>
    : <NavLink className={`${className} bold`} to={to}>{children || to} </NavLink>)
};

export const Link = ({ to, children = null }) => (
  <a className="link" href={to} target="_blank">
    {children || to || ''}
  </a>
);

export const Email = ({ to, children = "" }) => <a href={`mailto:${to}`}>{children || to}</a>;

export const Phone = ({ to, children = "" }) => <a href={`tel:${to}`}>{children || to}</a>;
