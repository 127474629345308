import React from 'react';
import * as HrvComponents from '@haravan/react-components';

import './tree_view_child.css';
import { Svg } from '../../components';

export interface INested {
    code: string,
    id: any,
    name: boolean,
    isSelected?: boolean,
    parentId?: any,
    disable?: boolean
}
export interface TreeViewChildItem {
    datas: INested[],
    values?: string[],
    onChange?: Function,
    blacklist?: string[]
    isRefeshNode?: boolean,
    isgetAll?: boolean,
    showDetail?: boolean,
    onClickDetail?: Function
}

export interface TreeViewChildProps {
    datas: INested[],
    values: string[]
}

export class TreeViewChild extends React.Component<TreeViewChildItem, TreeViewChildProps> {
    isCheckingParent: boolean;
    constructor(props) {
        super(props);
        this.state = {
            datas: props.datas || [] as INested[],
            values: props.values || [] as string[],
        }
        this.isCheckingParent = false;
    }

    componentDidMount() {
        let { datas } = this.state;

        if (this.props.isRefeshNode) {
            datas.map(a => this.refeshNode(a))
        }

        datas.map(node => this.checkedAll(node));

        this.setState({ datas: datas })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.datas !== nextProps.datas) {
            if (nextProps.datas) {
                let result = nextProps.datas;
                result?.map(node => this.checkedAll(node));
                this.setState({ datas: result })
            }
        }
        if (this.props.values !== nextProps.values) {
            this.setState({ values: nextProps.values }, () => {
                let result = nextProps.datas;
                result?.map(node => this.checkedAll(node));
                this.setState({ datas: result })
            })
        }
    }

    public render(this) {
        let { datas } = this.state;
        if (datas.length <= 0) {
            return;
        }
        return <div className='treeview role-detail'>
            {this.renderNode(datas, null, 1)}
        </div>
    }


    private renderNode(listNode, curParentId, childLevel) {
        let { onClickDetail, showDetail } = this.props

        return (
            <div>
                {listNode.map((node) => (node.parentId == curParentId &&
                    <div className={node.parentId == null ? "treecheckbox-node-container-parent " : "treecheckbox-node-container" + childLevel}>
                        <div className='one-node'>
                            {node.parentId != null && <Svg className='menu-item-icon' type='CornerDownRight' size={15} />}
                            <div className='node-funciton d-flex'>
                                <HrvComponents.InputCheckbox
                                    className='function-3'
                                    checked={node.disable ? false : node.isSelected}
                                    onChange={(checked) => this.onChange(listNode, node, checked, false)}
                                    disabled={node?.disable ?? false}>{node.name}</HrvComponents.InputCheckbox>
                                {!node?.disable && showDetail && onClickDetail && <div className='cursor-pointer ml5' onClick={() => onClickDetail(node, childLevel + 1)}><HrvComponents.Icon size={10} style={{ color: "#246deb" }} type='pencil' theme='solid' /></div>}
                            </div>
                        </div>
                        {this.getFirst(listNode, node) != null && this.renderNode(listNode, node.id, childLevel + 1)}
                    </div>
                ))}
            </div>
        )

    }
    private getFirst(listNode, item) {
        return listNode.find(x => x.parentId == item.id)
    }

    private subscribeNode(listNode, curNode, isCheck, isCheckParent) {
        if (isCheckParent) {
            listNode.map(node => {
                if (node.id == curNode.parentId) {
                    node.isSelected = isCheck;
                    listNode.map(o => {
                        if (o.id == node.parentId)
                            o.isSelected = isCheck;
                    })
                }
                if (node.id == curNode.id) {
                    node.isSelected = isCheck;
                }
            })
        } else {
            if (!this.isCheckingParent) {
                listNode.map(node => {
                    if (node.parentId == curNode.id) {
                        node.isSelected = isCheck;
                        listNode.map(o => {
                            if (o.parentId == node.id)
                                o.isSelected = isCheck;
                        })
                    }
                    if (node.id == curNode.id) {
                        node.isSelected = isCheck;
                    }
                })
            }

            if (isCheck && curNode.parentId) {
                this.isCheckingParent = true;
                this.subscribeNode(listNode, curNode, isCheck, true);
                this.isCheckingParent = false;
            }
        }

    }

    private async onChange(listNode, curNode, isCheck, isCheckParent) {
        await this.subscribeNode(listNode, curNode, isCheck, isCheckParent);
        this.setState({ datas: listNode });
        if (this.props.onChange) this.props.onChange(listNode);
    }

    private checkedAll(node: INested) {

    }

    private refeshNode(node: INested) {

    }
}