import { Textarea } from "@haravan/react-components";
import { useState } from "react";
import { InputDebounce, StatusSelector } from "../../../components";
import { STATUS, STATUS_THEME_NAME } from '../../../components/status/status';

import './index.css';

export const ThemePrice = ({ price, onChangePrice, onChangeStatus, onChangeReason=console.log }) => {
  const [isRejected, setRejection] = useState(price?.status === STATUS_THEME_NAME.Rejected);
  const [status_Price, setStatus_Price] = useState(price?.status);
  const [bool_reason, setBool_reason] =  useState(price?.rejectionReason != null && price?.rejectionReason != "");
  
  const changePrice = (value) => {
    onChangePrice?.(value);
  }

  const changeStatus = (value) => {
    setRejection(value === STATUS_THEME_NAME.Rejected)
    onChangeStatus?.(value)
  }

  const changeReason = (value) => {
    onChangeReason?.(value);
  }

  return (
  <div className="theme-price-detail">
    <div className="theme-price-row">
      <div className="full-width flex right">
        <StatusSelector onSelect={changeStatus} disableSelect={status_Price == STATUS_THEME_NAME.Approved || status_Price == STATUS_THEME_NAME.Rejected}
         listStatus={STATUS} value={price.status} />
      </div>
    </div>
    {
      isRejected &&
      <div className="mt10res">
        <div className="mb5">{isRejected && "Lý do từ chối:"}</div>
        <Textarea value={price.rejectionReason ?? ''} readOnly = {bool_reason && status_Price == STATUS_THEME_NAME.Rejected} onChange={changeReason} />
      </div>
    }
    <div className="theme-price-row">
      <div className="mt10">
        <div className='mb-md'>Giá đề nghị:</div>
        <InputDebounce readOnly type="currency" value={price?.cost || 0} />
      </div>
      <div className="mt10">
        <div className='mb-md'>Giá duyệt:</div>
        <InputDebounce type="currency" value={price?.costApproved || 0} readOnly={status_Price != STATUS_THEME_NAME.ApprovalWaiting} onChange={changePrice} />
      </div>
    </div>
  </div>)
}