import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { T } from '../../../../../components'
import { Button, Selection, DropdownCustom, Notification, InputDatetimePicker } from '@haravan/react-components'
import { BillingRepository, ShopplanRepository } from '../../../../../repositories'
import { closePopup } from '../../../../../components/popup/popup_slice'
import './index.css'
import { Moment } from 'moment'

export function UpdareSubscription() {
  const dispatch = useDispatch()
  const { shopId } = useParams()
  const [listShopPlans, setListShopPlans] = useState([])
  const [searchName, setSearchName] = useState("")
  const [statusShopPlan, setStatusShopPlan] = useState([])
  const [disabledInputDatetime, setDisabledInputDatetime] = useState(false)
  const [shopPlansCode, setShopPlansCode] = useState(null)
  const [expired_date, setExpired_date] = useState()

  useEffect(() => {
    fetchShopPlans()
  }, [searchName])

  const fetchShopPlans = async () => {
    const response = await ShopplanRepository.GetAllShopPlansActived({ freeText: searchName });
    setListShopPlans(response);
  };

  const save = () => {
    if (statusShopPlan.length <= 0) {
      Notification.error({ message: 'Vui lòng trạng thái', duration: 2 })
      return
    }

    if (!shopPlansCode && statusShopPlan[0].code != 'canceled') {
      Notification.error({ message: 'Vui lòng gói Shopplan', duration: 2 })
      return
    }

    if (!expired_date && statusShopPlan[0].code != 'canceled') {
      Notification.error({ message: 'Vui lòng chọn ngày hết hạn', duration: 2 })
      return
    }

    var obj = {
      expired_date: expired_date ? (expired_date as Moment).toDate() : new Date(),
      plan_code: shopPlansCode ?? 'none',
      status: statusShopPlan[0].code,
      org_id: parseInt(shopId)
    };

    BillingRepository.UpdateSubscription(obj)
      .then(rs => {
        if (rs?.errors) Notification.error({ message: T(rs.errors[0]), duration: 3 })
        else {
          Notification.success({ message: 'Cập nhật shop plan thành công', duration: 2 })
          dispatch(closePopup())
        }
      })
      .catch(err => Notification.error({ message: 'Có lỗi xảy ra', duration: 2 }))
  }

  return (
    <div className='shop-user'>
      <div className='class-subscription'>
        <span>Trạng thái</span>
        <DropdownCustom
          placeHolder='Chọn trạng thái'
          listValue={statusShopPlan}
          listData={constStatusShopPlan}
          keyId='code'
          keyName='name'
          onChangeItems={(items) => {
            if (items[0].code == 'canceled') {
              setDisabledInputDatetime(true);
              setShopPlansCode(null);
            } else setDisabledInputDatetime(false)
            setStatusShopPlan(items)
          }}
          isHideSearch
          isHideIconRemove
        />
      </div>
      <div className={disabledInputDatetime ? 'display-none' : 'class-subscription'}>
        <span>Shop Plan:</span>
        <Selection showSearch onChange={setShopPlansCode} onSearch={(value) => setSearchName(value)} placeholder='Chọn Shop Plan'>
          {listShopPlans.map(opt => <Selection.Option key={opt.code} value={opt.code}>{opt.name}</Selection.Option>)}
        </Selection>
      </div>
      <div className={disabledInputDatetime ? 'display-none' : 'class-subscription'}>
        <span>Thời gian hết hạn:</span>
        <InputDatetimePicker minDate={new Date()} onChange={(startDate) => setExpired_date(startDate)} />
      </div>
      <div className='shop-user-btn'>
        <Button status='default' onClick={() => dispatch(closePopup())}>
          Hủy
        </Button>
        <Button status='primary' onClick={save}>
          Cập nhật
        </Button>
      </div>
    </div>
  )
}

const constStatusShopPlan = [
  {
    code: 'trialing',
    name: 'Trialing',
  },
  {
    code: 'active',
    name: 'Active',
  },
  {
    code: 'canceled',
    name: 'Canceled',
  }
]