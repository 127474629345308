import { useEffect } from 'react'
import { signoutRedirect, signoutRedirectCallback } from './services/auth_service'

export const SignoutOidc = () => {
  useEffect(() => {
    signoutRedirect().then(signoutRedirectCallback)
  }, [])
  return (
    <div>
      Redirecting...
    </div>
  )
}
