import React, { useEffect, useState, useRef } from "react";
import { InputDatetimePicker, Selection, FormatDateTimeCustom, SelectionMutilple } from "@haravan/react-components";
import { OptionProps } from "@haravan/react-components/lib/Selection/Selection";
import { IDictionary } from "../../global/utils";
import { IFilterType } from "../../global/utils/functions";
import * as Env from '../../global/utils/env';

interface ValueFilterProps {
  type: IFilterType;
  keyObject: IDictionary;
  onChange: (filters: FilterSearchField[]) => void;
}

export interface FilterSearchField {
  displayName: string;
  fieldName: string;
  hasOptions: boolean;
  isNummeric: boolean;
  nummericOperator: string;
  nummericValue: string;
  optionValue: string;
  optionValueValue: any;
  rawValueValue: any;
}

export interface FilterSearchPage {
  currentPage: number;
  pageSize: number;
}
export interface IRequestExportModel {
  searchModel: FilterSearchModel;
  fileType: 'csv' | 'excel2003';
  exportByType: 'current_filter' | 'current_page' | 'all';
}
export interface FilterSearchModel {
  freeText: string;
  fields: FilterSearchField[];
  sortFieldName: string;
  sortType: string;
  page: FilterSearchPage;
}
export const FilterDataDefault = (type: IFilterType) => {
  switch (type) {
    case IFilterType.shop:
      return { key: "shopplanid", name: "cửa hàng" } as IDictionary;
    case IFilterType.transactions:
      return { key: "transactiontype", name: "giao dịch" } as IDictionary;
    case IFilterType.payouts:
      return { key: "createdDate", name: "công nợ" } as IDictionary;
  }
};
const FilterShopDataOptions = [
  { key: "shopplanid", name: "Shop Plan", group: IFilterType.shop.toString() },
  { key: "registerdate", name: "Ngày đăng ký", group: IFilterType.shop.toString() },
  { key: "shopplanexpired", name: "Ngày hết hạn", group: IFilterType.shop.toString() },
];
const FilterPayoutOptions = [
  { key: "createdDate", name: "Ngày phát sinh", group: IFilterType.payouts.toString() }
];

const FilterTransactionOptions = [
  { key: "transactiontype", name: "Loại giao dịch", group: IFilterType.transactions.toString() },
  { key: "transactiondate", name: "Ngày phát sinh giao dịch", group: IFilterType.transactions.toString() },
  { key: "transactioncompletiondate", name: "Ngày hoàn tất giao dịch", group: IFilterType.transactions.toString() },
  { key: "transactionstatus", name: "Trạng thái giao dịch", group: IFilterType.transactions.toString() },
];

export const FilterData = (type: IFilterType) => {
  let rs = [] as OptionProps[];
  switch (type) {
    case IFilterType.shop:
      FilterShopDataOptions.filter((o) => o.group == type.toString()).map((o) => {
        rs.push({ value: o.key, children: o.name, key: o.key } as OptionProps);
      });
      break;
    case IFilterType.transactions:
      FilterTransactionOptions.filter((o) => type.toString()).map((o) => {
        rs.push({ value: o.key, children: o.name, key: o.key } as OptionProps);
      });
      break;
    case IFilterType.payouts:
      FilterPayoutOptions.filter((o) => type.toString()).map((o) => {
        rs.push({ value: o.key, children: o.name, key: o.key } as OptionProps);
      });
      break;
  }
  return rs;
};


export const GetDisplayName = (type: IFilterType, key: string): string => {
  let array = [];
  switch (type) {
    case IFilterType.shop:
      array = FilterShopDataOptions.filter((o) => o.group == type.toString());
      break;
    case IFilterType.transactions:
      array = FilterTransactionOptions.filter((o) => o.group == type.toString());
      break;
    case IFilterType.payouts:
      array = FilterPayoutOptions.filter((o) => o.group == type.toString());
      break;
  }
  return array.find(o => o.key == key)?.name;;
};

export const SetFilterTags = (filters: FilterSearchField[], tags: IDictionary[]): IDictionary[] => {
  let tag = {} as IDictionary;
  var _groupBy = Env.groupBy(filters, "fieldName");
  Object.keys(_groupBy).forEach(function (opt) {
    let key = _groupBy[opt][0].fieldName;
    switch (key) {
      case "shopplanid":
        let shopplan = FilterShopDataOptions.find((o) => o.key == key);
        tag = {
          key: key,
          name: `${shopplan.name} là ${filters.find((o) => o.fieldName == key).nummericValue?.toUpperCase()}`,
        } as IDictionary;
        break;
      case "registerdate":
      case "shopplanexpired":
        let isRegister = key == "registerdate" ? true : false;
        let display = FilterShopDataOptions.find((o) => o.key == key);
        let start = filters.find((o) => o.optionValue == "After" && o.fieldName == key);
        let end = filters.find((o) => o.optionValue == "Before" && o.fieldName == key);
        let isDate = start.rawValueValue instanceof Date;
        tag = {
          key: key,
          name: `${display.name} từ ${FormatDateTimeCustom(isDate ? start.rawValueValue : start.rawValueValue.toDate(), isRegister ? "DD-MM-YYYY HH:mm:ss" : "DD-MM-YYYY")} đến ${FormatDateTimeCustom(isDate ? end.rawValueValue : end.rawValueValue.toDate(), isRegister ? "DD-MM-YYYY HH:mm:ss" : "DD-MM-YYYY")}`,
        } as IDictionary;
        break;
      case "createdDate":
        let displayPayout = FilterPayoutOptions.find((o) => o.key == key);
        let startPayout = filters.find((o) => o.optionValue == "After" && o.fieldName == key);
        let endPayout = filters.find((o) => o.optionValue == "Before" && o.fieldName == key);
        let isDatePayout = startPayout.rawValueValue instanceof Date;
        tag = {
          key: key,
          name: `${displayPayout.name} từ ${FormatDateTimeCustom(isDatePayout ? startPayout.rawValueValue : startPayout.rawValueValue.toDate(), "DD-MM-YYYY")} đến ${FormatDateTimeCustom(isDatePayout ? endPayout.rawValueValue : endPayout.rawValueValue.toDate(), "DD-MM-YYYY")}`,
        } as IDictionary;
        break;
      case "transactiontype":
      case "transactionstatus":
        let transaction = FilterTransactionOptions.find((o) => o.key == key);
        tag = {
          key: key,
          name: `${transaction.name} là ${filters.find((o) => o.fieldName == key).nummericValue?.toUpperCase()}`,
        } as IDictionary;
        break;
      case "transactiondate":
      case "transactioncompletiondate":
        let displaytr = FilterTransactionOptions.find((o) => o.key == key);
        let starttr = filters.find((o) => o.optionValue == "After" && o.fieldName == key);
        let endtr = filters.find((o) => o.optionValue == "Before" && o.fieldName == key);
        let isDatetr = starttr.rawValueValue instanceof Date;
        tag = {
          key: key,
          name: `${displaytr.name} từ ${FormatDateTimeCustom(isDatetr ? starttr.rawValueValue : starttr.rawValueValue.toDate(), "DD-MM-YYYY HH:mm:ss")} đến ${FormatDateTimeCustom(isDatetr ? endtr.rawValueValue : endtr.rawValueValue.toDate(), "DD-MM-YYYY HH:mm:ss")}`,
        } as IDictionary;
        break;
    }

    tags = tags.filter((item) => {
      return item.key != tag.key;
    });
    if (key == "shopplanid" || key == "transactiontype" || key == "transactionstatus") {
      if (filters[0].nummericValue?.toUpperCase() != "") {
        tags.push(tag);
      }
    } else {
      tags.push(tag);
    }
  });
  return tags;
};

export const SetFilters = (values: FilterSearchField[], filter: FilterSearchModel): FilterSearchModel => {
  values.map((o) => {
    switch (o.fieldName) {
      case "shopplanid":
      case "transactiontype":
      case "transactionstatus":
        var exist = filter.fields.find((item) => o.fieldName == item.fieldName && o.nummericOperator == item.nummericOperator);
        if (exist) exist.nummericValue = o.nummericValue;
        else filter.fields.push(o);
        break;
      case "registerdate":
      case "shopplanexpired":
      case "transactiondate":
      case "createdDate":
      case "transactioncompletiondate":
        var exist = filter.fields.find((item) => o.fieldName == item.fieldName && o.optionValue == item.optionValue);
        if (exist) exist.optionValueValue = o.optionValueValue;
        else filter.fields.push(o);
        break;
    }
  });

  return filter;
};
export const ConvertToFilterObject = (type, fieldName, operator, value): FilterSearchField => {
  let displayName = GetDisplayName(type, fieldName);
  let obj = {
    fieldName: fieldName,
    displayName: displayName,
  } as FilterSearchField;
  if (operator == "IN") {
    obj.isNummeric = true;
    obj.nummericOperator = operator;
    obj.nummericValue = value;
  } else if (operator == "After" || operator == "Before") {
    var _date = new Date(value);
    obj.hasOptions = true;
    obj.optionValue = operator;
    obj.optionValueValue = FormatDateTimeCustom(_date, "YYYY-MM-DD HH:mm");
    obj.rawValueValue = _date;
  }

  return obj;
}
export const RenderValueFilter = (props: ValueFilterProps) => {
  const ref_element = useRef(null);
  const { onChange, keyObject, type } = props;
  const [startDatePicker, setStartDatePicker] = useState(null);
  const [endDatePicker, setEndDatePicker] = useState(null);
  useEffect(() => {
    !endDatePicker && setEndDatePicker(Date())
    if (!startDatePicker) {
      let now = new Date();
      let firstDayMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      setStartDatePicker(firstDayMonth);
    }
    let obj = {} as FilterSearchField;
    onChange([obj]);
  }, []);
  useEffect(() => {
    if (keyObject.key == 'registerdate' ||
      keyObject.key == "transactiondate" ||
      keyObject.key == "createdDate" ||
      keyObject.key == "transactioncompletiondate") {
      let now = new Date();
      let firstDayMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      onChangeDate(firstDayMonth, now);
    }
    else onChangeValue([]);
  }, [keyObject]);
  const onChangeDate = (startDate, endDate) => {
    let objStart = {
      fieldName: keyObject.key,
      displayName: keyObject.name,
      hasOptions: true,
      optionValue: "After",
      optionValueValue: FormatDateTimeCustom(startDate instanceof Date ? startDate : startDate.toDate(), "YYYY-MM-DD HH:mm"),
      rawValueValue: startDate
    } as FilterSearchField;

    let objEnd = {
      fieldName: keyObject.key,
      displayName: keyObject.name,
      hasOptions: true,
      optionValue: "Before",
      optionValueValue: FormatDateTimeCustom(endDate instanceof Date ? endDate : endDate.toDate(), "YYYY-MM-DD HH:mm"),
      rawValueValue: endDate
    } as FilterSearchField;
    onChange([objStart, objEnd]);
  };
  const onChangeValue = (value) => {
    if (keyObject.key == 'shopplanid'
      || keyObject.key == 'transactiontype') {
      let obj = {
        fieldName: keyObject.key,
        displayName: keyObject.name,
        isNummeric: true,
        nummericOperator: "IN",
        nummericValue: `${value.join(', ')}`,
      } as FilterSearchField;

      onChange([obj]);
    } if (keyObject.key == 'transactionstatus') {
      let obj = {
        fieldName: keyObject.key,
        displayName: keyObject.name,
        isNummeric: true,
        nummericOperator: "IN",
        nummericValue: `${value}`,
      } as FilterSearchField;

      onChange([obj]);
    }
  };
  const renderFilter = () => {
    switch (keyObject.key) {
      case "shopplanid":
        let options_shopplanid = [
          { value: 'scale', children: "Scale", key: 'scale' },
          { value: 'grow', children: "Grow", key: 'grow' },
          { value: 'pro', children: "Pro", key: 'pro' },
          { value: 'business web', children: "Business web", key: 'business web' },
          { value: 'standard', children: "Standard", key: 'standard' },
          { value: "advanced", children: "Advanced", key: 'advanced' },
          { value: "affiliate plan", children: "Affiliate plan", key: 'affiliate plan' },
          { value: 'trai nghiem', children: "Trải nghiệm", key: 'trai nghiem' },
        ] as OptionProps[];
        return (
          <div ref={ref_element} style={{ position: "relative" }}>
            <SelectionMutilple placeholder="Vui lòng chọn" onChange={onChangeValue} getPopupContainer={() => ref_element.current}>
              {options_shopplanid.map((opt) => (
                <Selection.Option {...opt}></Selection.Option>
              ))}
            </SelectionMutilple>
          </div>
        );
      case "registerdate":
        return (
          <div>
            <InputDatetimePicker
              key={'registerdate'}
              placement="left"
              singleDatePicker={false}
              startDate={startDatePicker}
              endDate={endDatePicker}
              maxDate={endDatePicker}
              onChange={(startDate, endDate) => {
                onChangeDate(startDate, endDate);
              }}
            />
          </div>
        );
      case "shopplanexpired":
        return (
          <div>
            <InputDatetimePicker
              key={'shopplanexpired'}
              placement="right"
              locale={{ format: "DD/MM/YYYY" }}
              singleDatePicker={false}
              timePicker={false}
              onChange={(startDate, endDate) => {
                onChangeDate(startDate, endDate);
              }}
            />
          </div>
        );
      case "transactiontype":
        let options_transactiontype = [
          { value: "shop plan", children: "Shop plan", key: 'shop plan' },
          { value: "Shop feature", children: "Shop feature", key: 'shop feature' },
          { value: "Package", children: "Package", key: 'package' },
          { value: "app", children: "App", key: 'app' },
          { value: "customize", children: "Customize", key: 'customize' },
          { value: "theme", children: "Theme", key: 'theme' },
          { value: "dịch vụ khác", children: "Dịch vụ khác", key: 'dịch vụ khác' }
        ] as OptionProps[];
        return (
          <div ref={ref_element} style={{ position: "relative" }}>
            <SelectionMutilple placeholder=" Vui lòng chọn" onChange={onChangeValue} getPopupContainer={() => ref_element.current}>
              {options_transactiontype.map((opt) => (
                <Selection.Option {...opt}></Selection.Option>
              ))}
            </SelectionMutilple>
          </div>
        );
      case "transactiondate":
      case "transactioncompletiondate":
      case "createdDate":
        return (
          <InputDatetimePicker
            key={'transactiondate'}
            placement="left"
            singleDatePicker={false}
            startDate={startDatePicker}
            endDate={endDatePicker}
            maxDate={endDatePicker}
            onChange={(startDate, endDate) => {
              onChangeDate(startDate, endDate);
            }}
          />
        );
      case "transactionstatus":
        let option = [
          { value: "đã thanh toán", children: "Đã thanh toán", key: 'đã thanh toán' },
          { value: "đã hủy", children: "Đã hủy", key: 'đã hủy' },
        ] as OptionProps[];
        return (
          <div ref={ref_element} style={{ position: "relative" }}>
            <Selection placeholder=" Vui lòng chọn" onChange={onChangeValue} getPopupContainer={() => ref_element.current}>
              {option.map((opt) => (
                <Selection.Option {...opt}></Selection.Option >
              ))}
            </Selection>
          </div>
        );
    }
  };

  return renderFilter();
};
