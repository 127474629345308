import { createSlice } from '@reduxjs/toolkit';
import { setBearerToken } from '../../../repositories/api_helper'

const initialState = {
  user: null,
  isLoadingUser: true
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    storeUser: (state, action) => {
      const user = action.payload
      setBearerToken(user.access_token)
      state.user = user
      state.isLoadingUser = false
    },
    loadingUser: (state, action) => { 
      state.isLoadingUser = action.payload
    },
    userSignedOut: (state, action) => { 
      state.user = null
      state.isLoadingUser = false
    },
    storeUserError: (state) => { },
    userExpired: (state, action) => { },
  }, 
  // // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: builder => builder.addCase(
  //   textSearchChange, 
  //   (state, action) => { 
  //     if (action.payload) state.isReload = true
  //   }
  // )
});                         

export const { 
  loadingUser, storeUser, userSignedOut, userExpired, storeUserError
} = authSlice.actions;

export const selectUser = state => state.auth.user
export const selectLoadingUser = state => state.auth.isLoadingUser;

export default authSlice.reducer;
