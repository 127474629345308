import * as React from 'react';
import { Button, Modal } from '@haravan/react-components';
import { ButtonStatus } from '@haravan/react-components/lib/Button';

import './action_confirm.css';

export const ActionConfirm = (props) => {
    const { key, isConfirm, headerText, bodyText, cancelText, acceptText,
        children, childrenNeedConfirm, handleOnCancel, handleOnAccept, dangerAccept } = props

    const acceptStatus = `${dangerAccept ? "danger" : "primary"}` as ButtonStatus

    return isConfirm
        ? <Modal key={key} size='sm'
            headerContent={<div className="app-content-modal-footer"> {headerText} </div>}
            bodyContent={<span className="app-content-modal-body"> {bodyText} </span>}
            isBtnClose={false}
            footerContent={
                <div className="app-content-modal-footer">
                    <Button status="default" onClick={handleOnCancel}> {cancelText} </Button>
                    <Button status={acceptStatus} onClick={handleOnAccept}> {acceptText} </Button>
                </div>}
        >
            {childrenNeedConfirm || children}
        </Modal>
        : <div key={key} onClick={e => handleOnAccept && handleOnAccept()}>
            {children}
        </div>
}
