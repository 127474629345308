import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { InputCheckbox, Loading, Pagination, Table } from '@haravan/react-components'
import { checkRow, load, loadComplete, selectLoading, selectCheckedValues, unCheckRow, clearCheck } from './data_list_slice'
import { useTextSearch } from '../data_search/data_search'
import { DataFilter, DataFilterProps, useFilter } from '../data_filter/data_filter'
import { isEqual } from 'lodash';

import './data_list.css'

interface SortedHeader {
  index: number
  order: 'Asc' | 'Desc' | 'None'
}

export interface DataListHeader {
  className?: string
  name?: any,
  filter?: DataFilterProps
}

interface ResponseData {
  rawData?: any[]
  list: any[]
  total: number
}

interface DataListProps {
  usedBy?: string
  title?: string
  headers?: DataListHeader[] | any[]
  loadData: (filter: any, page: number, pageSize: number, search: string) => Promise<ResponseData>
  hasCheckbox?: boolean
  rowClassName?: string
  cellClassName?: string
  valueField?: string,
  header_normal?: boolean,
  page_size?: number
}

export const DataList = (props: DataListProps) => {
  const { usedBy = '', loadData, hasCheckbox, rowClassName, cellClassName, valueField = 'id', header_normal, page_size } = props

  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(page_size ?? 20);
  const [search, setSearch] = useState(null);
  const [isSearch, setIsSearch] = useState(false);


  const headers = props.headers ?? Object.keys(data[0]).map(k => ({ name: k, className: '', filter: null }))
  const firstItem = data?.list?.[0]
  const itemKeys = firstItem ? Object.keys(firstItem) : []

  const textSearch = useTextSearch(usedBy)
  const filter = useFilter(`${usedBy}-filter`)
  const isLoading = useSelector(selectLoading)
  const checkedValues = useSelector(selectCheckedValues)

  const dispatch = useDispatch()

  useEffect(
    () => () => {
      dispatch(clearCheck()) //uncheck all when unmount
    }, [])

  useEffect(() => {
    if (textSearch != null) {
      setIsSearch(!isEqual(search, textSearch));
      setSearch(textSearch);
    }
    setTimeout(() => {
      dispatch(load())
    }, 250);
  }, [textSearch, page, pageSize])

  useEffect(() => {
    dispatch(load())
  }, [filter])

  useEffect(() => {
    var pag = page;
    if (isSearch){
      pag = 1;
      setPage(1);
    } 
    isLoading && loadData(pag, pageSize, textSearch?.trim?.(), filter)
      .then(response => {
        dispatch(loadComplete())
        if (!response.list) response.list = []
        return response
      })
      .then(response => setData({ ...response }))
  }, [isLoading])

  //#region [shapphire] event handlers
  const check = (index, checked) => {
    if (checked) dispatch(checkRow(data.rawData[index][valueField]))
    else dispatch(unCheckRow(data.rawData[index][valueField]))
  }

  const checkAll = checked => {
    data?.rawData?.map(item => {
      if (checked) dispatch(checkRow(item[valueField]))
      else dispatch(unCheckRow(item[valueField]))
    })
  }

  const isCheckAll = () => {
    let result = true
    if (data?.rawData?.length > 0 && data?.rawData?.forEach) {
      data.rawData.forEach?.(item => {
        result = checkedValues?.includes(item[valueField])
      })
      return result
    }
    return false
  }

  const changePage = (current, page_size) => {
    if (pageSize != page_size || page != current) {
      setPage(current);
      setPageSize(page_size);
    }
  };

  //#endregion event handlers

  //#region JSX
  const tableHeader = (
    <tr className='data-list-header'>
      {hasCheckbox && (
        <th className={`data-list-header-cell checkbox-cell`}>
          <InputCheckbox checked={isCheckAll()} onChange={checkAll} />
        </th>
      )}
      {headers.map((header, index) => (
        <th key={header.name} className={`data-list-header-cell ${header_normal ? 'data-list-header-cell-font-normal' : ''} ${typeof firstItem?.[itemKeys[index]]} ${header.className}`}>
          <div className='data-list-value'>
            <span className='data-list-sort-header'>
              <span className='data-list-sort-label'>{header.name ?? ''}</span>
            </span>
            {header.filter && <DataFilter usedBy={`${usedBy}-filter`} {...header.filter} />}
          </div>
        </th>
      ))}
    </tr>
  )

  const tableBody = data?.list?.map((item, index) => (
    <tr key={index} className={`data-list-row ${rowClassName}`}>
      {hasCheckbox && (
        <td className={`data-list-cell ${cellClassName}`}>
          <InputCheckbox checked={checkedValues.includes(data.rawData[index][valueField])} onChange={checked => check(index, checked)} />
        </td>
      )}
      {Object.keys(item).map((k, i) => (
        <td key={k} className={`data-list-cell ${typeof item[k]} ${headers[i]?.className}`}>
          {item[k]}
        </td>
      ))}
    </tr>
  ))

  const loadingBody = (
    <tr className='loading-table'>
      <td colSpan={headers.length + 1}>
        <Loading size='thumb' />
      </td>
    </tr>
  )

  const noDataBody = (
    <tr className='loading-table'>
      <td colSpan={headers.length + 1}>
        <span className='flex center italic'>Không có dữ liệu</span>
      </td>
    </tr>
  )

  //#endregion JSX parts

  return (
    <div className='data-list-wrapper'>
      <div className='data-table-wrapper'>
        <Table renderTableHeader={tableHeader} renderTableBody={isLoading ? loadingBody : data?.list?.length > 0 ? tableBody : noDataBody} />
        <Pagination total={data?.total} current={page} onChange={changePage} pageSize={pageSize} onChangePageSize={changePage} />
      </div>
    </div>
  )
}
