import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type PopupType = 'info' | 'warning' | 'error'

interface ErrorPopup {
  error_code?: number,
  onAccept?: any,
  onCancel?: any
}

interface EditorPopup {
  title: any,
  content: any,
  acceptText?: string,
  onAccept?: any,
  cancelText?: string,
  onCancel?: any,
  closeOnClickOutside?: boolean,
  class_name?: string,
}

const initialState = {
  isOpen: false,
  type: 'info' as PopupType,
  error_code: 0,
  width: '20vw',
  class_name: '',
  content: {
    headerText: 'Thông báo',
    bodyContent: '',
    renderAcceptBtn: undefined,
    acceptText: 'Đồng ý',
    dangerAccept: false,
    onAccept: console.log,
    cancelText: 'Hủy',
    onCancel: console.log,
    dimOutside: false,
    closeOnClickOutside: true
  }
};

export const dataSearchSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    openErrorPopup: (state, action: PayloadAction<ErrorPopup>) => {
      state.isOpen = true;
      const { error_code, onAccept, onCancel } = action.payload;
      switch (error_code) {
        case 401:
          state.content = {
            ...state.content,
            headerText: 'Chưa xác thực quyền truy cập',
            bodyContent: 'Quyền truy cập chưa được xác thực hoặc phiên làm việc đã hết hạn.',
            acceptText: 'Tải lại',
            onAccept: () => window.location.href = window.location.href,
          };
          break;
      }
    },
    openEditorPopup: (state, action: PayloadAction<EditorPopup>) => {
      state.isOpen = true;
      const { title, content, acceptText, onAccept, cancelText, onCancel, closeOnClickOutside, class_name } = action.payload;
      state.content = {
        ...state.content,
        headerText: title,
        bodyContent: content,
        acceptText,
        onAccept,
        cancelText,
        onCancel,
        closeOnClickOutside,
      };

      state.class_name = class_name;
    },
    closePopup: (state) => {
      state.isOpen = false
    },
    acceptButton: (state, action) => {
      state.content = {
        ...state.content,
        renderAcceptBtn: action.payload.renderAcceptBtn
      }
    }
  }
});

export const { openErrorPopup, openEditorPopup, closePopup, acceptButton } = dataSearchSlice.actions;

export const selectPopupStatus = state => state.popup.isOpen;
export const selectPopupType = state => state.popup.type;
export const selectPopupContent = state => state.popup.content;
export const selectPopupClassName = state => state.popup.class_name;

export default dataSearchSlice.reducer;
