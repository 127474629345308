import * as ApiHelpers from './api_helper';

const apiRoute = 'shops';

export const StoreRepository = {
  GetStore: id => ApiHelpers.api_call_get(`${apiRoute}/${id}`),
  Filter: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/filter`, model);
  },
  Export: (model) => {
    return ApiHelpers.api_call_post(`${apiRoute}/export`, model);
  },
  ImportKA: (file: Blob) => {
    return ApiHelpers.api_upload_file(file,file['name'],`${apiRoute}/import/ka`);
  },
  GetTransactionsByShopId: (id, query = '', before = 0, after = 0, limit = 20) => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${id}/transactions`, {
      query: query,
      before: before,
      after: after,
      limit: limit
    });

    return ApiHelpers.api_call_get(path);
  },
  GetLogAppInstallByShopId: (id, page = 1, pageSize = 20, search = '') => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${id}/apps/install/history`, {
      page,
      pageSize,
      search
    });

    return ApiHelpers.api_call_get(path);
  },
  GetAppsInstallByShopId: (id) => {
    let path = (`${apiRoute}/${id}/apps/install`);
    return ApiHelpers.api_call_get(path);
  },
  GetThemesInstallByShopId: (id, page = 1, pageSize = 20, search = '') => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${id}/themes/install`, {
      page,
      pageSize,
      search
    });

    return ApiHelpers.api_call_get(path);
  },
  GetCustomersHistoryByShopId: (orgId, page = 1, pageSize = 10, prefix = "omni") => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${orgId}/customers/history`, {
      page: page,
      pageSize: pageSize,
      prefix: prefix
    });

    return ApiHelpers.api_call_get(path);
  },
  GetTiketsByShopId: (orgId, page = 1, pageSize = 10) => {
    let path = ApiHelpers.GetApiUrl(`${apiRoute}/${orgId}/tickets`, {
      page: page,
      pageSize: pageSize
    });

    return ApiHelpers.api_call_get(path);
  }
};
