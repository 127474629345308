import { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { dataChanged, getLoading, selectHasChanged } from "./data_save_slice";
import { Button } from "@haravan/react-components";

import './data_save.css';

export const DataSave = ({ wrapperClass = '', onSaveData = () => { } }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(getLoading);
    const hasChanged = useSelector(selectHasChanged);
    const hasChangedClass = hasChanged ? 'has-changed' : '';

    useEffect(() => () => { dispatch(dataChanged({ hasChanged: false, data: {} })); }, [])

    return <div className={`data-save-wrapper has-changed ${wrapperClass}`}>
        <div className='hrv-btn-group--item-1'></div>
        <div className='hrv-btn-group--item'><Button onClick={onSaveData} status='primary' loading={isLoading}>Lưu</Button></div>
    </div>

}
