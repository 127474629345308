import * as HrvComponents from '@haravan/react-components';
import "./index.css";
import { useEffect, useState } from 'react'
import { PermissionRepository } from '../../../repositories';
import { TreeViewChild } from '../../../components'
import { useParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";

export function RoleDetail() {
    const { roleId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const [listFunction, setListFunction] = useState(null);
    const [rolePATCH, setRolePATCH] = useState(null);
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalUpdateRole, setOpenModalUpdateRole] = useState(false);
    const [listUser, setListUser] = useState(null);
    const [modalUser, setModalUser] = useState(false);
    const [userId, setUserId] = useState(null);
    const param_flow = searchParams.get("flow");
    const [flowRequestAccsess, setFlowRequestAccsess] = useState(false);
    const [parentIdRoleRequestAccsess, setparentIdRoleRequestAccsess] = useState(null);
    const request_accsess = 3;
    useEffect(() => {
        var is_request_accsess = param_flow == "all" || param_flow == "request_accsess"
        setFlowRequestAccsess(is_request_accsess);
        if (!roleId) getListFunction(is_request_accsess);
        else {
            getDetailRole();
            getListUser();
        }
    }, [roleId]);

    const getListFunction = (is_request_accsess) => {
        PermissionRepository.GetAllFunctions(is_request_accsess ? "request_accsess" : null).then(rs => {
            if (is_request_accsess) {
                rs.data[0].disable = true;
                setparentIdRoleRequestAccsess(rs.data[0].id);
            }
            setListFunction(rs.data);

            return rs.data;
        });
    }
    const getDetailRole = () => {
        roleId && PermissionRepository.GetDetail(roleId).then(rs => {
            if (rs.data) {
                setRole(rs.data);
                if (rs.data.displayType == request_accsess) {
                    setFlowRequestAccsess(true);
                    rs.data.function[0].disable = true;
                    setparentIdRoleRequestAccsess(rs.data.function[0].id)
                }

                setListFunction(rs.data.function);
            }
        })
    }

    const getListUser = () => {
        roleId && PermissionRepository.GetListUserByRoleId(roleId).then(rs => {
            if (rs.data) {
                setListUser(rs.data);
            }
        })
    }

    const removeUserRole = (userId) => {
        setModalUser(true);
        setUserId(userId);
    }

    const deleteUserRole = (userId) => {
        setLoading(true)
        roleId && PermissionRepository.DeleteUserRole(roleId, userId).then(rs => {
            if (rs.data && !rs.has_error) {
                setLoading(false);
                setModalUser(false);
                HrvComponents.Notification.success({
                    message: "Xóa user thành công"
                });
                setListUser(rs.data);
            } else {
                setLoading(false);
                setModalUser(false);
                return HrvComponents.Notification.error({
                    message: "Xóa user thất bại"
                });
            }
        })
    }

    const saveRole = () => {
        if (!roleId) addRole();
        else updateRole();
    }

    const updateRole = () => {
        if (role.name == null || role.name == "" || role.name.trim() == "") {
            return HrvComponents.Notification.error({
                message: "Tên nhóm không được để trống"
            });
        }

        if (role.name.length > 100) {
            return HrvComponents.Notification.error({
                message: "Tên nhóm không được vượt quá 100 ký tự"
            });
        }

        if (!role?.function || role.function.findIndex(o => o.isSelected) < 0) {
            return HrvComponents.Notification.error({
                message: "Bạn chưa chọn quyền"
            });
        }

        setLoading(true);
        PermissionRepository.PutRole(role).then(rs => {
            if (rs.data && !rs.has_error) {
                setLoading(false);
                return HrvComponents.Notification.success({
                    message: "Cập nhật nhóm thành công"
                });
            } else {
                setLoading(false);
                return HrvComponents.Notification.error({
                    message: "Cập nhật nhóm thất bại"
                });
            }
        })
    }

    const addRole = () => {
        if (role?.name == null || role?.name == "" || !role?.name || role.name.trim() == "") {
            return HrvComponents.Notification.error({
                message: "Tên nhóm không được để trống"
            });
        }

        if (role.name.length > 100) {
            return HrvComponents.Notification.error({
                message: "Tên nhóm không được vượt quá 100 ký tự"
            });
        }

        if (!role?.function || role.function.findIndex(o => o.isSelected) < 0) {
            return HrvComponents.Notification.error({
                message: "Bạn chưa chọn quyền"
            });
        }

        if (flowRequestAccsess) {
            role['displayType'] = request_accsess //ForRequestAccess
        }
        setLoading(true);
        PermissionRepository.AddRole(role).then(rs => {
            if (rs.data && !rs.has_error) {
                HrvComponents.Notification.success({
                    message: "Thêm nhóm thành công"
                });
                setLoading(false);
                setTimeout(() => {
                    window.location.replace("/roles/" + rs.data.id);
                }, 1000);
            } else {
                setLoading(false);
                return HrvComponents.Notification.error({
                    message: "Thêm nhóm thất bại"
                });
            }
        })
    }

    const deleteRole = () => {
        setOpenModal(false);
        setLoading(true);
        roleId && PermissionRepository.DeleteRole(roleId).then(rs => {
            if (rs.data && !rs.has_error) {
                HrvComponents.Notification.success({
                    message: "Xóa nhóm thành công"
                });
                setLoading(false);
                setOpenModal(false);
                setTimeout(() => {
                    window.location.replace("/roles");
                }, 1000);
            } else {
                setLoading(false);
                setOpenModal(false);
                return HrvComponents.Notification.error({
                    message: "Xóa nhóm thất bại"
                });
            }
        })
    }

    const updateFunction = () => {
        if (rolePATCH?.name == null || rolePATCH?.name == "" || !rolePATCH?.name || rolePATCH.name.trim() == "") {
            return HrvComponents.Notification.error({
                message: "Tên quyền không được để trống"
            });
        }

        if (rolePATCH.name.length > 100) {
            return HrvComponents.Notification.error({
                message: "Tên quyền không được vượt quá 100 ký tự"
            });
        }

        if (rolePATCH?.code == null || rolePATCH?.code == "" || !rolePATCH?.code || rolePATCH.code.trim() == "") {
            return HrvComponents.Notification.error({
                message: "Code không được để trống"
            });
        }

        if (rolePATCH.code.length > 100) {
            return HrvComponents.Notification.error({
                message: "Code không được vượt quá 100 ký tự"
            });
        }

        setOpenModalUpdateRole(false);
        setLoading(true);
        if (flowRequestAccsess)
            rolePATCH['parentId'] = parentIdRoleRequestAccsess;
        PermissionRepository.UpdateFunction(rolePATCH).then(rs => {
            if (rs.data && !rs.has_error) {
                if (roleId) getDetailRole();
                else getListFunction(flowRequestAccsess);
                HrvComponents.Notification.success({
                    message: "Cập nhật quyền thành công"
                });
            }
            setLoading(false);
        })
    }

    const deleteFunction = () => {
        setOpenModalUpdateRole(false);
        setLoading(true);
        rolePATCH?.id > 0 && PermissionRepository.DeleteFunction(rolePATCH.id).then(rs => {
            if (!rs.has_error) {
                if (roleId) getDetailRole();
                else getListFunction(flowRequestAccsess);
                HrvComponents.Notification.success({
                    message: "Xóa quyền thành công"
                });
            }
            setLoading(false);
        })
    }

    const onChange = (values) => {
        setRole({ ...role, function: values })
    }

    const openModalDetailUpdateRole = (value, childLevel = 0) => {
        if (value)
            value['childLevel'] = childLevel;

        setRolePATCH(value);
        setOpenModalUpdateRole(true);
    }

    const exitModalDetailUpdateRole = () => {
        setRolePATCH(null);
        setOpenModalUpdateRole(false);
    }

    const addRoleWithParentId = () => {
        var parentId = rolePATCH.id;
        var parentName = rolePATCH.name;
        setRolePATCH({ ...rolePATCH, id: 0, name: null, code: null, parentId: parentId, parentName: parentName })
    }

    const renderModalUpdateRole = () => {
        return (
            <Modal className='modalUpdateRole' show={openModalUpdateRole} onHide={() => exitModalDetailUpdateRole()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div>{rolePATCH?.name ? 'Quyền ' + rolePATCH?.name : 'Thêm quyền'}</div>
                        {!flowRequestAccsess && rolePATCH?.id > 0 && rolePATCH?.childLevel < 4 && <HrvComponents.Button className="m0" status="link" onClick={() => addRoleWithParentId()}>
                            Thêm quyền con
                        </HrvComponents.Button>}
                        {rolePATCH?.parentName && <h6>Quyền con của <b>{rolePATCH?.parentName}</b></h6>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label>Tên quyền</label>
                        <HrvComponents.Input value={rolePATCH?.name ?? ''} onChange={value => setRolePATCH({ ...rolePATCH, name: value })} className='input-name-metadata' />
                        <label>Code</label>
                        <div className='ps-relative'>
                            <HrvComponents.Input value={rolePATCH?.code ?? ''} onChange={value => setRolePATCH({ ...rolePATCH, code: value })} className='input-name-metadata' />
                            {rolePATCH?.isSystem && <div className='i_role_system'><HrvComponents.Tooltip title="Quyền hệ thống"><HrvComponents.Icon style={{ color: "#22c993" }} type='checkCircle' theme='solid' /></HrvComponents.Tooltip></div>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <HrvComponents.Button status='default' onClick={() => exitModalDetailUpdateRole()}>
                        Hủy
                    </HrvComponents.Button>
                    <HrvComponents.Button status="primary" onClick={() => updateFunction()}>
                        Cập nhật
                    </HrvComponents.Button>
                    {!rolePATCH?.isSystem && rolePATCH?.id > 0 &&
                        <HrvComponents.Button status="danger" onClick={() => deleteFunction()}>
                            Xoá
                        </HrvComponents.Button>}
                </Modal.Footer>
            </Modal>
        )
    }

    const renderDelele = () => {
        return (
            <Modal show={openModal} onHide={() => setOpenModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Xóa nhóm</Modal.Title>
                </Modal.Header>
                <Modal.Body>Nhóm đã xóa sẽ không thể phục hồi lại, bạn có muốn tiếp tục?</Modal.Body>
                <Modal.Footer>
                    <HrvComponents.Button status='danger' onClick={() => setOpenModal(false)}>
                        Hủy
                    </HrvComponents.Button>
                    <HrvComponents.Button status="primary" onClick={deleteRole}>
                        Xác nhận
                    </HrvComponents.Button>
                </Modal.Footer>
            </Modal>
        );
    }


    const renderDeleleUser = () => {
        return (
            <Modal show={modalUser} onHide={() => setModalUser(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Xóa tài khoản trong nhóm</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc muốn xoá tài khoản này?</Modal.Body>
                <Modal.Footer>
                    <HrvComponents.Button status='danger' onClick={() => setModalUser(false)}>
                        Hủy
                    </HrvComponents.Button>
                    <HrvComponents.Button status="primary" onClick={() => deleteUserRole(userId)}>
                        Xác nhận
                    </HrvComponents.Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div className='full-screen'>
            {loading ? <HrvComponents.Loading className='hrv-loading-page' /> :
                (<div className="management-screen">
                    <div className="col-md-6">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">Thông tin nhóm</h3>
                            </div>
                            <div className="panel-body scroll-visible">
                                <div className="col-md-3 text-right border-right">
                                    <label>Tên nhóm</label>
                                </div>
                                <div className="col-md-9 text-left">
                                    <HrvComponents.Input value={role?.name} onChange={value => setRole({ ...role, name: value })} className='input-name-group' />
                                </div>
                            </div>
                        </div>
                        {listUser?.length > 0 && <div className='listview-content'>
                            <HrvComponents.Table
                                renderTableHeader={
                                    <tr>
                                        <th><b>Email</b></th>
                                        <th><b>Họ Tên</b></th>
                                        <th></th>
                                    </tr>
                                }
                                renderTableBody={
                                    listUser.map((item) => {
                                        return <tr className='tb-list-user'>
                                            <td className='item-user' >{item.email}</td>
                                            <td className='item-user' >{item.fullName}</td>
                                            <td className='btn-delete' >
                                                <HrvComponents.Tooltip title='Xóa'>
                                                    <HrvComponents.Button onClick={() => removeUserRole(item.id)} status='link'>  <HrvComponents.Icon type='times' /></HrvComponents.Button>
                                                </HrvComponents.Tooltip>
                                            </td>
                                        </tr>
                                    })
                                } />
                            {renderDeleleUser()}
                        </div>}

                    </div>
                    <div className="col-md-6">
                        <div className="panel panel-default">
                            <div className="panel-heading ps-relative">
                                <h3 className="panel-title">Phân quyền</h3>
                                {flowRequestAccsess && <div className='i_role_system cursor-pointer'><HrvComponents.Tooltip title="Thêm quyền"><HrvComponents.Icon size={20} type='plusCircle' style={{ color: "#246deb" }} onClick={() => openModalDetailUpdateRole(null)} theme='solid' /></HrvComponents.Tooltip></div>}
                            </div>
                            <div>
                                <TreeViewChild datas={listFunction} onChange={onChange} showDetail={flowRequestAccsess} onClickDetail={openModalDetailUpdateRole}></TreeViewChild>
                                {renderModalUpdateRole()}
                            </div>
                        </div>
                    </div>
                </div>)}
            <div className="form-group">
                <div className="footer-form">
                    {listFunction != null && !loading &&
                        <div className="pull-left">
                            {roleId && <HrvComponents.Button onClick={() => setOpenModal(true)} status='danger'>Xóa</HrvComponents.Button>}
                            <HrvComponents.Button onClick={saveRole} status='primary'> Lưu</HrvComponents.Button>
                            {renderDelele()}
                        </div>
                    }
                </div>
            </div>
        </div>

    )
};
