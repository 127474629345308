import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { InputCheckbox, Loading, Table, Badges, Icon, Pagination, RadioGroup, FileUpload } from "@haravan/react-components";
import { checkRow, load, loadComplete, selectLoading, selectCheckedValues, unCheckRow, clearCheck } from "./data_list_slice";
import {setLoading, getLoading } from "../data_save/data_save_slice";
import { useTextSearch } from "../data_search/data_search";
import { DataFilter } from "../data_filter/data_filter";
import { FilterBar, SearchBar } from "../filter-bar";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { IDictionary } from "../../global/utils";
import { FilterSearchField, FilterSearchModel, SetFilterTags, FilterSearchPage, SetFilters, IRequestExportModel, ConvertToFilterObject, GetDisplayName } from "../filter-bar/filter-data";
import "./index.css";
import { isEqual, IFilterType, removeAccents } from "../../global/utils/functions";
import { debounce } from "lodash";

export interface TableFilterHeader {
  className?: string;
  name?: any;
}

interface ResponseData {
  rawData?: any[];
  list: any[];
  total: number;
}

interface TableFilterProps {
  type: IFilterType;
  usedBy?: string;
  title?: string;
  headers?: TableFilterHeader[] | any[];
  loadData: (filter: FilterSearchModel) => Promise<ResponseData>;
  hasCheckbox?: boolean;
  rowClassName?: string;
  cellClassName?: string;
  valueField?: string;
  placeholder: string;
  canExportData: boolean;
  canFilter: boolean;
  processExportData?: Function,
  processImportKA?: Function,
  processelExportEctronicBill?: Function,
}

export const TableFilter = (props: TableFilterProps) => {
  const { type, usedBy = "", loadData, hasCheckbox, rowClassName, cellClassName, valueField = "id", placeholder, processExportData, processImportKA, processelExportEctronicBill, canFilter, canExportData } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const param_page = parseInt(searchParams.get("page"))
  const param_limit = parseInt(searchParams.get("limit"));
  const param_query = searchParams.get("query");
  const param_filter = searchParams.get("filter");
  const navigate = useNavigate();
  const location = useLocation();
  const filter_defaut = {
    page: { currentPage: param_page ?? 1, pageSize: param_limit ?? 20 } as FilterSearchPage,
    freeText: param_query,
    fields: [] as FilterSearchField[],
  } as FilterSearchModel;
  const [data, setData] = useState(null);
  const [exportByType, setExportByType] = useState('current_filter');
  const [fileExportType, setFileExportType] = useState('csv');
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModalImportShopKA, setShowModalImportShopKA] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalElectronicBill, setShowModalElectronicBill] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [filterTags, setFilterTags] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(filter_defaut);
  const [textSearch, setTextSearch] = useState('');
  const [filesImportKA, setFilesImportKA] = useState(null);
  
  const [propsFilter, setPropsFilter] = useState([] as FilterSearchField[]);
  const headers = props.headers ?? Object.keys(data[0]).map((k) => ({ name: k, className: "" }));
  const firstItem = data?.list?.[0];
  const itemKeys = firstItem ? Object.keys(firstItem) : [];
  const useSearch = useTextSearch(usedBy);
  const isLoading = useSelector(selectLoading);
  const checkedValues = useSelector(selectCheckedValues);
  const dispatch = useDispatch();
  useEffect(
    () => {
      if (param_query)
        setTextSearch(param_query);

      if (param_filter) {
        var _objects = param_filter.split("|").filter(o => o != "");
        var _props_filters = [] as FilterSearchField[];
        _objects.map(o => {
          var _slipt = o.split("::");
          _props_filters.push(ConvertToFilterObject(type, _slipt[0], _slipt[1], _slipt[2]));
        });
        changeFilter(_props_filters);
        setFirstLoaded(true);

      }
      if (param_page)
        setCurrentPage(param_page);
      if (param_limit)
        setPageSize(param_limit);

      dispatch(clearCheck());
    },
    []
  );

  useEffect(() => {
    if (location.search.length <= 0) {
      setCurrentFilter(filter_defaut);
      setTextSearch('');
      setFilterTags([]);
    }
  }, [location])

  useEffect(() => {
    setFilesImportKA(null);
  }, [showModalImportShopKA])

  useEffect(() => {
    var params = [
      { key: "page", value: currentFilter.page.currentPage ? currentFilter.page.currentPage.toString() : '1' },
      { key: "limit", value: currentFilter.page.pageSize ? currentFilter.page.pageSize.toString() : '20' }
    ] as IDictionary[];
    if (currentFilter.freeText)
      params.push({ key: "query", value: currentFilter.freeText } as IDictionary);
    if (currentFilter.fields.length > 0) {
      var _l_filter = "";
      currentFilter.fields.forEach((o, index) => {
        if (o.nummericValue) _l_filter += `${o.fieldName}::${o.nummericOperator}::${o.nummericValue}|`
        else _l_filter += `${o.fieldName}::${o.optionValue}::${new Date(o.optionValueValue)}|`;
      })

      params.push({ key: "filter", value: _l_filter } as IDictionary)
    }

    if(Number.isNaN(currentFilter.page.currentPage) || Number.isNaN(currentFilter.page.pageSize)){
      const newFilter = { ...currentFilter };
      newFilter.page = { currentPage: 1, pageSize: 20 } as FilterSearchPage;
      setCurrentFilter(newFilter);
    }
    setQuery(params);
    dispatch(load());
  }, [useSearch, currentFilter, filterTags]);

  useEffect(() => {
    isLoading &&
      loadData(currentFilter)
        .then((response) => {
          dispatch(loadComplete());
          if (!response.list) response.list = [];
          setTotalItems(response.total);
          return response;
        })
        .then((response) => setData({ ...response }));
  }, [isLoading]);

  const check = (index, checked) => {
    if (checked) dispatch(checkRow(data.rawData[index][valueField]));
    else dispatch(unCheckRow(data.rawData[index][valueField]));
  };

  const checkAll = (checked) => {
    data?.rawData?.map((item) => {
      if (checked) dispatch(checkRow(item[valueField]));
      else dispatch(unCheckRow(item[valueField]));
    });
  };

  const isCheckAll = () => {
    let result = true;
    if (data?.rawData?.length > 0 && data?.rawData?.forEach) {
      data.rawData.forEach?.((item) => {
        result = checkedValues?.includes(item[valueField]);
      });
      return result;
    }
    return false;
  };

  const tableHeader = (
    <tr className="data-list-header">
      {hasCheckbox && (
        <th className={`data-list-header-cell checkbox-cell`}>
          <InputCheckbox checked={isCheckAll()} onChange={checkAll} />
        </th>
      )}
      {headers.map((header, index) => (
        <th key={header.name} className={`data-list-header-cell ${typeof firstItem?.[itemKeys[index]]} ${header.className}`}>
          <div className="data-list-value">
            <span className="data-list-sort-header">
              <span className="data-list-sort-label">{header.name ?? ""}</span>
            </span>
            {header.filter && <DataFilter usedBy={`${usedBy}-filter`} {...header.filter} />}
          </div>
        </th>
      ))}
    </tr>
  );

  const tableBody = data?.list?.map((item, index) => (
    <tr key={index} className={`data-list-row ${rowClassName}`}>
      {hasCheckbox && (
        <td className={`data-list-cell ${cellClassName}`}>
          <InputCheckbox checked={checkedValues.includes(data.rawData[index][valueField])} onChange={(checked) => check(index, checked)} />
        </td>
      )}
      {Object.keys(item).map((k, i) => (
        <td key={k} className={`data-list-cell ${typeof item[k]} ${headers[i]?.className}`}>
          {item[k]}
        </td>
      ))}
    </tr>
  ));

  const loadingBody = (
    <tr className="loading-table">
      <td colSpan={headers.length + 1}>
        <Loading size="thumb" />
      </td>
    </tr>
  );

  const noDataBody = (
    <tr className="loading-table">
      <td colSpan={headers.length + 1}>
        <span className="flex center italic">Không có dữ liệu</span>
      </td>
    </tr>
  );

  const removeTagsFilter = (value, index) => {
    const newFilter = { ...currentFilter };
    newFilter.page = { currentPage: 1, pageSize: pageSize } as FilterSearchPage;
    let tags = filterTags.filter((o, i) => {
      return i != index;
    });
    newFilter.fields = newFilter.fields.filter((o) => {
      if (o.fieldName == "shopplanid" || o.fieldName == "transactiontype" || o.fieldName == "transactionstatus") {
        if (o.nummericValue != null && o.nummericValue != "") return o.fieldName != value.key;
      }
      else return o.fieldName != value.key;
    });
    setFilterTags(tags);
    setCurrentTags(tags);
    setCurrentFilter(newFilter);
    setCurrentPage(1);
  };
  const changePage = (current, page_size) => {
    if (pageSize != page_size)
      current = 1;
    const newFilter = { ...currentFilter };
    newFilter.page = { currentPage: current, pageSize: page_size } as FilterSearchPage;
    setCurrentFilter(newFilter);
    setCurrentPage(current);
    setPageSize(page_size);
  };
  const changeSearch = debounce((text) => {
    const newFilter = { ...currentFilter };
    newFilter.page = { currentPage: 1, pageSize: pageSize } as FilterSearchPage;
    newFilter.freeText = removeAccents(text.toLowerCase());
    setCurrentFilter(newFilter);
    setCurrentPage(1);
    setTextSearch(text);
  }, 750);

  const setQuery = (params: IDictionary[]) => {
    let searchParams = new URLSearchParams(location.search);
    params.forEach((pa) => searchParams.set(pa.key, pa.value));

    searchParams.forEach((p, key) => {
      var _this = params.find(o => o.key == key);
      if (!_this) searchParams.delete(key);
    });

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const changeFilter = (data: FilterSearchField[]) => {
    let tags = SetFilterTags(data, filterTags);
    var newFilter = SetFilters(data, { ...currentFilter });
    //newFilter.page = { currentPage: 1, pageSize: pageSize } as FilterSearchPage;
    newFilter.page = {
      currentPage: !firstLoaded && param_page ? param_page : 1,
      pageSize: !firstLoaded && param_limit ? param_limit : pageSize
    } as FilterSearchPage;



    newFilter.fields = newFilter.fields.filter((o) => {
      if (o.fieldName == "shopplanid" || o.fieldName == "transactiontype" || o.fieldName == "transactionstatus") {
        if (o.nummericValue != null && o.nummericValue != "") return o;
      }
      else return o;
    });

    let tagg = [];
    tags.map(o => {
      if (o?.key != null && o?.key != "")
        tagg.push(o)
    })

    if (tagg.length > 0 && !isEqual(tagg, currentTags)) {
      setCurrentFilter(newFilter);
      setFilterTags(tagg);
      setCurrentPage(1);
      setCurrentTags(tagg);
    }

    if (!firstLoaded) {
      setPageSize(param_limit)
      setCurrentPage(param_page)
    }
  };
  const exportDataByFilter = () => {
    let msg = {
      exportByType: exportByType,
      fileType: fileExportType,
      searchModel: currentFilter
    } as IRequestExportModel
    if (showModalExport) {
      processExportData(msg)
      setShowModalExport(false)
    }
    if (showModalElectronicBill) {
      processelExportEctronicBill(msg);
      setShowModalElectronicBill(false);
    }
  }
  
  const processImportKeyAccounts =async ()=> {
    dispatch(load());
    var rs = await processImportKA(filesImportKA);
    if(!rs.has_error) {
      setShowModalImportShopKA(false);
      dispatch(loadComplete());
    }
  }
  const renderExport = () => {
    return (processelExportEctronicBill || processExportData) && <div className='modal_export_button'>
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          Thao tác
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setShowModalExport(true)}>Xuất dữ liệu</Dropdown.Item>
          {processelExportEctronicBill && <Dropdown.Item onClick={() => setShowModalElectronicBill(true)}>Xuất theo mẫu hóa đơn</Dropdown.Item>}
          {usedBy=="shop-list"  && <Dropdown.Item onClick={() => setShowModalImportShopKA(true)}>Cập nhật KA</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
      {modalExport()}
      {modalElectronicBill()}
      {modalImportKA()}
    </div >
  }
  const modalExport = () => {
    return (
        processExportData && <Modal className="modal_export" show={showModalExport} onHide={() => setShowModalExport(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">Xuất dữ liệu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-section-group'>
            <b><label>Chọn xuất theo:</label></b>
            <p></p>
            <RadioGroup options={[
              { label: 'Theo bộ lọc hiện tại', value: 'current_filter' },
              { label: 'Trang hiện tại', value: 'current_page' }]} name='exportByType' onChange={(value) => setExportByType(value)} value={exportByType} />
            <p></p>
            <b><label>Chọn dạng file sẽ xuất:</label></b>
            <p></p>
            <RadioGroup options={[
              { label: 'Xuất file .csv (Comma Separated Values)', value: 'csv' },
              { label: 'Xuất file .xls (Excel)', value: 'excel2003' }]} onChange={(value) => setFileExportType(value)} name='fileExportType' value={fileExportType} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-cancel">
            <Button className="hrv-btn hrv-btn-default" onClick={() => setShowModalExport(false)}>Huỷ</Button>
          </div>
          <div className="btn-export">
            <Button className="hrv-btn hrv-btn-primary" onClick={exportDataByFilter}>Xuất dữ liệu</Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }

  const modalImportKA = () => {
    return (
        processImportKA && <Modal className="modal_export" show={showModalImportShopKA} onHide={() => {setShowModalImportShopKA(false); setFilesImportKA(null)}}>
          <Modal.Header closeButton>
            <Modal.Title className="h5">Nhập Key Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='modal-section-group'>
                <FileUpload onChange={(files)=> setFilesImportKA(files[0])} accept=".xlsx, .xls">
                  <Button className="hrv-btn hrv-btn-default">
                    <Icon type='upload'/>
                    <span className='hrv-btn-text'>Click to upload</span>
                  </Button>
                </FileUpload>
                <div className='mt10'>Nhập file .xls (Excel)</div>
                <div className='hrv-upload-list mt-3'>
                  {filesImportKA &&  <div className='hrv-upload-list-item'>
                      <div className='hrv-upload-list-item-info'>
                        <Icon type='attachFile' className='hrv-upload-list-item-icon-attach' size={10}/>
                        <span className='hrv-upload-list-item-name'>{filesImportKA.name}</span>
                      </div>
                    </div>
                  }
                </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-cancel">
              <Button className="hrv-btn hrv-btn-default" onClick={() => setShowModalImportShopKA(false)}>Huỷ</Button>
            </div>
            <div className="btn-export">
              <Button disabled={!filesImportKA && true} className="hrv-btn hrv-btn-primary" onClick={processImportKeyAccounts}> {!isLoading ? "Nhập dữ liệu" : <Loading className='hrv-loading-btn' size='pico' />}</Button>
            </div>
          </Modal.Footer>
        </Modal>
    )
  }
  const modalElectronicBill = () => {
    return (
        processelExportEctronicBill &&
        <Modal className="modal_export" show={showModalElectronicBill} onHide={() => setShowModalElectronicBill(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="h5">Xuất theo mẫu hóa đơn</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='modal-section-group'>
              <b><label>Chọn xuất theo:</label></b>
              <p></p>
              <RadioGroup options={[
                {label: 'Theo bộ lọc hiện tại', value: 'current_filter'},
                {label: 'Trang hiện tại', value: 'current_page'}]} name='exportByType' onChange={(value) => setExportByType(value)} value={exportByType} />
            <p></p>
            <b><label>Chọn dạng file sẽ xuất:</label></b>
            <p></p>
            <RadioGroup options={[
              { label: 'Xuất file .csv (Comma Separated Values)', value: 'csv' },
              { label: 'Xuất file .xls (Excel)', value: 'excel2003' }]} onChange={(value) => setFileExportType(value)} name='fileExportType' value={fileExportType} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-cancel">
            <Button className="hrv-btn hrv-btn-default" onClick={() => setShowModalElectronicBill(false)}>Huỷ</Button>
          </div>
          <div className="btn-export">
            <Button className="hrv-btn hrv-btn-primary" onClick={exportDataByFilter}>Xuất hóa đơn</Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <div className="data-list-wrapper">
      {canExportData && renderExport()}
      <div className="header-table">
        {canFilter && <div className="filter-bar">
          <FilterBar
            type={type}
            onChange={changeFilter}
          />
        </div>
        }
        <div className="search-bar">
          <SearchBar value={textSearch} className={!canFilter && 'text-search-wrapper-none-export'} onChange={changeSearch} placeholder={placeholder} />
        </div>
      </div>
      <div className="tag-filter">
        {filterTags.map((o, i) => {
          return (
            <Badges status="primary" key={i}>
              {o.name} <Icon className="cursor-pointer " type="times" theme="regular" onClick={() => removeTagsFilter(o, i)} />
            </Badges>
          );
        })}
      </div>
      <div className="data-table-wrapper">
        <Table renderTableHeader={tableHeader} renderTableBody={isLoading ? loadingBody : data?.list?.length > 0 ? tableBody : noDataBody} />
        <Pagination total={totalItems} current={currentPage} onChange={changePage} pageSize={pageSize} onChangePageSize={changePage} />
      </div>
    </div>
  );
};
