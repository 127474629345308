import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DataSearchProps {
  usedBy?: string;
  textSearch?: string;
}

const initialState = {};

// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const dataSearchSlice = createSlice({
  name: 'data-search',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    textSearchChange: (state, action: PayloadAction<DataSearchProps>) => {
      const { usedBy, textSearch } = action.payload;
      state[usedBy] = textSearch
    },
  },

  // // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = 'idle';
  //       state.value += action.payload;
  //     });
  // },
});

export const { textSearchChange } = dataSearchSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectText = state => usedBy => state.dataSearch[usedBy];

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default dataSearchSlice.reducer;
