import { ShopplanRepository } from '../../../repositories';
import { DataList, DataSearch, Detail } from '../../../components';

import './index.css';
const headers = [
  { name: 'Name', className: 'plans-name-col' },
  { name: 'Code', className: 'plans-cost-col' },
  { name: 'Description', className: 'plans-ref-col' }
];

export const ProductsList = () => {

  const fetchProducts = async (page, pageSize, search) => {
    const response = await ShopplanRepository.GetListProducts(page, pageSize, search);
    const list = response?.data?.data?.map(a => ({
      name: <Detail to={`/products/${a.id}`}>{a.name}</Detail>,
      code: <>{a.code}</>,
      description: <>{a.description}</>
    }));
    const rawData = response?.data?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  };

  return (
    <div className='shopplans-list'>
      <div className="empty-state-button">
        <a
          className="hrv-btn hrv-btn-primary"
          href='/products/new'>
          New Product
        </a>
      </div>
      <DataSearch className='mb-md' placeholder='Tìm kiếm theo id, tên, code' />
      <DataList headers={headers} loadData={fetchProducts} />
    </div>
  );
};
