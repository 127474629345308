import React, { useEffect, useRef, useState } from 'react';
import * as classnames from 'classnames';
import './svg.css';
import * as listIcon from './icon_list';

interface ICreateSVGProps {
  type: listIcon.IconType;
  className?: string;
  size: string | number;
  rotate?: number;
  style?: any;
  color?: string;
  onClick?: Function;
}

export const Svg = (props: ICreateSVGProps) => {
  let { type, className, size, rotate, style, color, onClick } = props;

  const svgWrapper = useRef(null);
  const [svgWrapperWidth, setSvgWrapperWidth] = useState(0)

  useEffect(() => {
    if(svgWrapper.current?.clientWidth != svgWrapper.current?.clientHeight)
      setSvgWrapperWidth(svgWrapper.current?.clientHeight)
  });

  const svgWrapperStyle = svgWrapperWidth ? { width: `${svgWrapperWidth}px` } : {}

  const sizeClass = size ? `svg-icon-size-${size}` : '';
  const rotateClass = rotate ? `svg-icon-rotate-${rotate}` : '';
  const classes = `svg-icon ${className} ${sizeClass} ${rotateClass}`;
  
  const SVGComponent: (props: React.SVGProps<SVGSVGElement>) => JSX.Element = listIcon[type];

  return SVGComponent && (
    <div ref={svgWrapper} className="svg-wrapper" style={svgWrapperStyle} onClick={e => onClick?.(e)}>
      <SVGComponent
        className={classes}
        style={style}
        width={size}
        height={size}
        color={color}
      />
    </div>
  );
};
