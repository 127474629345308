import * as React from 'react';

let env = {
    baseUrl: '/',
    layout: null
}

export const Env = env;
export const ProjectName = 'Haravan - Admin - ';

export const setLayout = (layout: React.Component) => {
    env.layout = layout;
}

export const getNewGuid = (): string => {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export const deepClone = (source) => {
    return JSON.parse(JSON.stringify(source))
}

export const checkEmail = (val) => {
    if (val.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        return true;
    } else {
        return false;
    }
}

export const checkUrl = (val) => {
    var regex = new RegExp("(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})");

    if (regex.test(val)) {
        return true;
    } else {
        return false;
    }
}

export const checkPhone = (val) => {
    if (val.match(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/)) {
        return true;
    } else {
        return false;
    }
}
export const validateField = (fieldName, value, name = '') => {
    let fieldValidationErrors = '';

    switch (fieldName) {
        case 'email':
            if (value.length == 0) {
                fieldValidationErrors = 'Vui lòng nhập thông tin email.';
            } else if (!checkEmail(value)) {
                fieldValidationErrors = 'Email không đúng định dạng. Vui lòng nhập lại.';
            }
            break;
        case 'phone':
            if (checkPhone(value) == false) {
                fieldValidationErrors = 'Số điện thoại không hợp lệ.';
            }
            break;
        case 'url':
            if (checkUrl(value) == false) {
                fieldValidationErrors = 'Url không hợp lệ.';
            }
            break;
        case 'password':
            if (value.length <= 6) {
                fieldValidationErrors = 'Mật khẩu quá ngắn.';
            }
            break;
        case 'text':
            if (value.length <= 1 || value.length >= 200) {
                fieldValidationErrors = name + ' không đúng định dạng. Vui lòng nhập lại.';
            }
            break;
        default:
            break;
    }
    if (fieldValidationErrors.length == 0) {
        return null;
    }
    return fieldValidationErrors;
}

interface IPaging {
    query: string,
    page: number,
    page_size: number,
    search: string
}

export const parsePaging = (search: string): IPaging => {
    let queries = new URLSearchParams(search);

    let page = null as number;
    let page_size = null as number;

    if (queries.get('page'))
        page = Number.parseInt(queries.get('page'))

    if (queries.get('page_size'))
        page_size = Number.parseInt(queries.get('page_size'))

    return {
        query: queries.get('query'),
        page: page,
        page_size: page_size,
        search: search || ''
    }
}

export const SplitName = (name: string, isfirstName: boolean): string => {

    if (name == undefined || name == null)
        return null;

    if (isfirstName)
        return name.split(' ').slice(0, -1).join(' ');
    else return name.split(' ').slice(-1).join(' ');
}

export const UrlFriendly = (str = ''): string => {
    var AccentsMap = [
        "aàảãáạăằẳẵắặâầẩẫấậ",
        "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
        "dđ", "DĐ",
        "eèẻẽéẹêềểễếệ",
        "EÈẺẼÉẸÊỀỂỄẾỆ",
        "iìỉĩíị",
        "IÌỈĨÍỊ",
        "oòỏõóọôồổỗốộơờởỡớợ",
        "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
        "uùủũúụưừửữứự",
        "UÙỦŨÚỤƯỪỬỮỨỰ",
        "yỳỷỹýỵ",
        "YỲỶỸÝỴ"
    ];

    str = str.trim().toLowerCase();

    for (var i = 0; i < AccentsMap.length; i++) {
        var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
        var char = AccentsMap[i][0];
        str = str.replace(re, char);
    }

    str = str.replace(/[^\w\s]/gi, '').replace(/ /g, "-");

    return str;
}


export const setCookie = (name , value, days = null) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
  
  export const getCookie = (name = ''): string => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  export const groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }), 
    {},
  );