import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  checkedValues: []
};

export const dataListSlice = createSlice({
  name: 'data-list',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    load: state => { state.isLoading = true },
    loadComplete: state => { state.isLoading = false },
    checkRow: (state, action) => {
      const existedIndex = state.checkedValues.findIndex(v => v === action.payload)
      if (existedIndex < 0) state.checkedValues.push(action.payload)
    },
    unCheckRow: (state, action) => {
      const existedIndex = state.checkedValues.findIndex(v => v === action.payload)
      if (existedIndex >= 0) state.checkedValues.splice(existedIndex,1)
    },
    clearCheck: state => {
      state.checkedValues = []
    }
  }, 
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: builder => builder.addCase(
  //     textSearchChange, 
  //     (state, action) => { 
  //       if (action.payload) state.isReload = true
  //     }
  // )
});                         

export const { load, loadComplete, checkRow, unCheckRow, clearCheck } = dataListSlice.actions;

export const selectLoading = state => state.dataList.isLoading;
export const selectCheckedValues = state => state.dataList.checkedValues;

export default dataListSlice.reducer;
