import * as React from 'react';
import { Error } from './error';

export class PermissionError extends React.Component<{}, {}> {
    public render() {
        return <Error
            title='Bạn không có quyền truy cập'
            content='Nội dung không tồn tại hoặc bạn không có quyền để xem. Vui lòng liên hệ người quản trị.'
        />
    }
}
