import React, { useEffect, useRef, useState } from 'react';
import './check_list.css';

type CheckType = 'checkbox' | 'radio';

interface ICheckListProps {
  className?: string;
  horizontal?: boolean;
  type?: CheckType;
  name?: string;
  listItem: ICheckListItem[];
  displayFieldName?: string;
  valueFieldName?: string;
  onCheckChange?: Function;
}

export interface ICheckListItem {
  id?: number | string;
  checked?: boolean;
  obj: any;
  text?: string;
  value?: any;
  invisible?: boolean; //custom display
}

export const CheckList = (props: ICheckListProps) => {
  const mounted = useRef(false);

  const [state, setState] = useState({
    // listItem: [],
    listChecked: [],
    listUnchecked: [],
    effectedItem: null,
  });

  const { listItem, className, type = 'checkbox', name, horizontal = false } = props;

  useEffect(() => {
    mounted.current = true;
  }, [])

  useEffect(() => {
    returnResult();
  }, [state])

  const renderCheckListItem = (item: ICheckListItem, index: number) => {
    return !item.invisible && <div key={`${name}-${type}-${index}`} className="checklist-item" onClick={() => changeCheck(type, item)}>
      <input
        key={index}
        type={type}
        name={name || type}
        checked={item.checked}
        onChange={() => changeCheck(type, item)}
      />
      <div>{item.text}</div>
    </div>
  };

  const changeCheck = (type: CheckType, item: ICheckListItem) => {
    let { listChecked, listUnchecked } = state;

    item.checked = type === 'checkbox' ? !item.checked : true;

    if (type === 'checkbox') {
      if (item.checked) {
        let index = listUnchecked.findIndex(i => i.id == item.id);
        if (index > -1) listUnchecked.splice(index, 1);
        listChecked.push(item);
        setState({ ...state, effectedItem: item, listChecked });
      } else {
        let index = listChecked.findIndex(i => i.id == item.id);
        if (index > -1) listChecked.splice(index, 1);
        listUnchecked.push(item);
        setState({ ...state, effectedItem: item, listUnchecked });
      }
    }
    else if (type === 'radio') {
      listItem.map(i => i.checked = i.id == item.id);
    }

    props.onCheckChange?.(item);
  };

  const returnResult = () => {
    let { valueFieldName, onCheckChange } = props;
    let { effectedItem, listChecked, listUnchecked } = state;

    if (valueFieldName) {
      let checkedItem = effectedItem[valueFieldName];

      let checkedResult = [];
      listChecked.map(item => checkedResult.push(item[valueFieldName]));

      let uncheckedResult = [];
      listUnchecked.map(item => uncheckedResult.push(item[valueFieldName]));

      onCheckChange?.(checkedItem, checkedResult, uncheckedResult);
    }

    onCheckChange?.(effectedItem, listChecked, listUnchecked);
  };

  return <div className={className}>
    <div className={`checklist-wrapper ${horizontal ? "horizontal" : ""}`}>
      {listItem.map(renderCheckListItem)}
    </div>
  </div>

}
