import { useEffect, useState } from 'react'
import { Button, Card } from 'reactstrap';
import { PageLayout } from '../page/page_layout';
import './tags_input.css';
import * as React from 'react';
import * as HrvComponents from '@haravan/react-components';
import { Form, Modal } from 'react-bootstrap';

interface TagsProps {
    tags?: any,
}

function TagsInput(props: TagsProps) {
    const { tags } = props;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const renderModel = () => {
        return (
            <div>
                <div onClick={handleShow} className='lb-all-tag'> <a>Tất cả Nhãn</a></div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Danh sách nhãn</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className='tag-select'>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Hủy
                        </Button>
                        <Button variant="primary">Áp dụng</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }

    return (
        <PageLayout>
            <PageLayout.AnotatedSection
                title={"Tag"}
                description={"Tag được dùng để phân loại bài viết"}>
                <Card >
                    <div className='grant-type'>
                        <div className='hrv-stack--item'>
                        </div>

                        <div className="tags-input-container">
                            {tags?.map((tag, index) => (
                                <div className="tag-item" key={index}>
                                    <span className="text">{tag}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Card>
            </PageLayout.AnotatedSection>
        </PageLayout>

    )
}

export default TagsInput