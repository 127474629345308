import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  checkedValues: []
};

export const dataListSlice = createSlice({
  name: 'data-list',
  initialState,
  reducers: {
    load: state => { state.isLoading = true },
    loadComplete: state => { state.isLoading = false },
    checkRow: (state, action) => {
      const existedIndex = state.checkedValues.findIndex(v => v === action.payload)
      if (existedIndex < 0) state.checkedValues.push(action.payload)
    },
    unCheckRow: (state, action) => {
      const existedIndex = state.checkedValues.findIndex(v => v === action.payload)
      if (existedIndex >= 0) state.checkedValues.splice(existedIndex, 1)
    },
    clearCheck: state => {
      state.checkedValues = []
    }
  },
});

export const { load, loadComplete, checkRow, unCheckRow, clearCheck } = dataListSlice.actions;

export const selectLoading = state => state.dataList.isLoading;
export const selectCheckedValues = state => state.dataList.checkedValues;

export default dataListSlice.reducer;
