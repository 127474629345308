import { Textarea } from "@haravan/react-components";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { CheckList, ICheckListItem, InputDebounce, StatusSelector, Status } from "../../../components";
import { STATUS, STATUS_APP_NAME, PLAN_TYPE_PRICE } from '../../../components/status/status';

import './index.css';

export const AppPrice = ({ price, status, index, onChangePrice, onChangeStatus, onChangeReason = console.log }) => {

  const [isRejected, setRejection] = useState(price.status === STATUS_APP_NAME.Rejected);
  const [status_AppPrice, setStatus_AppPrice] = useState(status)
  const [countPrice, setCountPrice] = useState(null);

  const changePrice = (value) => {
    onChangePrice(value, index);
  }

  const changeStatus = (value) => {
    setRejection(value === STATUS_APP_NAME.Rejected)
    onChangeStatus(value, index)
  }

  const changeReason = (value) => {
    onChangeReason(value, index);
  }

  const getPricing = (planCode) => {
    const myArray = planCode.split("_");
    let count = myArray[myArray?.length - 1];
    return count;
  }
  useEffect(() => {
    setCountPrice(getPricing(price?.planCode))
  }, [price])


  return price?.priceRecommend >= 0 &&
    (
      STATUS.includes(status_AppPrice) && <Card>
        <div className="app-price-detail">
          <div className="app-price-row">
            <label className="d-flex"><b>Gói giá {countPrice}</b> ({price?.planCode}) {price.planDefault && <Status className="pl-1" text='Gói mặc định' />}</label>
            <StatusSelector onSelect={changeStatus} listStatus={STATUS} value={price.status}
              disableSelect={status_AppPrice == STATUS[1] || status_AppPrice == STATUS[2]} />
          </div>
          <div className="change-type">
            <label className="lb-change-type">Loại phí</label>
          </div>
          <CheckList horizontal
            type="radio"
            name={`pay-by-${index}`}
            listItem={[
              {
                value: 'yearly',
                text: "Miễn phí",
                checked: price?.priceRecommend == 0,
                invisible: price?.priceRecommend > 0,
              },
              {
                value: 'yearly',
                text: "Thanh toán theo tháng",
                checked: price?.priceRecommend > 0 && price?.planTypeId == PLAN_TYPE_PRICE.month,
                invisible: price?.priceRecommend <= 0 || price?.planTypeId != PLAN_TYPE_PRICE.month,
              },
              {
                value: 'yearly',
                text: "Thanh toán theo năm",
                checked: price?.priceRecommend > 0 && price?.planTypeId != PLAN_TYPE_PRICE.month,
                invisible: price?.priceRecommend <= 0 || price?.planTypeId == PLAN_TYPE_PRICE.month,
              },

            ] as ICheckListItem[]}
          />
          {
            isRejected &&
            <div className="mt10res">
              <div className="mb5">{isRejected && "Lý do từ chối:"}</div>
              <Textarea value={price.rejectionReason ?? ''} disabled={status_AppPrice == STATUS[2]} onChange={changeReason} />
            </div>
          }
          <div className="app-price-row">
            <div className="mt10">
              <div className='mt10'>Giá đề xuất </div>
              <InputDebounce readOnly type="currency" value={price?.priceRecommend || 0} />
            </div>
            <div className="mt10">
              <div className='mt10'>Giá duyệt</div>
              <InputDebounce type="currency" readOnly={status_AppPrice != STATUS[0]} value={price?.priceApprove || 0} onChange={changePrice} />
            </div>
          </div>
          <div className="mt20">
            <div className='mb-md'>Tên gói </div>
            <InputDebounce readOnly className="input-body" type="text" value={price?.planName} />
          </div>
          <div className="mt20">
            <div className='mb-md'>Các tính năng của gói (tùy chọn)</div>
            <InputDebounce readOnly className="input-body" type="textarea" maxLength={1000} rowCount={2} value={price?.planDescription} />
          </div>
        </div>
      </Card>)
}