import { Button } from '@haravan/react-components'
import { useSelector } from 'react-redux'
import { selectCheckedValues } from '../data_list/data_list_slice'
import { useTextSearch } from '../data_search/data_search'

import './data_action.css'

export function DataAction ({ usedBy='', onExport=console.log }) {
  const checkedValues = useSelector(selectCheckedValues)
  const textSearch = useTextSearch(usedBy)
  return (
    <div className='data-action'>
      <Button status='primary' onClick={()=>onExport(checkedValues, textSearch)}>Xuất file dữ liệu</Button>
    </div>
  )
}
